import styled from 'styled-components';
import React from 'react';
import { secondary, secondaryAlt, white, textDark } from '../../styles/colors';
import { H6, Paragraph } from '../../components';
import { mobile } from '../../styles/breakpoints';
import { useMediaQuery } from 'react-responsive';

export const Card = ({
  padding,
  bgColor,
  number,
  icon,
  title,
  color,
  description,
  isAbout,
}) => {
  const isMobile = useMediaQuery({ query: mobile });

  return (
    <Wrapper
      padding={padding}
      bgColor={bgColor}
      color={color}
      className={bgColor}
    >
      {icon ? (
        <>
          <Icon src={icon} />
        </>
      ) : null}
      {title ? (
        <H6 fontSize={isMobile ? '1.5rem' : '1.5rem'} fontWeight="800">
          {title}
        </H6>
      ) : null}
      {number ? (
        <H6
          fontWeight="800"
          fontSize={isMobile ? '4rem' : '4.5rem'}
          color={isMobile ? 'inherit' : 'inherit'}
        >
          {number}
        </H6>
      ) : null}
      {description ? (
        <Paragraph
          fontWeight="500"
          margin="1rem 0 0"
          color={isAbout ? white : white}
        >
          {description}
        </Paragraph>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : '2.5rem 1.5rem')};
  background: ${(props) => (props.bgColor ? secondary : props.bgColor)};
  color: ${(props) => (props.color ? white : textDark)};

  &.none {
    background: ${secondaryAlt};
    @media ${mobile} {
      background: inherit;
    }
  }
  &:hover {
    background: ${(props) => (props.bgColor ? props.bgColor : secondaryAlt)};
    color: ${(props) => (props.color ? textDark : textDark)};
  }
  @media screen {
    margin: 0 0 1rem;
    color: ${(props) => (props.color ? props.color : white)};
  }
`;

const Icon = styled.img`
  height: 12rem;
  margin: 0 0 1.875rem;

  @media ${mobile} {
    height: ${(props) => (props.isAbout ? '4rem' : '10rem')};
    width: initial;
  }
`;
