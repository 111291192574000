import { motion, AnimatePresence } from 'framer-motion';
import { useState, forwardRef, useImperativeHandle } from 'react';
import ReactPlayer from 'react-player';

import styled from 'styled-components';
import { base } from '../../styles/colors';

export const Modal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      open: () => setOpen(true),
      close: () => setOpen(false),
    };
  });

  return (
    <>
      <AnimatePresence>
        {open && (
          <>
            <ModalBackdrop
              as={motion.div}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
              }}
              onClick={() => setOpen(false)}
            />
            <ModalContentWrapper
              initial={{
                scale: 0,
              }}
              animate={{
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                scale: 0,
              }}
            >
              <ModalContent
                as={motion.div}
                initial={{
                  x: 100,
                }}
                animate={{
                  x: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 0,
                }}
              >
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=M4dCAZ9iFNg"
                  autoplay
                />
              </ModalContent>
            </ModalContentWrapper>
          </>
        )}
      </AnimatePresence>
    </>
  );
});

const ModalBackdrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 11;
`;

const ModalContentWrapper = styled.div`
  position: fixed;
  width: 300px;
  height: 300px;
  background: ${base};
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px;
  z-index: 99999;
`;

const ModalContent = styled.div`
  position: relative;
`;
