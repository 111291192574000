import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { HeroTeam, Community, Members } from '../components';
import styled from 'styled-components';
import { base } from '../styles/colors';
import { mobile } from '../styles/breakpoints';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

export const Team = () => {
  const location = useLocation();

  const isMobile = useMediaQuery({ query: mobile });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>
          BlockGames Team: The minds behind the future of Web3 gaming
        </title>
      </Helmet>

      <PageWrapper>
        <HeroTeam />
        {isMobile ? <Members /> : <Members />}

        <Community />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  background: ${base};
  width: 100%;
  height: 100%;
  @media ${mobile} {
    overflow: hidden;
  }
`;
