import React, { useEffect } from "react";
import styled from "styled-components";
import { mobile } from "../../styles/breakpoints";
import { H2, H6, Paragraph, Container, LinkButton } from "../../components";
import { icons } from "../../utils/icons";
import { base, secondary } from "../../styles/colors";
import { useMediaQuery } from "react-responsive";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import gMain4 from "../../assets/gif/main_04.gif";
import gMain5 from "../../assets/gif/main_05.gif";
import gMain6 from "../../assets/gif/main_06.gif";
import Marquee from "react-fast-marquee";

const cards = [
  {
    title: "Cross-chain, cross-game",
    description:
      "For unparalleled scope, scale, flexibility, and security for players and games, BlockGames Player Network is built across multiple chains.",
    icon: gMain4,
  },
  {
    title: "Self-sustaining",
    description:
      "BlockGames is designed to attract an ever-increasing number of games and players consistently adding and receiving value in a mutualistic cycle of growth and earned rewards.",
    icon: gMain5,
  },
  {
    title: "Powered by $BLOCK",
    description:
      "$BLOCK is integral to the BlockGames network—the primary exchange of value that connects all stakeholders. As the Player Network exponentially grows, so does demand for $BLOCK.",
    icon: gMain6,
  },
];

const chains = [
  {
    title: "Aptos",
    icon: icons.aptos,
  },
  {
    title: "Polygon",
    icon: icons.polygon,
  },
  {
    title: "BNB Chain",
    icon: icons.bnbChain,
  },
  {
    title: "Ethereum",
    icon: icons.ethereumIcon,
  },
  {
    title: "Base",
    icon: icons.baseIcon,
  },
  {
    title: "Arbitrum",
    icon: icons.arbitrumIcon,
  },
];

export const PlayerNetwork = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const control = useAnimation();
  const [inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  return (
    <>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <H2
              fontSize={isMobile ? "2rem" : "3.5rem"}
              letterSpacing="0.07rem"
              margin="0 auto"
              maxWidth="982px"
            >
              Plug into a <strong>fun-first</strong>, Player Network that gives
              you more
            </H2>

            <Cards>
              {cards.map((card) => (
                <Card key={card.title}>
                  <Icon src={card.icon} alt={card.title} />

                  <H6
                    fontSize="24px"
                    margin="0 0 1rem"
                    letterSpacing="0.03rem"
                    fontWeight="800"
                  >
                    {card.title}
                  </H6>

                  <Paragraph
                    fontSize="1.125rem"
                    fontWeight="400"
                    margin="0 0 0"
                    letterSpacing="0.011rem"
                  >
                    {card.description}
                  </Paragraph>
                </Card>
              ))}
            </Cards>
            <Title>
              <Paragraph
                fontSize="1.25rem"
                fontWeight="600"
                letterSpacing="0.013rem"
              >
                Supported chains
              </Paragraph>
            </Title>
            <MarqueeContainer>
              <MarqueeOverlayLeft />
              <MarqueeOverlayRight />
              <MarqueeWrapper>
                <Marquee pauseOnHover={false} direction="left" speed={30}>
                  {/* Use the `speed` prop to slow down the marquee */}
                  {chains.map((chain, idx) => (
                    <LogoWrapper key={`chain-${idx}`}>
                      <Logo src={chain.icon} />
                    </LogoWrapper>
                  ))}
                </Marquee>
              </MarqueeWrapper>
            </MarqueeContainer>
            <ButtonWrapper>
              <LinkButton url="/player-network" label="Learn more" />
            </ButtonWrapper>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2.5rem 0 0;
  @media ${mobile} {
  }
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin: 0 0 1.5rem;

  @media ${mobile} {
    margin: 2.5rem 0 1rem;
  }
`;

const Icon = styled.img`
  height: 148px;
  margin: 0 0 2.5rem;
  @media ${mobile} {
    height: 60px;
    height: initial;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  text-align: center;
  background: ${secondary};
  border-radius: 2rem;
  transition: 0.2s all;

  @media ${mobile} {
    padding: 1.5rem;
    width: 100%;
  }

  &:hover {
    /* ${Icon} {
      transform: scale(1.1);
      transition: 0.2s all;
    } */
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${base};
  padding: 9rem 0 1rem;
  @media ${mobile} {
    padding: 5rem 0 1rem;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  width: 100%;
  margin: 5rem auto 2.5rem;

  @media ${mobile} {
    margin: 2.5rem auto 2.5rem;
    display: flex;
    flex-direction: column;
  }
`;

const MarqueeContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const MarqueeOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20%;
  flex-shrink: 0;
  z-index: 10;
  height: calc(100% - 14px);
`;

const MarqueeOverlayLeft = styled(MarqueeOverlay)`
  left: 0;
  background: linear-gradient(-90deg, rgba(50, 5, 84, 0) 0%, #320554 100%);
`;

const MarqueeOverlayRight = styled(MarqueeOverlay)`
  right: 0;
  background: linear-gradient(270deg, #320554 0%, rgba(50, 5, 84, 0) 100%);
`;

const MarqueeWrapper = styled.div`
  margin-bottom: 1rem; // Add some spacing between the marquee components
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${secondary};
  border-radius: 1.25rem;
  padding: 1.75rem 0;
  margin: 0 0.5rem;
  min-width: 300px;
  @media ${mobile} {
    min-width: 200px;
    padding: 1.5rem 0;
  }
`;

const Logo = styled.img`
  height: 3.75rem;
  @media ${mobile} {
    height: 2.5rem;
  }
`;
