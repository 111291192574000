import React, { useState } from "react";
import styled from "styled-components";
import { mobile } from "../../styles/breakpoints";
import { H2, Paragraph, Container } from "../../components";
import { baseSecondary, white } from "../../styles/colors";
import gcvpart1 from "../../assets/lottie/GCV_part_1.json";
import gcvpart2 from "../../assets/lottie/GCV_part_2.json";
import gcvpart3 from "../../assets/lottie/GCV_part_3.json";
import gcvpart4 from "../../assets/lottie/GCV_part_4.json";
import Lottie from "lottie-react";
import { motion, AnimatePresence } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import LazyLoad from "react-lazy-load";

const tabs = [
  {
    id: 1,
    name: "Connect and scale",
    description:
      "Games connected to the BlockGames network unlock player growth and scale fast.",
    animation: gcvpart1,
  },
  {
    id: 2,
    name: "Fees contribution",
    description:
      "Games contribute $BLOCK fees to BlockGames in exchange for unlocked player growth.",
    animation: gcvpart2,
  },
  {
    id: 3,
    name: "Viral rewards",
    description:
      "Players win instant rewards for engaging in their favorite games, referring friends, plus when those friends play and engage.",
    animation: gcvpart3,
  },
  {
    id: 4,
    name: "Player contribution",
    description:
      "Games receive instant $BLOCK rewards for bringing new players into the Player Network.",
    animation: gcvpart4,
  },
];

export const Gcv = () => {
  const isMobile = useMediaQuery({ query: mobile });
  const [selectedTab, setSelectedTab] = useState(1);
  const [currentAnimation, setCurrentAnimation] = useState(tabs[0].animation);
  const [loading, setIsLoading] = useState(false);
  const [animating, isAnimating] = useState(false);

  console.log("loading", loading);
  console.log("animating", animating);

  const handleTabChange = (newAnimation) => {
    setIsLoading(true);
    setTimeout(() => {
      setCurrentAnimation(newAnimation);
      setIsLoading(false);
    }, 200); // Adjust the duration as needed (in milliseconds)
  };

  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          <H2
            fontSize={isMobile ? "2rem" : "3.5rem"}
            letterSpacing="0.07rem"
            margin="0 auto 1.5rem"
            maxWidth={isMobile ? "100%" : "982px"}
            textAlign="center"
          >
            Generating <strong>cyclical</strong> value
          </H2>
          {!isMobile && (
            <Paragraph
              maxWidth={isMobile ? "100%" : "55%"}
              margin="0 auto"
              textAlign="center"
            >
              BlockGames' unique contribution mechanisms, combined with instant
              referral and engagement rewards for players, help spark repeated
              chain reactions that generate viral growth for games.
            </Paragraph>
          )}

          <Values>
            <Left>
              <TabsContainer>
                {tabs.map((tab) => (
                  <StyledTab
                    key={tab.id}
                    selected={selectedTab === tab.id}
                    onClick={() => {
                      setSelectedTab(tab.id);
                      handleTabChange(tab.animation);
                    }}
                  >
                    <p> {tab.name}</p>

                    {selectedTab === tab.id && (
                      <>
                        <Description
                          visible={selectedTab === tab.id}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 20 }}
                          transition={{ duration: 0.3 }}
                        >
                          {tab.description}
                        </Description>
                      </>
                    )}
                  </StyledTab>
                ))}
              </TabsContainer>
            </Left>
            <Right>
              <AnimatePresence>
                {isAnimating ? (
                  <TabPanel key="animation">
                    <LazyLoad height={500} offsetVertical={200}>
                      <LottieWrapper
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 2 }}
                      >
                        <Lottie animationData={currentAnimation} />
                      </LottieWrapper>
                    </LazyLoad>
                  </TabPanel>
                ) : (
                  <TabPanel key="loading">
                    <LottieWrapper
                      initial={{ opacity: 0.5 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0.5 }}
                      transition={{ duration: 2 }}
                    >
                      <Lottie animationData={currentAnimation} />
                    </LottieWrapper>
                  </TabPanel>
                )}
              </AnimatePresence>
            </Right>
          </Values>
        </InnerWrapper>
      </Container>
    </Wrapper>
  );
};

const StyledTab = styled(motion.div)`
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? "transparent" : "transparent"};
  color: ${(props) => (props.selected ? white : "rgba(255, 255, 255, 0.5)")};
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0;
  margin: 0 0 2rem;

  @media ${mobile} {
    width: 100%;
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  /* Additional styles to show the description only if the tab is active */
  position: relative;
  overflow: hidden;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%; /* Set a fixed width to prevent reflow */

  @media ${mobile} {
    margin: 4rem 0 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 9rem 0 0rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${baseSecondary};
  @media ${mobile} {
    height: initial;
    padding: 2.5em 0 0;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Values = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media ${mobile} {
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 30%;

  @media ${mobile} {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const Right = styled.div`
  width: 70%;
  padding: 4rem 0 6rem;
  @media ${mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
`;

const Description = styled(motion.p)`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.18px;
  margin: 1rem 0 0;
  top: 100%;
  left: 0;
  width: 100%;
  color: ${white};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
  transition: opacity 0.3s ease;
  color: #dbc9e8;
`;
const LottieWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  height: 800px;
  @media ${mobile} {
    width: 100%;
    height: initial;
  }
`;

const TabPanel = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
