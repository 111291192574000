import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { icons } from '../../utils/icons';
import { H1, Paragraph, Container, Card } from '..';
import { motion } from 'framer-motion';
import { base, secondary } from '../../styles/colors';
import bgFaded from '../../assets/lottie/Background_faded.json';
import Lottie from 'lottie-react';
import { useMediaQuery } from 'react-responsive';

export const HeroAbout = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const cards = [
    {
      id: 1,
      title: 'One network, one token',
      description:
        'Create an ever-expanding universe of games and players —The BlockGames Player Network—powered by $BLOCK',
      icon: icons.about1,
    },
    {
      id: 2,
      title: 'More engagement, more rewards',
      description:
        'Empower players through innovative proof of contribution mechanisms that deliver instant rewards with real value in every game.',
      icon: icons.about2,
    },
    {
      id: 3,
      title: 'Unique UA mechanics',
      description:
        'Drive rapid player growth for games connected to the Player Network through our novel approach to user acquisition.',
      icon: icons.about3,
    },
  ];

  return (
    <>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <LottieWrapper>
              <BackgroundWrapper
                as={motion.div}
                initial={{ opacity: 0, y: 0, x: 0 }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                transition={{ duration: 0.3, delay: 0 }}
              >
                {isMobile ? (
                  <Lottie animationData={bgFaded} />
                ) : (
                  <Lottie animationData={bgFaded} />
                )}
              </BackgroundWrapper>
            </LottieWrapper>
            <TextWrapper>
              <H1
                as={motion.h1}
                initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
                animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                transition={{ ease: 'easeOut', duration: 0.3, delay: 0.2 }}
                maxWidth={isMobile ? '100%' : '90%'}
              >
                We are <strong>BlockGames</strong>
              </H1>
              <Paragraph
                as={motion.p}
                initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
                animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                transition={{ ease: 'easeOut', duration: 0.3, delay: 0.3 }}
                margin={isMobile ? '1rem 0 0' : '2.5rem auto 0'}
                maxWidth={isMobile ? '100%' : '65%'}
                textAlign="center"
              >
                We’re building the world’s most connected, decentralized Player
                Network delivering exciting gameplay experiences to players and
                alternative user acquisition (UA) avenues for games.
              </Paragraph>
              <Paragraph
                as={motion.div}
                initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
                animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                transition={{ ease: 'easeOut', duration: 0.3, delay: 0.4 }}
                fontSize={isMobile ? '1.25rem' : '1.5rem'}
                margin={isMobile ? '2.5rem auto 1rem' : '5rem auto 1.5rem'}
              >
                Our vision is simple:
              </Paragraph>
            </TextWrapper>
            <Cards
              as={motion.div}
              initial={{ opacity: 0, y: 40, x: 0 }}
              animate={{ opacity: 1, y: 0, x: 0 }}
              transition={{ duration: 0.3, delay: 0.5 }}
            >
              {cards.map((card) => (
                <Card
                  key={card.id}
                  title={card.title}
                  description={card.description}
                  icon={card.icon}
                  bgColor={secondary}
                  isAbout
                />
              ))}
            </Cards>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};
const LottieWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 15%;
`;
const TextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${mobile} {
    position: relative;
    z-index: 2;
    text-align: center;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 2rem;
  left: 0;
  width: 100%;
  height: 100%;

  @media ${mobile} {
    /* position: relative;
    width: 100%;
    top: -9rem; */
    width: 200%;
    margin-left: -50%;
    top: 0%;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  position: relative;
  z-index: 3;
  @media ${mobile} {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
    position: relative;
    z-index: 3;
  }
`;

const Wrapper = styled.div`
  background: ${base};
  padding: 12.5rem 0 5rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${mobile} {
    padding: 12rem 0 5rem;
    width: 100%;
    overflow: hidden;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  @media ${mobile} {
    padding: 0 0px;
    width: 100%;
    overflow: hidden;
  }
`;
