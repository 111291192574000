import React from "react";
import styled from "styled-components";
import { mobile } from "../../styles/breakpoints";

import { H1, Paragraph, Container } from "..";
import { images } from "../../utils/images";
import { motion } from "framer-motion";
import { base, white, textBlack, green } from "../../styles/colors";
import { useMediaQuery } from "react-responsive";

export const HeroPlayerNetwork = () => {
  const isMobile = useMediaQuery({ query: mobile });

  return (
    <>
      <Wrapper
        as={motion.div}
        initial={{ opacity: 0, y: 0, x: 0 }}
        animate={{ opacity: 1, y: 0, x: 0 }}
        transition={{ duration: 0.3, delay: 0 }}
      >
        <Container>
          <InnerWrapper>
            <BackgroundImage
              as={motion.img}
              initial={{ opacity: 0, y: 0, x: 0 }}
              animate={{ opacity: 1, y: 0, x: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}
              src={isMobile ? images.networkBgMobile2 : images.networkBgDesktop}
              alt="Team"
            />
            <TextWrapper>
              <H1
                as={motion.h1}
                initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
                animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                transition={{ ease: "easeOut", duration: 0.3, delay: 0.2 }}
                maxWidth={isMobile ? "100%" : "80%"}
                margin="0 auto 0"
              >
                The first <strong>Player Network Protocol</strong> powered by
                Web3
              </H1>
              <Paragraph
                as={motion.p}
                initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
                animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                transition={{ ease: "easeOut", duration: 0.3, delay: 0.3 }}
                margin="1.5rem auto 0"
                maxWidth={isMobile ? "100%" : "50%"}
                textAlign="center"
              >
                A cross-chain, cross-game, decentralized Player Network that
                unlocks player growth for games.
              </Paragraph>
              {/* <ButtonsWrapper
                as={motion.div}
                initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
                animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                transition={{ ease: "easeOut", duration: 0.3, delay: 0.4 }}
              >
                <a
                  href="https://block-games.gitbook.io/blockgames-whitepaper/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read the litepaper
                </a>
              </ButtonsWrapper> */}
            </TextWrapper>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const TextWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 auto;
  @media ${mobile} {
    position: relative;
    z-index: 90;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
  max-width: 100%;
  @media ${mobile} {
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
    flex-shrink: 0;
    width: 100%;
    a {
      width: 100%;
    }
  }
  a {
    border: 0px solid ${white};
    background: ${white};
    color: ${textBlack};
    font-weight: 700;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    transition: 0.2s all;
    cursor: pointer;
    display: flex;
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    line-height: 150%;
    padding: 0.5rem 1.5rem;
    height: 3.5rem;
    min-width: ${(props) => (props.minWidth ? props.minWidth : "auto")};
    &:hover {
      transform: scale(1.1);
      background: ${green};
    }
  }
`;

const Wrapper = styled.div`
  background: ${base};
  padding: 12.5rem 0 10rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media ${mobile} {
    position: relative;
    // min-height: 100vh;
    justify-content: flex-start;
    padding: 10rem 0 5rem;
    width: 100%;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;

  @media ${mobile} {
    overflow: hidden;
  }
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
  flex-shrink: 0;
`;
