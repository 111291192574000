import logo from '../assets/icons/logo.svg';
import medium from '../assets/icons/medium.svg';
import twitter from '../assets/icons/twitter.svg';
import discord from '../assets/icons/discord.svg';
import telegram from '../assets/icons/telegram.svg';
import play from '../assets/icons/play.svg';
import step1 from '../assets/icons/step1.svg';
import step2 from '../assets/icons/step2.svg';
import step3 from '../assets/icons/step3.svg';
import rewardsCoins from '../assets/icons/rewards-coins.svg';
import live from '../assets/icons/live.svg';
import comingSoon from '../assets/icons/coming-soon.svg';
import buttonArrow from '../assets/icons/button-arrow.svg';
import users from '../assets/icons/users.svg';
import googlePlay from '../assets/icons/google-play.svg';
import appStore from '../assets/icons/app-store.svg';
import crossChain from '../assets/icons/crossChain.svg';
import selfSustaining from '../assets/icons/selfSustaining.svg';
import poweredByBlock from '../assets/icons/poweredByBlock.svg';
import aptos from '../assets/icons/aptos.svg';
import polygon from '../assets/icons/polygon.svg';
import bnbChain from '../assets/icons/bnbChain.svg';
import cumberland from '../assets/icons/cumberland.svg';
import susq from '../assets/icons/susq.svg';
import estoty from '../assets/icons/estoty.svg';
import vc from '../assets/icons/432vc.svg';
import magiceden from '../assets/icons/magiceden.svg';
import dappradar from '../assets/icons/dappradar.svg';
import clock from '../assets/icons/clock.svg';
import calendar from '../assets/icons/calendar.svg';
import teamHero from '../assets/icons/teamHero.svg';
import decoFace1 from '../assets/icons/deco-face1.svg';
import decoFace2 from '../assets/icons/deco-face2.svg';
import decoBlocks1 from '../assets/icons/deco-blocks1.svg';
import decoBlocks2 from '../assets/icons/deco-blocks2.svg';
import decoBlocks3 from '../assets/icons/deco-blocks3.svg';
import networkBg from '../assets/icons/network-bg.svg';
import poc1 from '../assets/icons/poc1.svg';
import poc2 from '../assets/icons/poc2.svg';
import powered from '../assets/icons/powered.svg';
import about1 from '../assets/icons/about1.svg';
import about2 from '../assets/icons/about2.svg';
import about3 from '../assets/icons/about3.svg';
import together from '../assets/icons/together.svg';
import togetherMobile from '../assets/icons/togetherMobile.svg';
import together1 from '../assets/icons/together1.svg';
import together2 from '../assets/icons/together2.svg';
import together3 from '../assets/icons/together3.svg';
import together4 from '../assets/icons/together4.svg';
import team from '../assets/icons/team.svg';
import value1 from '../assets/icons/value1.svg';
import value2 from '../assets/icons/value2.svg';
import value3 from '../assets/icons/value3.svg';
import value4 from '../assets/icons/value4.svg';
import adv1 from '../assets/icons/adv1.svg';
import adv2 from '../assets/icons/adv2.svg';
import adv3 from '../assets/icons/adv3.svg';
import adv4 from '../assets/icons/adv4.svg';
import adv1logo from '../assets/icons/adv1logo.svg';
import advdeco1 from '../assets/icons/adv-deco1.svg';
import advdeco2 from '../assets/icons/adv-deco2.svg';
import heroDecoMobile from '../assets/icons/hero-deco-mobile.svg';
import aboutDecoMobile from '../assets/icons/aboutDecoMobile.svg';
import aboutDecoMobile2 from '../assets/icons/aboutDecoMobile2.svg';
import aboutDecoMobile3 from '../assets/icons/aboutDecoMobile3.svg';
import playerNetworkDecoMobile from '../assets/icons/playerNetworkDecoMobile.svg';
import teamBg from '../assets/icons/teamBg.svg';
import arrow from '../assets/icons/arrow.svg';
import blogDeco1 from '../assets/icons/blogDeco1.svg';
import blogDeco2 from '../assets/icons/blogDeco2.svg';
import blogDeco3 from '../assets/icons/blogDeco3.svg';
import blogDecoMobile1 from '../assets/icons/blogDecoMobile1.svg';
import apple from '../assets/icons/apple.svg';
import google from '../assets/icons/google.svg';
import googleCloud from '../assets/icons/Google_Cloud_logo.svg';
import personalisedExperiences from '../assets/icons/personalisedExperiences.svg';
import baseIcon from '../assets/icons/baseIcon.svg';
import ethereumIcon from '../assets/icons/ethereumIcon.svg';
import arbitrumIcon from '../assets/icons/Arbitrum_f.svg';

export const icons = {
  logo,
  medium,
  twitter,
  discord,
  telegram,
  play,
  step1,
  step2,
  step3,
  rewardsCoins,
  live,
  comingSoon,
  buttonArrow,
  users,
  googlePlay,
  appStore,
  crossChain,
  selfSustaining,
  poweredByBlock,
  aptos,
  polygon,
  bnbChain,
  cumberland,
  susq,
  estoty,
  vc,
  magiceden,
  dappradar,
  clock,
  calendar,
  teamHero,
  decoFace1,
  decoFace2,
  decoBlocks1,
  decoBlocks2,
  decoBlocks3,
  networkBg,
  poc1,
  poc2,
  powered,
  about1,
  about2,
  about3,
  together,
  togetherMobile,
  together1,
  together2,
  together3,
  together4,
  team,
  value1,
  value2,
  value3,
  value4,
  adv1,
  adv2,
  adv3,
  adv4,
  adv1logo,
  advdeco1,
  advdeco2,
  heroDecoMobile,
  aboutDecoMobile,
  aboutDecoMobile2,
  aboutDecoMobile3,
  playerNetworkDecoMobile,
  teamBg,
  arrow,
  blogDeco1,
  blogDeco2,
  blogDeco3,
  blogDecoMobile1,
  apple,
  google,
  googleCloud,
  personalisedExperiences,
  baseIcon,
  ethereumIcon,
  arbitrumIcon,
};
