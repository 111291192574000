import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { green, opaquePurple } from "../../styles/colors";

export const WalletButton = ({
  onClick,
  walletAddress,
  backgroundColor,
  textColor,
  indicatorColor,
  disabled,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <Container
      backgroundColor={backgroundColor}
      textColor={textColor}
      indicatorColor={indicatorColor}
    >
      <Circle indicatorColor={indicatorColor} />
      {walletAddress}
      <Action textColor={textColor} onClick={handleClick}>
        Disconnect
      </Action>
    </Container>
  );
};

WalletButton.propTypes = {
  onClick: PropTypes.func,
  walletAddress: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  indicatorColor: PropTypes.string,
  disabled: PropTypes.bool,
};

WalletButton.defaultProps = {
  backgroundColor: "#2d094980", // Base dark purple with 50% opacity.
  textColor: opaquePurple,
  indicatorColor: green,
  disabled: false,
};

const Container = styled.div`
  border: none;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  font-weight: normal;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  display: flex;
  gap: 0.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 3rem;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ indicatorColor }) => indicatorColor};
`;

const Action = styled.div`
  border: none;
  color: ${({ textColor }) => textColor};
  cursor: pointer;
  text-decoration: underline;
`;
