import { createClient } from "contentful";

const useContentful = () => {
  const client = createClient({
    space: "h9iz6b033blc",
    accessToken: "EEHLuan8N7ymdhpkfzg3tLCR7TIwj9BxVq_-CRVAgm4",
  });

  const getPosts = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "post",
        select: "fields",
      });

      const sanitizedEntries = entries.items.map((item) => {
        const image = item.fields.image.fields;
        return {
          ...item.fields,
          image,
        };
      });

      return sanitizedEntries;
    } catch (error) {
      console.log(`Error fetching posts ${error}`);
    }
  };

  const getSinglePost = async (slug) => {
    try {
      const entries = await client.getEntries({
        content_type: "post",
        "fields.slug": slug,
      });

      const sanitizedEntries = entries.items.map((item) => {
        const image = item.fields.image.fields;
        return {
          ...item.fields,
          image,
        };
      });

      // Instead of returning the sanitizedEntries array, return the first item
      return sanitizedEntries[0] || {}; // Return an empty object if no post is found
    } catch (error) {
      console.log(`Error fetching posts ${error}`);
    }
  };

  //get post with features true
  const getFeaturedPost = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "post",
        "fields.featured": true,
      });

      const sanitizedEntries = entries.items.map((item) => {
        const image = item.fields.image.fields;
        return {
          ...item.fields,
          image,
        };
      });

      // Instead of returning the sanitizedEntries array, return the first item

      return sanitizedEntries[0] || {}; // Return an empty object if no post is found
    } catch (error) {
      console.log(`Error fetching posts ${error}`);
    }
  };

  return { getPosts, getSinglePost, getFeaturedPost };
};

export default useContentful;
