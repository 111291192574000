import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { H2, H6, Paragraph, LinkButton, Games } from '../../components';
import { white, baseSecondary } from '../../styles/colors';
import { icons } from '../../utils/icons';
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';
import GamesImage from '../../assets/images/games.png';

export const Explore = () => {
  const isMobile = useMediaQuery({ query: mobile });

  return (
    <>
      <Wrapper>
        <InnerWrapper>
          <TextWrapper>
            <H2 fontSize={isMobile ? '2rem' : '3.5rem'}>
              Start <strong>exploring</strong>
            </H2>
            <Paragraph
              margin="2rem auto 0rem"
              fontSize="18px"
              fontWeight="500"
              maxWidth="696px"
            >
              Become part of the world's leading Player Network connecting games
              and players through a cyclical value rewards system.
            </Paragraph>
          </TextWrapper>
          <ImageWrapper src={GamesImage} alt="Games" />
          <RewardsCTA>
            <div>
              <H6
                fontSize="1.5rem"
                fontWeight="700"
                margin="0 0 1rem"
                color={white}
              >
                Begin your rewards adventure!
              </H6>
              <Paragraph fontSize="1.125rem" maxWidth="460px">
                Be among the first to enter a world of fun, games, rewards, and
                exclusive collectibles. But hurry, there are only few thousand
                spots available!
              </Paragraph>
            </div>
            <ButtonWrapper>
              <LinkButton
                url="/get-early-access"
                label="Get early access now"
              />
            </ButtonWrapper>

            <RewardsCoins
              as={motion.div}
              animate={{ translateY: [0, -10, 0] }}
              transition={{
                ease: 'linear',
                duration: 2,
                repeat: Infinity,
              }}
            >
              <RewardsCoinsIcon src={icons.rewardsCoins} />
            </RewardsCoins>
          </RewardsCTA>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

const TextWrapper = styled.div`
  padding: 0 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  @media ${mobile} {
    margin: 2rem 0 0;
    width: 100%;
    a {
      width: 100%;
    }
  }
`;

const RewardsCoins = styled.div`
  position: absolute;
  top: -1.875rem;
  right: 4rem;
  @media ${mobile} {
    width: 100%;
    right: 0;
    transform: translateX(50%);
    top: -2.5rem;
    text-align: center;
  }
`;

const RewardsCoinsIcon = styled.img`
  height: 110px;
  @media ${mobile} {
    height: 80px;
  }
`;

const RewardsCTA = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 2.5rem;
  border-radius: 2.5rem;
  border: 1.091px solid #7236c0;
  background: linear-gradient(309deg, #e78bf6 0%, #9867ff 100%);
  box-shadow: 5px 10px 0px 0px #7236c0;
  max-width: 840px;
  width: 100%;
  text-align: left;
  @media ${mobile} {
    border-radius: 40px;
    border: 1.091px solid #7236c0;
    background: linear-gradient(180deg, #e78bf6 0%, #9867ff 100%);
    box-shadow: 5px 10px 0px 0px #7236c0;
    flex-direction: column;
    max-width: 100%;
    padding: 4rem 1.5rem 2.5rem;
    div {
      text-align: center;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${baseSecondary};
  padding: 9rem 0 10rem;
  @media ${mobile} {
    padding: 5rem 0px 5rem;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  @media ${mobile} {
    padding: 0 20px;
  }
`;

const ImageWrapper = styled.img`
  margin: 3rem 0rem;
  width: 100%;
`;