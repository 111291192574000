import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { icons } from '../../utils/icons';
import { Decoration, Paragraph, Card } from '..';
import { motion } from 'framer-motion';
import { baseSecondary, cyan, green, yellow } from '../../styles/colors';
import { useMediaQuery } from 'react-responsive';
import { images } from '../../utils/images';

export const Together = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const cards = [
    {
      id: 1,
      number: '40+',
      description: 'Games launched',
      bgColor: green,
    },
    {
      id: 2,
      number: '50M',
      description: 'Monthly active players',
      bgColor: yellow,
    },
    {
      id: 3,
      number: '2BN',
      description: 'Games already downloaded',
      bgColor: cyan,
    },
  ];

  return (
    <>
      <Wrapper>
        {isMobile ? (
          <>
            <MobileImage src={images.aboutDecoMobile} />
            <MobileImage2 src={images.aboutDecoMobile2} />
          </>
        ) : (
          <>
            <Decoration
              top="1rem"
              left="1rem"
              iconHeight="16rem"
              icon={icons.together1}
            />
            <Decoration
              top="1rem"
              right="1rem"
              iconHeight="16rem"
              icon={icons.together2}
            />
            <Decoration
              bottom="1rem"
              left="1rem"
              iconHeight="16rem"
              icon={icons.together3}
            />
            <Decoration
              bottom="1rem"
              right="1rem"
              iconHeight="16rem"
              icon={icons.together4}
            />
          </>
        )}

        <Container>
          <InnerWrapper>
            <Paragraph opacity="0.7">Founded together with</Paragraph>
            <Logo src={icons.together} />
            <Paragraph
              as={motion.p}
              initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
              animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
              transition={{ ease: 'easeOut', duration: 2, delay: 0.5 }}
              margin={isMobile ? '1.5rem 0 0' : '2.5rem 0 0'}
              maxWidth={isMobile ? '100%' : '55%'}
            >
              As an affiliate of world-leading game studio - Estoty, BlockGames
              is perfectly positioned to spearhead the next revolution in
              gaming.
            </Paragraph>
            <Cards>
              {cards.map((card) => (
                <Card
                  key={card.id}
                  title={card.title}
                  description={card.description}
                  icon={card.icon}
                  number={card.number}
                  padding="2rem"
                  bgColor={isMobile ? card.bgColor : card.bgColor}
                  color={isMobile ? 'textDark' : 'textDark'}
                />
              ))}
            </Cards>
            {isMobile ? null : (
              <Paragraph maxWidth={isMobile ? '100%' : '55%'}>
                Together, we are ready to embrace and harness the network
                effects of next-generation gameplay through the BlockGames
                Player Network!
              </Paragraph>
            )}
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  box-sizing: border-box;
  padding: 0px;
  overflow: initial;
  position: initial;
  @media ${mobile} {
    max-width: 100%;
  }
`;

const MobileImage = styled.img`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  z-index: 999;
`;
const MobileImage2 = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 1rem;
  z-index: 999;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin: 2rem 0;
  @media ${mobile} {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0 20px;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  background: ${baseSecondary};
  padding: 6.5rem 0 10rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  @media ${mobile} {
    padding: 12rem 0 12rem;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;
const Logo = styled.img`
  height: 5.25rem;
`;
