import videoPreview from '../assets/images/video-preview.png';
import videoPreviewMobile from '../assets/images/videoPreviewMobile.png';
import dashboard from '../assets/images/dashboard.png';
import forestKnight from '../assets/images/forest-knight.png';
import shatterpoint from '../assets/images/shatterpoint.png';
import blastRoyale from '../assets/images/blast-royale.png';
import planetMojo from '../assets/images/planet-mojo.png';
import cardOfEternity from '../assets/images/cards-of-ethernity.png';
import triumph from '../assets/images/triumph.png';
import rogue from '../assets/images/rogue-nation.png';
import rogueNation from '../assets/images/rogue-nation3.png';
import news from '../assets/images/news.png';
import networkBg from '../assets/images/network-bg.png';
import team from '../assets/images/team.png';
import team1 from '../assets/images/team1.png';
import team2 from '../assets/images/team2.png';
import team3 from '../assets/images/team3.png';
import team4 from '../assets/images/team4.png';
import team5 from '../assets/images/team5.png';
import team6 from '../assets/images/team6.png';
import team7 from '../assets/images/team7.png';
import team8 from '../assets/images/team8.png';
import team9 from '../assets/images/team9.png';
import team10 from '../assets/images/team10.png';
import team11 from '../assets/images/team11.png';
import team12 from '../assets/images/team12.png';
import team13 from '../assets/images/team13.png';
import team14 from '../assets/images/team14.png';
import team15 from '../assets/images/team15.png';
import team16 from '../assets/images/team16.png';
import team17 from '../assets/images/team17.png';
import team18 from '../assets/images/team18.png';
import adv1 from '../assets/images/adv1.png';
import adv2 from '../assets/images/adv2.png';
import adv3 from '../assets/images/adv3.png';
import adv4 from '../assets/images/adv4.png';
import animation1 from '../assets/images/animation1.png';
import animation2 from '../assets/images/animation2.png';
import animation3 from '../assets/images/animation3.png';
import animation4 from '../assets/images/animation4.png';
import dashboardMobile from '../assets/images/dashboardMobile.png';
import playerNetworkBgMobile from '../assets/images/playerNetworkBgMobile.png';
import networkBgDesktop from '../assets/images/networkBgDesktop.png';
import networkBgMobile2 from '../assets/images/networkBgMobile2.png';
import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.jpg';
import article5 from '../assets/images/article5.jpg';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import articletest from '../assets/images/articletest.png';
import personalized from '../assets/images/personalized.png';
import personalizedMobile from '../assets/images/personalizedMobile.png';
import aboutDecoMobile from '../assets/images/aboutDecoMobile.png';
import aboutDecoMobile2 from '../assets/images/aboutDecoMobile2.png';

import rogueNationMobile from '../assets/images/mobile/rogueNationMobile.png';
import forestKnightMobile from '../assets/images/mobile/forestKnightMobile.png';
import triumphMobile from '../assets/images/mobile/triumphMobile.png';
import cardsofethernityMobile from '../assets/images/mobile/cardsofethernityMobile.png';
import planetMojoMobile from '../assets/images/mobile/planetMojoMobile.png';
import blastRoyaleMobile from '../assets/images/mobile/blastRoyaleMobile.png';
import shatterpointMobile from '../assets/images/mobile/shatterpointMobile.png';
import decoBlocks1 from '../assets/images/decoBlocks1.png'

export const images = {
  videoPreview,
  videoPreviewMobile,
  dashboard,
  forestKnight,
  shatterpoint,
  blastRoyale,
  planetMojo,
  cardOfEternity,
  triumph,
  rogue,
  rogueNation,
  news,
  networkBg,
  team,
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
  team8,
  team9,
  team10,
  team11,
  team12,
  team13,
  team14,
  team15,
  team16,
  team17,
  team18,
  adv1,
  adv2,
  adv3,
  adv4,
  animation1,
  animation2,
  animation3,
  animation4,
  dashboardMobile,
  playerNetworkBgMobile,
  networkBgDesktop,
  networkBgMobile2,
  article1,
  article2,
  article3,
  article4,
  article5,
  article6,
  article7,
  articletest,
  personalized,
  personalizedMobile,
  aboutDecoMobile,
  aboutDecoMobile2,
  rogueNationMobile,
  forestKnightMobile,
  triumphMobile,
  cardsofethernityMobile,
  planetMojoMobile,
  blastRoyaleMobile,
  shatterpointMobile,
  decoBlocks1,
};
