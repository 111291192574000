import React, { useState } from 'react';
import styled from 'styled-components';
import { icons } from '../../utils/icons';
import { mobile, big } from '../../styles/breakpoints';
import { Swiper, SwiperSlide } from 'swiper/react';
import { H6, Paragraph } from '..';
import {
  baseSecondary,
  white,
  // textDark,
  // green,
  // yellow,
  // cyan,
} from '../../styles/colors';
import { Navigation, Scrollbar, Autoplay, Pagination } from 'swiper';
import { images } from '../../utils/images';
import { useMediaQuery } from 'react-responsive';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const games = [
  {
    id: 2,
    title: 'Blast Royale',
    status: 'Coming Soon',
    tags: ['Closed  Alpha', 'MidCore', 'RPG'],
    image: images.blastRoyale,
    mobileImage: images.blastRoyaleMobile,
    description:
      'A combination of MOBA features with immersive Battle Royale gameplay jam-packed in a vibrant battlefield.',
    users: '50k+',
    links: {
      website: 'https://www.blastroyale.com/',
      googlePlay:
        'https://play.google.com/store/apps/details?id=com.firstlightgames.blastroyale&hl=en&gl=US',

      appStore:
        'https://apps.apple.com/gb/app/blast-royale-battle-lands/id1621071488',
    },
  },
  {
    id: 3,
    title: 'Planet Mojo',
    status: 'Coming Soon',
    tags: ['Closed  Alpha', 'MidCore', 'RPG'],
    image: images.planetMojo,
    mobileImage: images.planetMojoMobile,
    description:
      'A Redefining strategy multiplayer PVP autochess battler game, set on a mysterious alien planet with a deep narrative.',
    users: '100k+',
    links: {
      website: 'https://www.planetmojo.io/',
      googlePlay: '',
      appStore: '',
    },
  },
  {
    id: 4,
    title: 'Cards of Ethernity',
    status: 'Coming Soon',
    tags: ['Closed  Alpha', 'MidCore', 'RPG'],
    image: images.cardOfEternity,
    mobileImage: images.cardsofethernityMobile,
    description:
      'A dark fantasy competitive card game that offers an engaging and rewarding experience for gamers and collectors alike.',
    users: '50k+',
    links: {
      website: 'https://www.aethergames.io/',
      googlePlay: '',
      appStore: '',
    },
  },

  {
    id: 5,
    title: 'Triumph: Fantasy RPG',
    status: 'Coming Soon',
    tags: ['Closed  Alpha', 'MidCore', 'RPG'],
    image: images.triumph,
    mobileImage: images.triumphMobile,
    description:
      'A real-time multiplayer RPG from the creators of Legendary that combines the best of free-to-play and web3.',
    users: '50k+',
    links: {
      website: 'https://www.n3twork.com/triumph/',
      googlePlay:
        'https://play.google.com/store/apps/details?id=com.n3twork.triumph&gl=ph',
      appStore: 'https://apps.apple.com/ph/app/id1643734514',
    },
  },
  {
    id: 6,
    title: 'Shatterpoint',
    status: 'Coming Soon',
    tags: ['Closed  Alpha', 'MidCore', 'RPG'],
    image: images.shatterpoint,
    mobileImage: images.shatterpointMobile,
    description:
      'A free-to-play, free-to-own action RPG for mobile that features PVP & PVE gameplay modes and an interactive, narrative-driven universe.',
    users: '50k+',
    links: {
      website: 'https://www.shatterpoint.game/',
      googlePlay: '',
      appStore: '',
    },
  },
  {
    id: 1,
    title: 'Forest Knight',
    status: 'Coming Soon',
    tags: ['Closed  Alpha', 'MidCore', 'RPG'],
    image: images.forestKnight,
    mobileImage: images.forestKnightMobile,
    description:
      'A free-to-play, turn-based RPG that features PVE & PVP gameplay modes in a story-driven fantasy universe.',
    users: '40k+',
    links: {
      website: 'https://www.forestknight.io/',
      googlePlay:
        'https://play.google.com/store/apps/details?id=com.ChronoGames.Forest.Knight&hl=en&gl=US',
      appStore: '',
    },
  },

  {
    id: 7,
    title: 'Rogue Nation',
    status: 'Coming Soon',
    tags: ['Closed  Alpha', 'MidCore', 'RPG'],
    image: images.rogue,
    mobileImage: images.rogueNationMobile,
    description:
      'A revolutionary take on modern rogue-lite, combining fast-paced gameplay with a deep progression through player actions.',
    users: '25k+',
    links: {
      website: 'https://www.moonlitgames.io/',
      googlePlay: '',
      appStore: '',
    },
  },
];

const params = {
  loop: true,
  autoplay: true,
  modules: [Autoplay, Navigation, Pagination, Scrollbar],
  navigation: {
    nextEl: '.arrow-right',
    prevEl: '.arrow-left',
  },

  scrollbar: true,
  centeredSlides: true,

  breakpoints: {
    320: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    1024: {
      slidesPerView: 3.6,
      spaceBetween: 16,
    },
    1200: {
      slidesPerView: 3.6,
      spaceBetween: 32,
    },
    1500: {
      slidesPerView: 4.6,
      spaceBetween: 48,
    },
  },
};

export const Games = () => {
  const [hoveredSlideIndex, setHoveredSlideIndex] = useState('null');

  const hoverWrapperVariants = {
    hidden: { opacity: 0, y: '-100px', transition: { duration: 0.5 } },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  console.log('hoveredSlideIndex', hoveredSlideIndex);

  const isMobile = useMediaQuery({ query: mobile });

  return (
    <Wrapper className="logos-swiper">
      <InnerWrapper>
        {!isMobile && (
          <Swiper {...params}>
            {games.map((game, index) => (
              <SwiperSlide
                key={game.id}
                onMouseEnter={() => setHoveredSlideIndex(index)}
                onMouseLeave={() => setHoveredSlideIndex(null)}
              >
                <Game
                  onMouseEnter={() => setHoveredSlideIndex(index)}
                  onMouseLeave={() => setHoveredSlideIndex(null)}
                >
                  <Image
                    src={isMobile ? game.mobileImage : game.image}
                    alt={game.title}
                  />
                  <Status>
                    {game.status === 'Live' ? (
                      <StatusWrapper>
                        <StatusIcon src={icons.live} />
                        <p>{game.status}</p>
                      </StatusWrapper>
                    ) : (
                      <StatusWrapper>
                        <StatusIcon src={icons.comingSoon} />
                        <p>{game.status}</p>
                      </StatusWrapper>
                    )}
                  </Status>
                  <TextIdle>
                    <H6
                      fontWeight="800"
                      fontSize="2rem"
                      color={white}
                      margin="0"
                      lineHeight="1.3"
                      letterSpacing="0.64px"
                    >
                      {game.title}
                    </H6>
                  </TextIdle>

                  <HoverWrapper
                    variants={hoverWrapperVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                  >
                    <H6
                      fontWeight="800"
                      fontSize="2rem"
                      color={white}
                      margin="0"
                      lineHeight="1.3"
                      letterSpacing="0.64px"
                    >
                      {game.title}
                    </H6>
                    <Paragraph
                      fontSize="1rem"
                      color={white}
                      margin="0.5rem 0 0"
                    >
                      {game.description}
                    </Paragraph>
                    <Bottom>
                      <Users>
                        <UsersIcon src={icons.users} />
                        <Paragraph
                          fontSize="1.5rem"
                          fontWeight="800"
                          letterSpacing="0.96px"
                          color={white}
                        >
                          {game.users}
                        </Paragraph>
                      </Users>
                      <Links>
                        {game.links.googlePlay && (
                          <a
                            href={game.links.googlePlay}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LinkIcon src={icons.googlePlay} />
                          </a>
                        )}
                        {game.links.appStore && (
                          <a
                            href={game.links.appStore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LinkIcon src={icons.appStore} />
                          </a>
                        )}

                        {game.links.website && (
                          <a
                            href={game.links.website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LinkIcon src={icons.buttonArrow} />
                          </a>
                        )}
                      </Links>
                    </Bottom>
                  </HoverWrapper>
                </Game>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {isMobile && (
          <MobileGames>
            {games.map((game, index) => (
              <MobileGame
                key={game.id}
                onMouseEnter={() => setHoveredSlideIndex(index)}
                onMouseLeave={() => setHoveredSlideIndex(null)}
              >
                <MobileImageWrapper>
                  <Image src={game.mobileImage} alt={game.title} />
                </MobileImageWrapper>
                <Status>
                  {game.status === 'Live' ? (
                    <StatusWrapper>
                      <StatusIcon src={icons.live} />

                      <p>{game.status}</p>
                    </StatusWrapper>
                  ) : (
                    <StatusWrapper>
                      <StatusIcon src={icons.comingSoon} />
                      <p>{game.status}</p>
                    </StatusWrapper>
                  )}
                </Status>
                <TextIdle>
                  <H6
                    fontWeight="800"
                    fontSize="28px"
                    color={white}
                    margin="0"
                    lineHeight="1.3"
                    letterSpacing="0.64px"
                  >
                    {game.title}
                  </H6>
                </TextIdle>

                <MobileInfo>
                  <MobileInfoTop>
                    <H6
                      fontWeight="800"
                      fontSize="2rem"
                      color={white}
                      margin="0"
                      lineHeight="1.3"
                      textAlign="left"
                      width="100%"

                      // letterSpacing="0.64px"
                    >
                      {game.title}
                    </H6>
                    <Users>
                      <UsersIcon src={icons.users} />
                      <Paragraph
                        fontSize="1.5rem"
                        fontWeight="800"
                        letterSpacing="0.96px"
                        color={white}
                      >
                        {game.users}
                      </Paragraph>
                    </Users>
                  </MobileInfoTop>

                  <Paragraph
                    fontSize="1rem"
                    color={white}
                    margin="0.5rem 0 0"
                    textAlign="left"
                  >
                    {game.description}
                  </Paragraph>

                  {game.links.website && (
                    <MobileWebsite>
                      <a
                        href={game.links.website}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Game Website
                      </a>
                    </MobileWebsite>
                  )}

                  <Links>
                    {game.links.appStore && (
                      <MobileLink>
                        <a
                          href={game.links.appStore}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <MobileLinkIcon src={icons.appStore} />
                          App Store
                        </a>
                      </MobileLink>
                    )}

                    {game.links.googlePlay && (
                      <MobileLink>
                        <a
                          href={game.links.googlePlay}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <MobileLinkIcon src={icons.google} />
                          Google Play
                        </a>
                      </MobileLink>
                    )}
                  </Links>
                </MobileInfo>
              </MobileGame>
            ))}
          </MobileGames>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

const MobileLink = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    border: 1px solid ${white};
    border-radius: 0.5rem;
    padding: 11px 12px;
    color: ${white};
    text-decoration: none;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    margin-right: 8px;
  }
`;

const MobileLinkIcon = styled.img`
  height: 24px;
`;

const MobileWebsite = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 8px;
  a {
    text-align: left;
    color: ${white};
    font-size: 1rem;
    font-weight: 700;
    text-decoration: underline;
    margin: 0;
  }
`;
const MobileInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MobileInfoTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

const MobileImageWrapper = styled.div`
  width: 100%;
  height: initial;
`;

const MobileGames = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileGame = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 2rem;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    position: absolute;
    font-size: 14px;
    color: ${white};
    line-height: 1.5;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const StatusIcon = styled.img`
  height: 48px;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 1);
  opacity: 0;
`;

const HoverWrapper = styled.div`
  position: absolute;
  bottom: -100px;
  left: 0;
  width: 100%;
  opacity: 0;
  text-align: left;
  padding: 1.5rem;
  border-radius: 2rem;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );

  @media ${mobile} {
    opacity: 1;
    bottom: 0px;
  }
`;

const TextIdle = styled.div`
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  opacity: 0.7;
  z-index: 90;
  @media ${mobile} {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${baseSecondary};
  padding: 5rem 0 5rem;

  @media ${mobile} {
  }

  .swiper-slide {
    padding: 4px;
  }
`;

const InnerWrapper = styled.div`
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const Game = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 2rem;
  max-height: 600px;
  overflow: hidden;

  @media ${big} {
    max-height: initial;
  }

  &:hover {
    transform: scale(1);
    border-radius: 2rem;
    transition: all 0.2s;
    box-shadow: 4px 4px 0px #e65efa;

    ${HoverWrapper} {
      opacity: 1;
      transition: all 0.3s;
      bottom: 0px;
      z-index: 3;
    }
    ${Overlay} {
      transition: all 0.2s;
      opacity: 1;
    }
    ${TextIdle} {
      opacity: 0;
      transition: all 0.2s;
      bottom: 1.5rem;
    }
  }
  @media ${mobile} {
    box-shadow: 4px 4px 0px #e65efa;
    &:hover {
      ${HoverWrapper} {
        opacity: 1;
      }
      ${TextIdle} {
        opacity: 0;
      }
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
  overflow: hidden;
  @media ${mobile} {
    border-radius: 1rem;
  }
`;

const Status = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
`;

// const Tags = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 0 0 0.5rem;
// `;

// const Tag = styled.div`
//   display: flex;
//   padding: 0.5rem 1.25rem;
//   justify-content: center;
//   align-items: center;
//   border-radius: 10px;
//   background-color: ${cyan};
//   color: ${textDark};
//   font-size: 0.875rem;
//   font-weight: 700;
//   line-height: 1.5;
//   white-space: nowrap;
//   margin-right: 0.5rem;

//   &.green {
//     background-color: ${green};
//   }
//   &.yellow {
//     background-color: ${yellow};
//   }
// `;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1.5rem 0 0;
`;

const Users = styled.div`
  display: flex;
  align-items: center;
`;

const UsersIcon = styled.img`
  height: 1rem;
  margin-right: 0.5rem;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    transition: all 0.2s;
    &:hover {
      transition: all 0.2s;
      transform: scale(1.2);
    }
  }

  @media ${mobile} {
    margin: 1rem 0 0;
    justify-content: flex-start;
  }
`;

// const Link = styled.a``;

const LinkIcon = styled.img`
  height: 2.5rem;
`;
