import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { textBlack } from "../../styles/colors";

export const Spinner = ({ width, height, color }) => {
  return <StyledSpinner width={width} height={height} color={color} />;
};

Spinner.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

Spinner.defaultProps = {
  width: "30px",
  height: "30px",
  color: textBlack,
};

const StyledSpinner = styled.span`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 4px solid ${(props) => props.color};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  boxSizing: border-box;
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
})`;
