import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PrimaryButton } from './PrimaryButton';

/**
 * Use when you want to link to an internal page.
 */
export const LinkButton = ({
  onClick,
  label,
  disabled,
  className,
  icon,
  url,
  children,
  minWidth,
  outlined,
  height,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <Link to={url}>
      <PrimaryButton
        disabled={disabled}
        onClick={handleClick}
        className={className}
        label={label}
        icon={icon}
        minWidth={minWidth}
        outlined={outlined}
        height={height}
      >
        {children}
      </PrimaryButton>
    </Link>
  );
};

LinkButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

LinkButton.defaultProps = {
  type: 'button',
  disabled: false,
};
