export const textTransition = {
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
    skewX: 0,
    skewY: 0,
    transition: { duration: 1 },
  },
  hidden: { opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 },
};

export const slideFromLeftTransition = {
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: { duration: 0.5 },
  },
  hidden: { opacity: 0, x: -40, y: 0 },
};

export const slideFromRightTransition = {
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: { duration: 0.5 },
  },
  hidden: { opacity: 0, x: 40, y: 0 },
  exit: { opacity: 0, x: 0, y: 0 },
};

export const slideFromBottomTransition = {
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: { duration: 0.5, delay: 0 },
  },
  hidden: { opacity: 0, x: 0, y: 40 },
  exit: { opacity: 0, x: 0, y: 0 },
};

export const slideFromBottomTransition1 = {
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: { duration: 0.5 },
  },
  hidden: { opacity: 0, x: 0, y: 40 },
  exit: { opacity: 0, x: 0, y: 0 },
};

export const slideFromBottomTransition2 = {
  visible: {
    opacity: 1,
    x: 0,
    y: 0,

    transition: { duration: 0.5, delay: 0.2 },
  },
  hidden: { opacity: 0, x: 0, y: 40 },
  exit: { opacity: 0, x: 0, y: 0 },
};

export const slideFromBottomTransition3 = {
  visible: {
    opacity: 1,
    x: 0,
    y: 0,

    transition: { duration: 0.5, delay: 0.4 },
  },
  hidden: { opacity: 0, x: 0, y: 40 },
  exit: { opacity: 0, x: 0, y: 0 },
};

export const slideFromBottomTransition4 = {
  visible: {
    opacity: 1,
    x: 0,
    y: 0,

    transition: { duration: 0.5, delay: 0.6 },
  },
  hidden: { opacity: 0, x: 0, y: 40 },
  exit: { opacity: 0, x: 0, y: 0 },
};

export const noTransition = {
  hidden: { opacity: 1, x: 0, y: 0 },
  visible: { opacity: 1, x: 0, y: 0 },
};
