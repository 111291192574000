import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { HeroAbout, Together, Community } from '../components';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { base } from '../styles/colors';

export const About = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>
          About The world's first cross-game, cross-chain Player Network
        </title>
      </Helmet>
      <PageWrapper>
        <HeroAbout />
        <Together />
        <Community />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  background: ${base};
  width: 100%;
  height: 100%;
`;
