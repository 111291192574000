import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { mobile, big } from "../../styles/breakpoints";
import { motion } from "framer-motion";
import { black, white, green, textBlack } from "../../styles/colors";
import { Spinner } from "../loaders/Spinner";

export const PrimaryButton = ({
  onClick,
  label,
  disabled,
  type,
  className,
  icon,
  children,
  minWidth,
  changeBgColor,
  state,
  outlined,
  height,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <Wrapper
      as={motion.button}
      disabled={disabled}
      type={type}
      onClick={handleClick}
      className={className}
      initial={{ backgroundColor: outlined ? "transparent" : white }}
      whileHover={{
        scale: outlined ? 1.05 : 1.1,
        backgroundColor: outlined ? "transparent" : changeBgColor ? green : white,
      }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 300, damping: 17 }}
      minWidth={minWidth}
      outlined={outlined}
      height={height}
    >
      {state === "loading" ? (
        <Spinner color={black} />
      ) : (
        <>
          {icon && <ButtonIcon src={icon} label={!!label} />}
          {label}
          {children}
        </>
      )}
    </Wrapper>
  );
};

PrimaryButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  changeBgColor: PropTypes.bool,
  state: PropTypes.string,
  outlined: PropTypes.bool,
  height: PropTypes.string,
};

PrimaryButton.defaultProps = {
  type: "button",
  disabled: false,
  changeBgColor: true,
  state: "normal",
  outlined: false,
  height: "3.5rem",
};

const Wrapper = styled.button`
  border: ${(props) => (props.outlined ? `1px solid ${white}` : `none`)};
  background: ${(props) => (props.outlined ? "green" : white)};
  color: ${(props) => (props.outlined ? white : textBlack)};
  font-weight: 700;
  font-size: 1.125rem;
  padding: 0.5rem 1.5rem;
  border-radius: ${(props) => (props.outlined ? "0.5rem" : "1rem")};

  cursor: pointer;
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 150%;
  padding: 0.5rem 1.5rem;
  height: ${(props) => (props.height ? props.height : "3.5rem")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "auto")};

  a {
    cursor: pointer;
    display: flex;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: ${white};
  }
  @media ${mobile} {
    padding: 8px 24px;
    height: 48px;
    width: 100%;
  }

  @media ${big} {
    font-size: 18px;
    padding: 18px 42px;
  }
`;

const ButtonIcon = styled.img`
  display: flex;
  height: ${(props) => (props.label ? "30px" : "35px")};
  margin-right: ${(props) => (props.label ? "10px" : "0px")};
`;
