import styled from 'styled-components';
import { white } from '../../styles/colors';

export const H4 = styled.h4`
  padding: ${(props) => props.padding || '0'};
  margin: ${(props) => props.margin || '0'};
  font-weight: ${(props) => props.fontWeight || 800};
  font-size: ${(props) => props.fontSize || '2rem'};
  line-height: ${(props) => props.lineHeight || '1.3'};
  color: ${(props) => props.color || white};
  max-width: ${(props) => props.maxWidth || '100%'};
  text-align: ${(props) => props.textAlign || 'none'};
  letter-spacing: 1.12px;

  strong {
    background: var(
      --new-gradient,
      linear-gradient(45deg, #e78bf6 0%, #9867ff 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 130%;
    letter-spacing: 1.12px;
    font-weight: 800;
  }
`;
