import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { H1, H6, Paragraph, Container } from '..';
import {
  baseSecondary,
  white,
  green,
  yellow,
  textDark,
  brightPink,
} from '../../styles/colors';
import { icons } from '../../utils/icons';
import { useMediaQuery } from 'react-responsive';

export const BigPost = ({ firstPost = {} }) => {
  const isMobile = useMediaQuery({ query: mobile });

  console.log('firstPost: ', firstPost);

  // const firstPost = blogPosts[0];

  return (
    <>
      <Wrapper>
        <Container>
          <InnerWrapper>
            {isMobile && (
              <H1 fontSize="2rem" margin="0 0 2rem" textAlign="center">
                Featured
              </H1>
            )}

            {firstPost && (
              <>
                <ImageWrapper>
                  <Link to={`/news/${firstPost.slug}`}>
                    <Image src={firstPost.image.file.url} />
                  </Link>
                </ImageWrapper>

                <InfoWrapper>
                  <NewsItem key={firstPost.id}>
                    <Link to={`/news/${firstPost.slug}`}>
                      <NewsContent>
                        <NewsTop>
                          {firstPost.categories && (
                            <Tags>
                              {firstPost.categories.map((category) => (
                                <Tag key={category.fields.name}>
                                  {category.fields.name}
                                </Tag>
                              ))}
                            </Tags>
                          )}
                        </NewsTop>

                        <H6
                          fontSize={isMobile ? '1.25rem' : '2rem'}
                          margin={isMobile ? '0 0 1rem' : '0 0 2rem'}
                        >
                          {firstPost.title}
                        </H6>

                        <Description
                          fontSize={isMobile ? '0.875rem' : '1.125rem'}
                        >
                          {firstPost.description}
                        </Description>

                        {firstPost.author && (
                          <Author>
                            by <strong>{firstPost.author.fields.name}</strong>
                          </Author>
                        )}

                        <Wrap>
                          <AttributesWrapper>
                            <Attribute>
                              <Icon src={icons.clock} />
                              <Paragraph
                                fontSize="0.875rem"
                                fontWeight="600"
                                lineHeight="160%"
                              >
                                {firstPost.minutesToRead} min
                              </Paragraph>
                            </Attribute>
                            <Attribute>
                              <Icon src={icons.calendar} />
                              <Paragraph
                                fontSize="0.875rem"
                                fontWeight="600"
                                lineHeight="160%"
                              >
                                {dayjs(firstPost.date).format('D MMM')}
                              </Paragraph>
                            </Attribute>
                          </AttributesWrapper>
                          {firstPost.slug && (
                            <LinkButton to={`/news/${firstPost.slug}`}>
                              Read more
                            </LinkButton>
                          )}
                        </Wrap>
                      </NewsContent>
                    </Link>
                  </NewsItem>
                </InfoWrapper>
              </>
            )}
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};
const Description = styled(Paragraph)`
  /* Set the maximum number of lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${mobile} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const Image = styled.img`
  height: 450px;
  width: 100%;
  object-fit: cover;
  transition: 0.4s all;
  @media ${mobile} {
    height: 200px;
  }
`;

const AttributesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinkButton = styled(Link)`
  display: flex;
  margin: 1rem 0 0;
  color: ${brightPink};
  font-weight: 700;
  line-height: 160%;
  font-size: 1rem;
  white-space: nowrap;
  position: relative;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 1px;
    left: 0;
    background: ${brightPink};
    transition: width 0.3s;
    bottom: -0.25rem;
  }
  &:hover:after {
    width: 100%;
  }

  @media ${mobile} {
    margin: 0;
    font-size: 0.875rem;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  overflow: hidden;
  max-height: 450px;
  transition: 0.4s all;
  border-radius: 2.5rem;
  transform: scale(1);
  border: 1px solid ${brightPink};

  &:hover {
    cursor: pointer;
    ${Image} {
      transform: scale(1.05);
      transition: 0.4s all;
    }
  }

  @media ${mobile} {
    width: 100%;
    border-radius: 1.5rem;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  padding-left: 3rem;
  @media ${mobile} {
    width: 100%;
    padding: 0;
  }
`;

const Attribute = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.75;
  color: ${white};
  margin-right: 1rem;
`;

const Icon = styled.img`
  height: 1rem;
  margin-right: 0.5rem;
`;
const Author = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: ${white};
  opacity: 0.5;
  font-weight: 500;
  line-height: 160%;
  margin: 1rem 0;

  strong {
    margin-left: 0.25rem;
    font-weight: 500;
  }

  @media ${mobile} {
    margin: 0.5rem 0;
  }
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0.5rem;
  @media ${mobile} {
    margin: 0;
  }
`;

const Tag = styled.div`
  display: flex;
  padding: 0.5rem 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${green};
  color: ${textDark};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  white-space: nowrap;

  &.green {
    background-color: ${green};
  }
  &.yellow {
    background-color: ${yellow};
  }

  @media ${mobile} {
    font-size: 0.75rem;
  }
`;

const NewsItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const NewsTop = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;

  @media ${mobile} {
    margin: 1rem 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${baseSecondary};
  padding: 5rem 0;

  @media ${mobile} {
    height: initial;

    padding: 2.5rem 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${mobile} {
    flex-direction: column;
  }
`;
