import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../components';
import { base, secondary } from '../../styles/colors';
import { icons } from '../../utils/icons';
import { mobile } from '../../styles/breakpoints';
import Marquee from 'react-fast-marquee';
import { motion } from 'framer-motion';

// Helper function to shuffle the array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const logos = [
  {
    id: 1,
    src: icons.aptos,
  },
  {
    id: 2,
    src: icons.bnbChain,
  },
  {
    id: 3,
    src: icons.polygon,
  },
  {
    id: 4,
    src: icons.cumberland,
  },
  {
    id: 5,
    src: icons.estoty,
  },
  {
    id: 6,
    src: icons.vc,
  },
  {
    id: 7,
    src: icons.magiceden,
  },
  {
    id: 8,
    src: icons.dappradar,
  },
  {
    id: 9,
    src: icons.susq,
  },
  {
    id: 10,
    src: icons.googleCloud,
  }
];

export const Trusted = () => {
  // Have to duplicate because not enough elements to fill the screen on wider screens.
  const shuffledLogos = [...logos, ...logos]; // Don't shuffle, just duplicate? //shuffleArray([...logos]);

  return (
    <>
      <Wrapper
        as={motion.div}
        initial={{ opacity: 0, y: 40, x: 0 }}
        animate={{ opacity: 1, y: 0, x: 0 }}
        transition={{ duration: 0.3, delay: 0.6 }}
      >
        <InnerWrapper>
          <Paragraph
            fontSize="1.175rem"
            fontWeight="800"
            margin="0 auto 1.5rem"
          >
            Trusted by
          </Paragraph>
          <MarqueeContainer>
            <MarqueeOverlayLeft />
            <MarqueeOverlayRight />
            <MarqueeWrapper>
              <Marquee pauseOnHover={false} direction="left" speed={30}>
                {/* Use the `speed` prop to slow down the marquee */}
                {shuffledLogos
                  .slice(0, shuffledLogos.length / 2)
                  .map((logo, idx) => (
                    <LogoWrapper key={`logo-${idx}`}>
                      <Logo src={logo.src} />
                    </LogoWrapper>
                  ))}
              </Marquee>
            </MarqueeWrapper>

            <MarqueeWrapper>
              <Marquee pauseOnHover={false} direction="right" speed={30}>
                {/* Use the `speed` prop to slow down the marquee */}
                {shuffledLogos
                  .slice(shuffledLogos.length / 2)
                  .map((logo, idx) => (
                    <LogoWrapper key={`second-logo-${idx}`}>
                      <Logo src={logo.src} />
                    </LogoWrapper>
                  ))}
              </Marquee>
            </MarqueeWrapper>
          </MarqueeContainer>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

const MarqueeContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const MarqueeOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20%;
  flex-shrink: 0;
  z-index: 10;
  height: calc(100% - 14px);
`;

const MarqueeOverlayLeft = styled(MarqueeOverlay)`
  left: 0;
  background: linear-gradient(-90deg, rgba(50, 5, 84, 0) 0%, #320554 100%);
`;

const MarqueeOverlayRight = styled(MarqueeOverlay)`
  right: 0;
  background: linear-gradient(270deg, #320554 0%, rgba(50, 5, 84, 0) 100%);
`;

const MarqueeWrapper = styled.div`
  margin-bottom: 1rem; // Add some spacing between the marquee components
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${secondary};
  border-radius: 1.25rem;
  padding: 1.75rem 0;
  margin: 0 0.5rem;
  min-width: 300px;
  @media ${mobile} {
    min-width: 200px;
    padding: 1.5rem 0;
  }
`;

const Logo = styled.img`
  height: 3.75rem;
  @media ${mobile} {
    height: 2.5rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${base};
  padding: 0rem 0 10rem;
  @media ${mobile} {
    padding: 0 0 2.5rem;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;
