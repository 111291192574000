import React, { useEffect } from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { H2, H6, Paragraph, Container } from '../../components';
import {
  slideFromBottomTransition1,
  slideFromBottomTransition2,
  slideFromBottomTransition3,
} from '../../animations/animations';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { baseSecondary, brightPink, white } from '../../styles/colors';
import gMain1 from '../../assets/gif/main_01.gif';
import gMain2 from '../../assets/gif/main_02.gif';
import gMain3 from '../../assets/gif/main_03.gif';

const rewards = [
  {
    id: 1,
    number: '1',
    title: 'Discover and play new games',
    text: 'Choose from a fast-growing network of high-quality games. Download, sign up, and start playing the games you love.',
    image: gMain1,
    transition: slideFromBottomTransition1,
  },
  {
    id: 2,
    number: '2',
    title: 'Invite friends and engage',
    text: 'Share your game discoveries with your friends and followers and engage across the Player Network for rewards that virally scale.',
    image: gMain2,
    transition: slideFromBottomTransition2,
  },
  {
    id: 3,
    number: '3',
    title: 'Redeem your rewards ',
    text: 'Redeem rewards earned for your participation and contribution to the Player Network.',
    image: gMain3,
    transition: slideFromBottomTransition3,
  },
];

export const Rewards = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const control = useAnimation();
  const [inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  return (
    <>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <H2>
              3 simple steps <strong>to great rewards</strong>
            </H2>

            <RewardsWrapper>
              {rewards.map((reward) => (
                <Reward key={reward.id}>
                  <Image src={reward.image} alt={reward.title} />
                  <Number>{reward.number}</Number>
                  <H6
                    fontSize={isMobile ? '1.25rem' : '1.5rem'}
                    margin={isMobile ? '0 0 1rem' : '0 0 1.5rem'}
                  >
                    {reward.title}
                  </H6>
                  <Paragraph
                    fontSize={isMobile ? '1rem' : '1.125rem'}
                    fontWeight="400"
                    margin="0 0 0"
                  >
                    {reward.text}
                  </Paragraph>
                </Reward>
              ))}
            </RewardsWrapper>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${baseSecondary};
  padding: 9rem 0;
  @media ${mobile} {
    padding: 5rem 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

const RewardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 6.5rem 0 0;
  @media ${mobile} {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 3.25rem 0 0;
  }
`;

const Image = styled.img`
  height: 174px;

  object-fit: cover;
  @media mobile {
    height: 9.75rem;
  }
`;
const Reward = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s all;

  width: 350px;
  @media ${mobile} {
    width: 100%;
    margin: 0 0 3rem;
  }

  &:hover {
    ${Image} {
      transform: scale(1.1);
      transition: 0.2s all;
    }
  }
`;

const Number = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 12px;
  border: 2px solid ${brightPink};
  color: ${white};
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin: 2.5rem 0 0.75rem;
`;
