import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile, big } from '../../styles/breakpoints';
import { Link } from 'react-router-dom';
import { H1, H6, Paragraph, Container, BigPost } from '..';
import dayjs from 'dayjs';
import {
  base,
  secondary,
  white,
  green,
  yellow,
  textDark,
  brightPink,
} from '../../styles/colors';
import { icons } from '../../utils/icons';
import { useMediaQuery } from 'react-responsive';
import useContentful from '../../useContentful';

export const Posts = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const [posts, setPosts] = useState([]);
  const { getPosts } = useContentful();
  const [featuredPost, setFeaturedPost] = useState();
  const [displayCount, setDisplayCount] = useState(6);
  const [selectedCategory, setSelectedCategory] = useState('All'); // Initial value is 'All'

  useEffect(() => {
    getPosts().then((response) => {
      if (response) {
        setPosts(response);
      }
      // Find the post with featured true
      const featuredPost = response.find((post) => post.featured === true);

      setFeaturedPost(featuredPost);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to ensure the effect runs only once

  const handleLoadMore = () => {
    setDisplayCount(displayCount + 6);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setDisplayCount(6); // Reset the display count when changing category
  };

  const filteredPosts =
    selectedCategory === 'All'
      ? posts
      : posts.filter((post) =>
          post.categories && post.categories.some(
            (category) => category.fields.name === selectedCategory
          )
        );

  console.log(posts)

  return (
    <>
      {featuredPost && <BigPost firstPost={featuredPost} />}
      <Wrapper>
        <Container>
          <InnerWrapper>
            {isMobile && (
              <H1
                fontSize="2rem"
                margin="0 0 2rem"
                textAlign={isMobile ? 'center' : 'initial'}
              >
                News about us
              </H1>
            )}
            <FilterButtons>
              <FilterButton
                active={selectedCategory === 'All'}
                onClick={() => handleCategoryChange('All')}
              >
                All
              </FilterButton>
              <FilterButton
                active={selectedCategory === 'Blog'}
                onClick={() => handleCategoryChange('Blog')}
              >
                Blog
              </FilterButton>
              <FilterButton
                active={selectedCategory === 'Industry'}
                onClick={() => handleCategoryChange('Industry')}
              >
                Industry
              </FilterButton>
              {/* Add more buttons for other categories */}
            </FilterButtons>
            <NewsWrapper>
              {filteredPosts
                .filter((post) => post !== featuredPost) // Exclude the featured post
                .slice(0, displayCount)
                .map((post) => (
                  <NewsItem key={post.slug}>
                    <Link
                      to={`/news/${post.slug}`}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <ImageWrapper>
                        <Image src={post.image.file.url} />
                      </ImageWrapper>

                      <NewsContent>
                        <NewsTop>
                          <Tags>
                            {post.categories &&
                              post.categories.map((category) => {
                                console.log('category:', category); // Add this line
                                return (
                                  <Tag
                                    key={category.fields.name}
                                    className={category.fields.name}
                                  >
                                    {category.fields.name}
                                  </Tag>
                                );
                              })}
                          </Tags>

                          {!isMobile && (
                            <>
                              <Attribute>
                                <Icon src={icons.clock} />
                                <Paragraph
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  lineHeight="160%"
                                >
                                  {post.minutesToRead} min
                                </Paragraph>
                              </Attribute>
                              <Attribute>
                                <Icon src={icons.calendar} />
                                <Paragraph
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  lineHeight="160%"
                                >
                                  {dayjs(post.date).format('D MMM')}
                                </Paragraph>
                              </Attribute>
                            </>
                          )}
                        </NewsTop>

                        <BlogTitle>{post.title}</BlogTitle>
                        <Description fontSize="1rem">
                          {post.description}
                        </Description>

                        {post.author && (
                          <Author>
                            by <strong>{post.author.fields.name}</strong>
                          </Author>
                        )}
                        {isMobile ? (
                          <Wrap>
                            <AttributesWrapper>
                              <Attribute>
                                <Icon src={icons.clock} />
                                <Paragraph
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  lineHeight="160%"
                                >
                                  {post.minutesToRead} min
                                </Paragraph>
                              </Attribute>
                              <Attribute>
                                <Icon src={icons.calendar} />
                                <Paragraph
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  lineHeight="160%"
                                >
                                  {dayjs(post.date).format('D MMM')}
                                </Paragraph>
                              </Attribute>
                            </AttributesWrapper>
                            <MoreButton to={`/news/${post.slug}`}>Read more</MoreButton>
                          </Wrap>
                        ) : (
                          <MoreButton to={`/news/${post.slug}`}>
                            Read more
                          </MoreButton>
                        )}
                      </NewsContent>
                    </Link>
                  </NewsItem>
                ))}
            </NewsWrapper>

            {displayCount < filteredPosts.length && (
              <LoadMoreButton onClick={handleLoadMore}>
                Load more
              </LoadMoreButton>
            )}
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

// Styled Components for Category Filter Buttons
const FilterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  gap: 1rem;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 18px;
  font-weight: 700;
  border: none;
  letter-spacing: 0.9px;
  border-radius: 0.5rem;
  color: ${(props) => (props.active ? brightPink : white)};
  background-color: ${(props) => (props.active ? secondary : base)};
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    background-color: ${secondary};
    color: ${brightPink};
    transition: 0.2s all;
  }
`;
const LoadMoreButton = styled.button`
  display: block;
  margin: 2rem auto;
  padding: 1rem 2rem;
  background-color: ${secondary};
  border: 2px solid ${brightPink};
  color: ${white};
  font-size: 1rem;
  font-weight: 700;
  border-radius: 1.125rem;
  width: 100%;
  height: 3rem;
  max-width: 328px;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    transform: scale(1.05);
  }

  @media ${mobile} {
  }
`;

const AttributesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${mobile} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const BlogTitle = styled(H6)`
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.75rem;
  margin: 0 0 1.5rem;

  @media ${mobile} {
    font-size: 1.25rem;
    margin: 0 0 1rem;
  }
`;

const Description = styled(Paragraph)`
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${mobile} {
    font-size: 0.875rem;
  }
`;

const MoreButton = styled(Link)`
  display: flex;
  margin: 0rem 0 0;
  color: ${brightPink};
  font-weight: 700;
  line-height: 160%;
  font-size: 1rem;
  white-space: nowrap;
  position: relative;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 1px;
    left: 0;
    background: ${brightPink};
    transition: width 0.3s;
    bottom: -0.25rem;
  }
  &:hover:after {
    width: 100%;
  }

  @media ${mobile} {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

const Attribute = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  color: ${white};
  margin-left: 1rem;

  @media ${mobile} {
    margin-right: 1rem;
    margin-left: 0rem;
  }
`;

const Icon = styled.img`
  height: 1rem;
  margin-right: 0.5rem;
`;
const Author = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: ${white};
  opacity: 0.5;
  font-weight: 500;
  line-height: 160%;
  margin: 0.5rem 0;

  strong {
    margin-left: 0.25rem;
    font-weight: 500;
  }

  @media ${mobile} {
    font-size: 0.75rem;
  }
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0.5rem;

  @media ${mobile} {
    margin: 0;
  }
`;

const Tag = styled.div`
  display: flex;
  padding: 0.5rem 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${green};
  color: ${textDark};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  white-space: nowrap;

  &.green {
    background-color: ${green};
  }
  &.yellow {
    background-color: ${yellow};
  }

  @media ${mobile} {
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
  }
`;

const NewsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;

  @media ${mobile} {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.2s all;
`;
const NewsItem = styled.div`
  display: flex;
  flex-direction: column;
  transition: 0.2s all;
  margin: 0 0 4.5rem;

  &:hover {
    cursor: pointer;
    ${Image} {
      transform: scale(1.05);
      transition: 0.2s all;
    }
  }

  @media ${mobile} {
    background: ${secondary};
    border-radius: 1.5rem;
    padding: 1.5rem;
    margin: 0 0 1rem;
  }
`;

const ImageWrapper = styled.div`
  height: 12.5rem;
  width: 100%;
  border-radius: 1.25rem;
  overflow: hidden;
  border: 1px solid ${brightPink};
  @media ${big} {
    height: 18.75rem;
  }
  @media ${mobile} {
    border: 0px;
    height: 9rem;
  }
`;

const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const NewsTop = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;

  @media ${mobile} {
    margin: 1rem 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${base};
  padding: 8rem 0 5.5rem;
  @media ${mobile} {
    height: initial;
    padding: 4rem 0 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${mobile} {
  }
`;
