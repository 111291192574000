import styled from 'styled-components';
import { white } from '../../styles/colors';
import { mobile, big } from '../../styles/breakpoints';

export const H6 = styled.h6`
  padding: ${(props) => props.padding || '0'};
  margin: ${(props) => props.margin || '0'};
  font-weight: ${(props) => props.fontWeight || 700};
  font-size: ${(props) => props.fontSize || '1.5rem'};
  line-height: ${(props) => props.lineHeight || '1.2'};
  color: ${(props) => props.color || white};
  max-width: ${(props) => props.maxWidth || '100%'};
  letter-spacing: ${(props) => props.letterSpacing || '1.3'};
  text-align: ${(props) => props.textAlign || 'none'};

  strong {
    background: var(
      --new-gradient,
      linear-gradient(45deg, #e78bf6 0%, #9867ff 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 130%;
    letter-spacing: 1.12px;
  }

  @media ${mobile} {
    font-size: ${(props) => props.fontSize || '1.25rem'};
    &.article {
      font-size: 2.5rem;
    }
    &.cta {
      font-size: 2.5rem;
      font-weight: 400;
      letter-spacing: -0.1rem;
    }
    &.pageTitle {
      font-size: 2.5rem;
    }
  }
  @media ${big} {
    font-size: ${(props) => props.fontSize || '2rem'};
  }
`;
