import {useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { H1, Paragraph, Container, Decoration } from '..';
import { FormControl, Input, Button } from '@chakra-ui/react';
import {
  base,
  baseSecondary,
  white,
  green,
  textDark,
} from '../../styles/colors';
import { motion } from 'framer-motion';
import { icons } from '../../utils/icons';
import { useMediaQuery } from 'react-responsive';

export const HeroBlog = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const [email, setEmail] = useState('');
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const submitStatus = urlParams.get('status');

    if (submitStatus === 'submitted') {
      window._show_thank_you();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const encodedEmail = encodeURIComponent(email);

    var head = document.querySelector('head'),
      script = document.createElement('script'),
      r = false;
    script.type = 'text/javascript';
    script.charset = 'utf-8';

    script.src = `https://block-games.activehosted.com/proc.php?u=15&f=15&s=&c=0&m=0&act=sub&v=2&or=715272d305b6350736f7e717c4829701&email=${encodedEmail}&jsonp=true`;
    // if (callback) {
    script.onload = script.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        // Callback
      }
    };
    // }
    script.onerror = function () {
      console.error('error');
    };

    head.appendChild(script);
  };

  return (
    <>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <TextWrapper>
              <H1
                as={motion.h1}
                initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
                animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                transition={{ ease: 'easeOut', duration: 0.3, delay: 0.2 }}
                maxWidth={isMobile ? '100%' : '90%'}
                textAlign={isMobile ? 'center' : 'left'}
              >
                What's new?
              </H1>
              <Paragraph
                as={motion.p}
                initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
                animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
                transition={{ ease: 'easeOut', duration: 0.3, delay: 0.3 }}
                margin={isMobile ? '2rem auto 1rem' : '1.5rem 0 1rem'}
                maxWidth={isMobile ? '100%' : '65%'}
                textAlign={isMobile ? 'center' : 'left'}
                fontSize={isMobile ? '1rem' : '1.25rem'}
              >
                Get BlockGames’ latest news & updates
              </Paragraph>
              <SubscriptionWrapper
                onSubmit={handleSubmit}
              >
                <CustomFormControl id="email">
                  <CustomInput
                    className="customInput"
                    type="email"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {isMobile ? null : (
                    <CustomButton type="submit">Subscribe</CustomButton>
                  )}
                </CustomFormControl>
                {isMobile ? (
                  <CustomButton type="submit">Subscribe</CustomButton>
                ) : null}
              </SubscriptionWrapper>
            </TextWrapper>
            <ImageWrapper>
              {isMobile ? null : (
                <>
                  <DecoWrapper1
                    as={motion.div}
                    animate={{ translateY: [0, 10, 0] }}
                    transition={{
                      ease: 'linear',
                      duration: 2,
                      repeat: Infinity,
                    }}
                  >
                    <Decoration iconHeight="2.5rem" icon={icons.arrow} />
                  </DecoWrapper1>
                  <Decoration
                    right="0"
                    bottom="-10rem"
                    icon={icons.blogDeco1}
                  />
                  <Decoration
                    right="5rem"
                    top="-10rem"
                    icon={icons.blogDeco2}
                  />
                  <Decoration left="0" top="8rem" icon={icons.blogDeco3} />
                </>
              )}

              {isMobile ? (
                <DecoWrapper1
                  as={motion.div}
                  animate={{ translateY: [0, 10, 0] }}
                  transition={{
                    ease: 'linear',
                    duration: 2,
                    repeat: Infinity,
                  }}
                >
                  <Decoration
                    iconHeight="4.75rem"
                    icon={icons.blogDecoMobile1}
                  />
                </DecoWrapper1>
              ) : null}
            </ImageWrapper>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const SubscriptionWrapper = styled.form`
  display: flex;

  @media ${mobile} {
    flex-direction: column;
  }
`;

const DecoWrapper1 = styled.div`
  position: absolute;
  top: -5rem;
  left: 5rem;
  @media ${mobile} {
    top: 2.5rem;
    left: 2rem;
  }
`;

const CustomFormControl = styled(FormControl)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${baseSecondary};
  height: 3.5rem;
  width: 90%;
  border-radius: 0.75rem;
  box-shadow: 3px 3px 0px 0px #280047;

  @media ${mobile} {
    flex-direction: column;
    width: 100%;
    height: initial;
  }
`;

const CustomButton = styled(Button)`
  background: ${white};
  border-radius: 0.5rem;
  color: #161616;
  font-weight: 700;
  height: 2.75rem;
  padding: 0 1.5rem;
  margin-right: 0.375rem;
  font-size: 1.125rem;
  font-weight: 600;
  border: none;
  transition: 0.2s all;

  &:hover {
    background: ${green};
    color: ${textDark};
    transition: 0.2s all;
    cursor: pointer;
  }
  @media ${mobile} {
    position: relative;
    height: 3rem;
    margin: 1rem 0 0;
    border-radius: 0.875rem;
  }
`;

const CustomInput = styled(Input)`
  background: transparent;
  width: 100%;
  padding: 0 1.5rem 0 1.5rem;
  height: 100%;
  border-radius: 100rem;
  outline: 0;
  border: 0;
  border-radius: 0px;
  font-size: 1.125rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: ${white};
  @media ${mobile} {
    position: relative;
    height: 3rem;
    margin: 0;
    padding: 0 1.5rem;
    border-radius: 0.875rem;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  position: relative;
  @media ${mobile} {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  @media ${mobile} {
    position: relative;
    z-index: 2;
    text-align: center;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  background: ${base};
  padding: 11rem 0 11rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${mobile} {
    padding: 6rem 0 11rem;
    width: 100%;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  @media ${mobile} {
    padding: 0 0px;
    width: 100%;
    flex-direction: column;
    text-align: center;
  }
`;
