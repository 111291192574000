export const members = [
  {
    id: 1,
    name: 'Ugne Gumenikovaite',
    linkedin: 'https://www.linkedin.com/in/ugne-gumenikovaite-a25a71147/',
    twitter: 'https://twitter.com/UgneGummy',
    image: 'ugne_gumenikovaite',
    role: 'Graphic Designer',
    unknown: false,
  },
  {
    id: 2,
    name: 'Nedas Sapalas',
    linkedin: 'https://www.linkedin.com/in/nedas-sapalas/',
    twitter: 'https://twitter.com/NedasSapalas',
    image: 'nedas_sapalas',
    role: 'Head of Partnerships',
    unknown: false,
  },
  {
    id: 3,
    name: 'Karolis Prekeris',
    linkedin: '',
    twitter: '',
    image: 'karolis_prekeris',
    role: '',
    unknown: true,
  },

  {
    id: 4,
    name: 'Chris Akhavan',
    linkedin: '',
    twitter: '',
    image: 'chris_akhavan',
    role: '',
    unknown: true,
  },
  {
    id: 5,
    name: 'Mateusz Staniszew',
    linkedin: 'https://www.linkedin.com/in/mateusz-staniszew-57a78b252/',
    twitter: 'https://twitter.com/Mateusz87279097',
    image: 'mateusz_staniszew',
    role: 'Motion Designer',
    unknown: false,
  },
  {
    id: 6,
    name: 'Mazvydas Skuodas',
    linkedin: 'https://www.linkedin.com/in/mazvydas-skuodas/',
    twitter: 'https://twitter.com/wanis_here',
    image: 'mazvydas_skuodas',
    role: 'CTO',
    unknown: false,
  },
  {
    id: 7,
    name: 'Vytis Bareika',
    linkedin: 'https://www.linkedin.com/in/vytisbareika/',
    twitter: 'https://twitter.com/vytisbareika',
    image: 'vytis_bareika',
    role: 'CMO',
    unknown: false,
  },
  {
    id: 8,
    name: 'Gabija Antanaityte',
    linkedin: 'https://www.linkedin.com/in/gabija-antanaityt%C4%97-034375210/',
    twitter: 'https://twitter.com/GabijaAnta54671',
    image: 'unknown1',
    role: 'Social Media Manager',
    unknown: false,
  },

  {
    id: 9,
    name: 'Benas Baltramiejunas',
    linkedin: 'https://www.linkedin.com/in/benasbaltramiejunas/',
    twitter: 'https://twitter.com/benbaltram',
    image: 'benas_baltramiejunas',
    role: 'CEO',
    unknown: false,
  },
  {
    id: 10,
    name: 'Kipras Gajauskas',
    linkedin: 'https://www.linkedin.com/in/kipras-gajauskas-52a465106/',
    twitter: 'https://twitter.com/yeskipras?lang=en',
    image: 'kipras_gajauskas',
    role: 'COO',
    unknown: false,
  },
  {
    id: 11,
    name: 'Laurynas Slikas',
    linkedin: 'https://www.linkedin.com/in/laurynas-%C5%A1likas-b728a414b/',
    twitter: 'https://twitter.com/_UncleLarry',
    image: 'laurynas_slikas',
    role: 'Head of Growth',
    unknown: false,
  },
  {
    id: 12,
    name: 'Jonas Pleckaitis',
    linkedin: 'https://www.linkedin.com/in/jonas-ple%C4%8Dkaitis-8054088b/',
    twitter: 'https://twitter.com/jonas_plec',
    image: 'jonas_pleckaitis',
    role: 'Head of Community',
  },
  {
    id: 13,
    name: 'Unknown2',
    linkedin: '',
    twitter: '',
    image: 'unknown2',
    role: '',
    unknown: true,
  },
  {
    id: 14,
    name: 'Matthew Pigott',
    linkedin: 'https://www.linkedin.com/in/mattpigott/',
    twitter: 'https://twitter.com/mattpigott',
    image: 'matthew_pigott',
    role: 'Content Manager',
    unknown: false,
  },
  {
    id: 15,
    name: 'Unknown3',
    linkedin: '',
    twitter: '',
    image: 'unknown3',
    role: '',
    unknown: true,
  },
  {
    id: 16,
    name: 'Unknown4',
    linkedin: '',
    twitter: '',
    image: 'unknown4',
    role: '',
    unknown: true,
  },

  {
    id: 17,
    name: 'Dziugas Meskauskas',
    linkedin:
      'https://www.linkedin.com/in/d%C5%BEiugas-me%C5%A1kauskas-9362b2119/',
    twitter: '',
    image: 'dziugas_meskauskas',
    role: '',
    unknown: false,
  },
  {
    id: 18,
    name: 'Danas Vogulis',
    linkedin: 'https://www.linkedin.com/in/danas-vogulis-364632135',
    twitter: '',
    image: 'danas_vogulis',
    role: '',
    unknown: false,
  },
];
