import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { big } from "../../styles/breakpoints";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  green,
  black,
  white,
  grey,
  border,
  textDark,
} from "../../styles/colors";

export const OutlineButton = ({
  onClick,
  label,
  disabled,
  type,
  className,
  icon,
  url,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <Link to={url}>
      <Wrapper
        as={motion.button}
        disabled={disabled}
        type={type}
        onClick={handleClick}
        className={className}
        whileHover={{
          scale: 1.1,
          backgroundColor: green,
          color: textDark,
          border: "1px solid #AAEE51",
          cursor: "pointer",
        }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: "spring", stiffness: 300, damping: 17 }}
      >
        {label}
        {icon ? <ButtonIcon src={icon} /> : null}
      </Wrapper>
    </Link>
  );
};

OutlineButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

OutlineButton.defaultProps = {
  type: "button",
  disabled: false,
};

const Wrapper = styled.button`
  font-family: "Inter", sans-serif;
  border: 1px solid ${white};
  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  color: ${white};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%; /* 21px */
  letter-spacing: 0.7px;
  background: transparent;
  border-radius: 8px;
  flex-shrink: 0;

  a {
    cursor: pointer;
    display: flex;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: ${white};
  }

  &.small {
    padding: 11px 24px;
    font-size: 14px;
  }
  &.white {
    background: ${white};
    color: ${black};
  }
  &.backButton {
    background: ${grey};
    border-radius: 48px;
  }
  &.filterButton {
    background: none;
    font-size: 14px;
    line-height: 24px;
    padding: 4px 32px;
    border-radius: 100px;
    border: 1px solid ${border};
    margin-right: 1rem;
  }
  &.w100 {
    width: 100%;
  }
  @media ${big} {
  }
`;

const ButtonIcon = styled.img`
  display: flex;
  height: 18px;
  margin-left: 12px;
`;
