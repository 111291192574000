import React from "react";
import styled from "styled-components";
import useScrollToTop from "../hooks/useScrollToTop";
import { mobile } from "../styles/breakpoints";
import { ReactComponent as SubscribeTextMobile } from "../assets/icons/early_access_text_mobile.svg";
import { ReactComponent as SubscribeText } from "../assets/icons/early_access_text.svg";
import mintBackground from "../assets/images/mint-background.svg";
// import mintBackground from "../assets/images/mint-background.png";
// import mintBackgroundMobile from "../assets/images/mint-background-mobile.png";
import mintBackgroundMobile from "../assets/images/mint-background-mobile.svg";
import { SubscribeForm } from "../components/subscribeForm/SubscribeForm";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  background: `url(${mintBackground})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  [`@media (max-width: 600px)`]: {
    background: `url(${mintBackgroundMobile})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
});

const ControlsContainer = styled.div({
  marginTop: "auto",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
});

const DesktopText = styled(SubscribeText)({
  marginBottom: 24,
  [`@media ${mobile}`]: {
    display: "none",
  },
});

const MobileText = styled(SubscribeTextMobile)({
  display: "none",
  [`@media ${mobile}`]: {
    display: "block",
    marginBottom: 24,
  },
});

export const Mint = () => {
  useScrollToTop();

  return (
    <Container>
      <ControlsContainer>
        <DesktopText />
        <MobileText />
        <SubscribeForm />
      </ControlsContainer>
    </Container>
  );
};
