import React from "react";
import styled from "styled-components";
import { mobile } from "../../styles/breakpoints";
import { icons } from "../../utils/icons";
import { H2, Paragraph, Container } from "..";
import { base, white, brightPink, secondary, cyan } from "../../styles/colors";
import pocH from "../../assets/lottie/PoC_horiz.json";
import pocV from "../../assets/lottie/PoC_vert.json";
import Lottie from "lottie-react";
import LazyLoad from "react-lazy-load";
import { useMediaQuery } from "react-responsive";

const blocks = [
  {
    id: 1,
    title: "For games",
    subtitle: `players and fees`,
    text: "For users acquired directly through the Player Network, games contribute a cost-per-install fee. Games also receive a revenue share, paid in $BLOCK, for contributing their own players to the network.",
    icon: icons.poc1,
  },
  {
    id: 2,
    title: "For players",
    subtitle: `engagement`,
    text: "Referrals, on-chain activities, in-game achievements, and community engagements generate instant rewards that can be claimed via BlockGames.",
    icon: icons.poc2,
  },
];

export const Poc = () => {
  const isMobile = useMediaQuery({ query: mobile });

  return (
    <>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <H2 maxWidth="100%" margin="0 auto" textAlign="center">
              Proof of <strong>contribution</strong>
            </H2>
            <LazyLoad height={800} offsetVertical={0}>
              <AnimationWrapper>
                <Lottie animationData={isMobile ? pocV : pocH} />
              </AnimationWrapper>
            </LazyLoad>

            <Blocks>
              {blocks.map((block) => (
                <Block key={block.id}>
                  <BlockIcon src={block.icon} />
                  <BlockTitle>{block.title}</BlockTitle>
                  <BlockSubtitle
                    className={
                      block.subtitle === "players and fees" ? "pink" : "cyan"
                    }
                  >
                    Contribution of <strong>{block.subtitle}</strong>
                  </BlockSubtitle>
                  <BlockText>{block.text}</BlockText>
                </Block>
              ))}
            </Blocks>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const Blocks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  width: 100%;
  margin: 0rem 0 0;
  @media ${mobile} {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
`;

const BlockText = styled(Paragraph)`
  font-size: 1.125rem;
  margin: 0rem 0 0;
  font-weight: 400;
  @media ${mobile} {
    order: 5;
    font-size: 0.875rem;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${secondary};
  border-radius: 2rem;
  padding: 4rem;
  @media ${mobile} {
    padding: 1.5rem;
    margin: 0 0 1rem;
  }
`;

const BlockIcon = styled.img`
  height: 12.5rem;
  @media ${mobile} {
    height: 7.75rem;
    order: 2;
  }
`;

const BlockTitle = styled.h3`
  font-size: 2rem;
  font-weight: 800;
  color: ${white};
  margin: 0;
  @media ${mobile} {
    order: 1;
    margin: 1.5rem 0 0;
  }
`;

const BlockSubtitle = styled.p`
  font-size: 1.125rem;
  letter-spacing: 0.36px;
  margin: 1rem 0;
  color: ${white};
  &.cyan {
    strong {
      color: ${cyan};
    }
  }
  strong {
    font-weight: 700;
    color: ${brightPink};
  }
  @media ${mobile} {
    font-size: 1rem;
    margin: 0.5rem 0 1rem;
    order: 3;
  }
`;

const AnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin: 5rem 0;
  @media ${mobile} {
    max-width: 400px;
    margin: 2.5rem auto;
  }
`;

const Wrapper = styled.div`
  background: ${base};
  padding: 9rem 0;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${mobile} {
    padding: 5rem 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${mobile} {
    text-align: center;
  }
`;
