import React from 'react';
import styled from 'styled-components';
import { icons } from '../../utils/icons';
import { H2, H6, Paragraph, Container, Decoration } from '..';
import { images } from '../../utils/images';

import { base, baseSecondary, cyan } from '../../styles/colors';

export const Advisors = () => {
  return (
    <>
      <Wrapper>
        <Decoration
          top="0rem"
          left="0rem"
          iconHeight="100%"
          icon={icons.advdeco1}
        />
        <Decoration
          top="0rem"
          right="0rem"
          iconHeight="100%"
          icon={icons.advdeco2}
        />
        <Container>
          <InnerWrapper>
            <H2 maxWidth="100%" margin="0 auto">
              Our <strong>advisors</strong>
            </H2>
            <Paragraph margin="1.5rem auto 0" maxWidth="40%">
              Supported by experienced advisors from the gaming industry across
              web2 & web3 industries
            </Paragraph>

            <AdvisorsWrapper>
              <Advisor>
                <AdvisorImage src={images.adv1} />
                <Button>Linkedin</Button>
                <H6 margin="0 0 0.5rem" fontSize="1.5rem" fontWeight="800">
                  Chris Akhavan
                </H6>
                <Paragraph fontSize="0.875rem" maxWidth="100%">
                  CGO at <strong> MagicEden</strong> & Ex VP Mobile at EA 
                </Paragraph>
                <Logos>
                  <Logo src={icons.adv1logo} />
                </Logos>
              </Advisor>
              <Advisor>
                <AdvisorImage src={images.adv2} />
                <Button>Linkedin</Button>
                <H6 margin="0 0 0.5rem" fontSize="1.5rem" fontWeight="800">
                  Veslavas Zapolski
                </H6>
                <Paragraph fontSize="0.875rem">
                  Founder at <strong> Estoty</strong>
                </Paragraph>
                <Logos>
                  <Logo src={icons.estoty} />
                </Logos>
              </Advisor>
              <Advisor>
                <AdvisorImage src={images.adv3} />
                <Button>Linkedin</Button>
                <H6 margin="0 0 0.5rem" fontSize="1.5rem" fontWeight="800">
                  Chris Akhavan
                </H6>
                <Paragraph fontSize="0.875rem" maxWidth="70%">
                  CGO at & Ex VP Mobile at EA 
                </Paragraph>
              </Advisor>
              <Advisor>
                <AdvisorImage src={images.adv4} />
                <Button>Linkedin</Button>
                <H6 margin="0 0 0.5rem" fontSize="1.5rem" fontWeight="800">
                  Veslavas Zapolski
                </H6>
                <Paragraph fontSize="0.875rem">
                  Founder at <strong> Estoty</strong>
                </Paragraph>
              </Advisor>
            </AdvisorsWrapper>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const AdvisorsWrapper = styled.div`
  display: flex;
  margin: 4.5rem 0 0;
`;

const Advisor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;
  flex-shrink: 0;
`;

const AdvisorImage = styled.img`
  height: 9.5rem;
`;

const Button = styled.button`
  background: ${cyan};
  color: ${base};
  border: none;
  border-radius: 0.625rem;
  margin: 1.5rem 0;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.5rem 1.25rem;

  &:hover {
    cursor: pointer;
  }
`;

const Logos = styled.div`
  display: flex;
  margin: 1.5rem 0 0;
`;

const Logo = styled.img`
  height: 3rem;
`;

const Wrapper = styled.div`
  background: ${baseSecondary};
  padding: 9rem 0;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;
