import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { H2, Paragraph, Container, LinkButton } from '../../components';
import { images } from '../../utils/images';
import { useMediaQuery } from 'react-responsive';
import { base, baseSecondary } from '../../styles/colors';
import gBlocks from '../../assets/gif/g_block.gif';
import gNfts from '../../assets/gif/g_nft.gif';
import gPersonalisedExperiences from '../../assets/gif/g_rewards.gif';
import { icons } from '../../utils/icons';

export const Dashboard = () => {
  const isMobile = useMediaQuery({ query: mobile });

  return (
    <>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <H2
              fontSize={isMobile ? '2rem' : '3.5rem'}
              margin="0 auto"
              maxWidth={isMobile ? '90%' : '80%'}
            >
              <strong>Track and share</strong> your gaming
              moments forever!
            </H2>
            <ImageWrapper>
              <Image
                src={images.personalized}
              />
            </ImageWrapper>
            <NewBlocks>
              <NewBlock>
                <Gif src={gBlocks} />
                <Paragraph
                  fontSize={isMobile ? '8px' : '12px'}
                  margin="0 auto 0.5rem"
                  fontWeight="700"
                >
                  Digital tokens
                </Paragraph>
              </NewBlock>
              <NewBlock>
                <Gif src={gPersonalisedExperiences} />
                <Paragraph
                  fontSize={isMobile ? '8px' : '12px'}
                  margin="0 auto 0.5rem"
                  fontWeight="700"
                >
                  Personalised experiences
                </Paragraph>
              </NewBlock>
              <NewBlock>
                <Gif src={gNfts} />
                <Paragraph
                  fontSize={isMobile ? '8px' : '12px'}
                  margin="0 auto 0.5rem"
                  fontWeight="700"
                >
                  Digital collectibles
                </Paragraph>
              </NewBlock>
            </NewBlocks>
            <Paragraph
              margin="0"
              fontSize={isMobile ? '14px' : '1rem'}
              fontWeight="700"
              maxWidth="696px"
              lineHeight="1.4"
            >
              Build up your universal player profile as you play, and receive personalised rewards.
            </Paragraph>
            <Paragraph
              margin="0 0 2rem 0"
              fontSize={isMobile ? '14px' : '1rem'}
              fontWeight="500"
              maxWidth="696px"
              lineHeight="1.4"
            >
              Tokens, digital collectibles, and personalised in-game experiences are just some of the treats on offer for players! So what are you waiting for?
            </Paragraph>
            <LinkButton
              url="/get-early-access"
              label="Get early access now"
            />
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const NewBlocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem auto 4rem;
  gap: 14px;

  @media ${mobile} {
    gap: 8px;
    margin: 2rem auto 2rem;
  }
`;

const NewBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${baseSecondary};
  border-radius: 14px;
  min-width: 180px;
  width: 180px;
  padding: 0.5rem 0;
  text-align: center;
  p {
    white-space: nowrap;
  }
  @media ${mobile} {
    width: 25%;
    padding: 0.5rem 0 0;

    p {
      white-space: normal;
      height: 30px;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
    }
  }
`;

const Gif = styled.img`
  height: 60px;
  margin: 0 auto 0.5rem;
  @media ${mobile} {
    height: 40px;
    margin: 0 auto 0rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  @media ${mobile} {
    max-width: 100%;
    margin: 2.5rem 0 0rem;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 900px;
  margin: 5rem auto 0rem;
  @media ${mobile} {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 0rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${base};
  padding: 9rem 0 10rem;
  @media ${mobile} {
    height: initial;
    padding: 5rem 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;
