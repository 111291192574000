import styled from 'styled-components';
import { base, baseSecondary } from '../styles/colors';
import { mobile, big, huge } from '../styles/breakpoints';

export const PrivacyPolicy = () => {
    const isSmallScreen = window.innerWidth < 500;
    return (
        <PageWrapper>
            <Container>
                <EffectiveDateWrapper>
                    Effective date: 21st of November, 2023
                </EffectiveDateWrapper>
                <div style={{ display: "flex", justifyContent: "center"}}>
                <ContentWrapper>
                    <HeaderTextWrapper>
                        {isSmallScreen ? <><span>BlockGames</span><br/><span>Privacy Policy</span></> : `BlockGames Privacy Policy` }
                    </HeaderTextWrapper>
                    <SubheaderTextWrapper>
                        1. GENERAL
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        Block Group Limited (referred to as “BlockGames”, “we”, “us” or “our” in this Privacy Policy) respects your privacy and is committed to protecting your Personal Data. This Privacy Policy (“Privacy Policy”) is designed to inform you how we handle your personal data when you download and use our application (“Platform”) and notify you about your privacy rights and how the law protects such rights. To the extent that the European General Data Protection Regulation (“GDPR”) or the United Kingdom General Data Protection Regulation (“UK GDPR”) apply to Personal Data that we process in accordance with this Privacy Policy, we act as a ‘data controller’ with respect to such processing.<br/><br/>
                        “Personal Data” means any information about an individual from which that person can be identified. Capitalized terms used but not otherwise defined in this Privacy Policy shall have the meanings ascribed to such terms in the Terms of Use.<br/><br/>
                        We encourage you to read this Privacy Policy carefully, and to regularly check this page to review any changes we might make to the terms of this Privacy Policy. This Privacy Policy supplements other policies we may provide as part of our interactions with you or policies you may have received from other controllers and is not intended to override such policies.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        2. THE DATA WE COLLECT ABOUT YOU
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        When you use this Platform, we may collect the following types of Personal Data on you:<br/><br/>
                        <ul>
                            <li>
                                Identity Data includes user name and password.
                            </li>
                            <li>
                                Contact Data includes email address and phone number.
                            </li>
                            <li>
                                Country Data includes the country from which you use the Platform.
                            </li>
                            <li>
                                Usage Data includes information about how you use or interact with our Platform (such as, when you entered the Platform, how long you stayed in the Platform and information on your in-app purchases).
                            </li>
                            <li>
                                Technical Data includes information on your internet connection (such as your IP address and internet service provider name), on the device and software that you are using to access the Platform (e.g., your web browser type, mobile device type, and computer operating system).
                            </li>
                            <li>
                                Additional Inquiry Data includes additional information that you may provide when you contact.
                            </li>
                        </ul>
                        We also collect, use and share aggregated data such as statistical data for any purpose. Such information does not reveal your identity, and therefore is not considered Personal Data. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific Platform feature.<br/><br/>
                        If you register to the Platform via your Google, Facebook or Platformle account, we may collect additional Identity Data, such as your first and last name, profile picture and other details received from such companies.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        Protecting the Privacy Rights of Third Parties
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        If you provide to us any Personal Data relating to others, you must make sure that you have permission to do so.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        3. HOW WE COLLECT YOUR PERSONAL DATA
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        We use different methods to collect data from and about you including through:<br/><br/>
                        <ul>
                            <li>
                                Direct interactions. You may provide us your Identity Data and Contact Data by entering your details in our Platform or by signing in with your Google, Facebook or Apple account. You may also provide Additional Inquiry Data to us when you contact us.
                            </li>
                            <li>
                                Automated technologies or interactions. As you interact with our Platform, we automatically collect Technical Data and Usage Data. We collect this data by using server logs and other similar technologies.
                            </li>
                            <li>
                                Third parties or publicly available sources. We may receive Technical Data and Usage Data about you from third parties, such as Usage Data and Technical Data that we may receive from analytics providers, and from advertising and marketing partners.
                            </li>
                        </ul>
                        You are not legally obligated to provide any Personal Data to BlockGames. Any Personal Data that you choose to provide to BlockGames, is provided at your own free will. <br/><br/>
                        You remain the sole and exclusive owner of all personal data provided.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        4. HOW WE USE YOUR PERSONAL DATA
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        We will only use your Personal Data when permitted by law.<br/><br/>
                        We will commonly use your Personal Data in the following circumstances:<br/><br/>
                        <ul>
                            <li>
                                Where the processing is necessary in connection with the performance of any contract we have entered into with you or where it is necessary for taking steps at your request prior to entering a contract.
                            </li>
                            <li>
                                Where the processing is necessary for our legitimate interests (or those of a third party), and such legitimate interests are not overridden by your interests, fundamental rights, or freedoms. Legitimate Interest means our interest in managing and conducting our business.
                            </li>
                            <li>
                                Where the processing is necessary for compliance with a legal or regulatory obligation.
                            </li>
                            <li>
                                Where you have provided your consent for such processing. Such consent can be revoked at any time.
                            </li>
                        </ul>
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        Purposes for Which We Use Your Personal Data
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        Below is a description of the ways we use your Personal Data. To the extent that the GDPR and UK GDPR apply, we have mentioned the legal bases we rely on to use your Personal Data, and identified what our legitimate interests are where appropriate: We use your Identity Data and Contact Data to enter into a contract or to perform a contract with you. We also use your Usage Data for the performance of a contract with you (to provide our services to you via the Platform).<br/><br/>
                        <ul>
                            <li>
                                We use your Contact Data and Additional Inquiry Data to respond to your requests or questions and to manage our relationship with you, including by notifying you about changes to our terms or Privacy Policy. We do so for the performance of a contract with you, if it is necessary to comply with a legal obligation or if it is necessary for our legitimate interests
                            </li>
                            <li>
                                We use your Usage Data, Technical Data, Country Data and Contact Data for marketing purposes and to deliver relevant and personalized content to you, as well as service messages (for example, for security purposes) including via email or text message (SMS), and to measure or understand the effectiveness of our marketing efforts. We do so where it is necessary for our legitimate interests (to grow our business, to inform our marketing strategy and to maintain and secure the Services); or if we have obtained your prior consent (where laws require consent for such communications).
                            </li>
                            <li>
                                We use your Usage Data and Technical Data to improve our Platform, services, marketing, customer relationships and experiences, including by personalizing and customizing our content. We do so where it is necessary for our legitimate interests (to keep our Platform updated and relevant, to develop our business and to inform our marketing strategy).
                            </li>
                            <li>
                                In the event of a legal dispute between you (and/or a party on your behalf) to us (and/or a party on our behalf), we may use your Personal Data to the extent necessary in connection with handling such dispute. We do so where it is necessary for our legitimate interests (to defend and enforce our legal rights).
                            </li>
                            <li>
                                We may also use your Personal Data as: (a) required by subpoena, law, or other legal process; (b) necessary to assist law enforcement officials or government enforcement agencies; (c) necessary to investigate violations of or otherwise enforce our terms of use, to maintain security of the Platform and prevent fraudulent or illegal activity; (d) necessary to protect us from legal action or claims from third parties, including you and/or other users or members; or (e) necessary to protect the legal rights, personal/real property, or personal safety of our company, users, employees, and affiliates. We do so based on our legitimate interest (to defend and enforce our legal rights and to protect and ensure the continuous operation of our Platform).
                            </li>
                            <li>
                                Note that we may process your Personal Data for more than one lawful ground depending on the specific purpose for which we are using your data.
                            </li>
                        </ul>
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        Promotional Offers
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        You will receive marketing communications from us if you have agreed to receive such communications or otherwise requested information from us and you have not opted out of receiving marketing communications. You can change your contact preferences at any time by sending us an email with your request to the email that appears in the Contact Details section below. If you wish to stop receiving marketing communications from us, you may ask us to stop sending you marketing communications at any time by following the opt-out link on any marketing message sent to you or by contacting us.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        Notifications
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        We may send notifications to your mobile device (push notifications) or email regarding your activity, or activity relating to you, in the Platform including text messages and push notifications. At any time, you can manage your push notification preferences or deactivate these notifications by turning off the notification settings in the device settings of your mobile device. You may ask us to stop sending email notification to you at any time by contacting us, however you will not be able to opt-out of receiving service messages from us, including legal and security notices (such as two-factor authentication messages).
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        Third-Party Links
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        The Platform may include links to third-party applications, websites and plug-ins. Clicking on those links or interacting with such content may allow your data to be shared with the relevant third-party. We do not control these third-party applications, websites and plug-ins and are not responsible for their privacy statements; we therefore recommend that you read the applicable privacy policies.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        Children under 13
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        In order to use the Platform, you must be at least 13 years of age, or older, if otherwise required by the laws of the country where you reside. Even though our Platform is not designed for use by anyone under the age of 13, we realize that a child under the age of 13 may attempt to access our Platform. We do not knowingly collect Personal Data from children under the age of 13.
                        If you are not 18 years old or older, you must review this Privacy Policy with your parent or legal guardian. If you are a parent or legal guardian and have concerns about your child’s privacy, or if you believe that your child may have provided us with their Personal Data, please contact us using the Contact Details below. When you approach us as a parent or legal guardian, we may request that you provide certain information needed to confirm your identity, in order to prevent malicious removal of account information.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        5. SHARING YOUR PERSONAL DATA WITH THIRD PARTIES
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        We share Personal Data with third-party service providers that provide us services in connection with the purposes listed above. For example, we may share your Usage Data and Technical Data with analytics providers and other vendors that assist us in maintaining the Platform. We may also share your Identity Data and Contact Data, with email and marketing service providers. In addition, we will share Usage Data regarding your in-app purchases with providers of Games for which you choose to utilize your BlockGames Credits via the Platform. We may also share your Personal Data with legal and regulatory authorities to the extent required by applicable law our professional advisors (e.g. lawyers, accountants) to the extent necessary for the provision of their services to us; and third parties to whom we may choose to sell, transfer, or merge our business (or parts thereof) or our assets, or parties whose business we wish to acquire.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        6. INTERNATIONAL TRANSFERS
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        We may transfer your Personal Data to countries outside of the country in which you reside (including outside of the European Economic Area (EEA) or the United Kingdom (UK)), that do not provide the same level of data protection as the country in which you reside and are not recognized by the European Commission and/or the applicable UK authority as providing an adequate level of data protection.<br/><br/>
                        Our transfers of Personal Data outside of the EEA or the UK are done for the performance of a contract or implementation of pre-contractual relations with you, based on your consent, or subject to safeguards that ensure the protection of your Personal Data, such as standard contractual clauses approved by the European Commission.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        7. DATA SECURITY
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        We have put in place appropriate security measures to prevent your Personal Data from accidental or unlawful loss, use, alteration or disclosure. We and the third parties who provide services for us, also maintain technical and physical safeguards to protect your Personal Data. We limit access to your Personal Data to those employees, agents, contractors and other third parties who have a need to know. We require them to only process your Personal Data on our instructions and as allowed by applicable law. Unfortunately, we cannot guarantee against the loss or misuse of your Personal Data or secure data transmission over the Internet because of its nature.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        8. DATA RETENTION
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        We retain your Personal Data for as long as necessary to fulfill the purposes we collected it for, including for legal or reporting requirements. In order to determine the appropriate retention period for Personal Data, we consider the following criteria: the volume, nature, and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of Personal Data, the purposes for which we collect and process Personal Data, the timeframe during which we may require such Personal Data in connection with potential litigation and applicable legal requirements.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        9. YOUR RIGHTS
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        Certain privacy laws, including the GDPR and UK GDPR, provide users with rights related to their Personal Data. To the extent that such laws apply to your Personal Data, you may have the following rights:<br/><br/>
                        <ul>
                            <li>
                                Request access. This means that you may receive a copy of the Personal Data we hold about you.
                            </li>
                            <li>
                                Request correction. This means that you may ask us to correct or complete inaccurate or incomplete data that we hold about you.
                            </li>
                            <li>
                                Request erasure. This means that you may ask us to delete or remove Personal Data that we hold about you. If we are unable to comply with such request, we will notify you of the specific legal reasons for our decision.
                            </li>
                            <li>
                                Object to processing. This means that you may object to the processing of your Personal Data where it is used for direct marketing purposes, or where we are relying on a legitimate interest and you feel that such interest is overridden by your rights and freedoms.
                            </li>
                            <li>
                                Request restriction of processing. This means that you may ask us to suspend the processing of your Personal Data if you believe that such data is inaccurate, if our processing is unlawful or if we no longer need to process such data for a particular purpose, but you need us to continue to hold the data.
                            </li>
                            <li>
                                Data Portability. This means that you may request that we transfer your Personal Data to you or to a third party. This right applies where we have obtained and processed the data from you based on your consent or where we used the information to perform a contract with you.
                            </li>
                            <li>
                                Withdraw consent at any time. This means that you may withdraw your consent where we are processing your Personal Data based on your consent.
                            </li>
                        </ul>
                        If you wish to exercise any of the rights set out above, please contact us.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        What We May Need From You
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        When you approach us with a request to exercise one of your rights, we may request that you provide us with information needed to confirm your identity as a security measure to ensure that your Personal Data is not disclosed to any person who has no right to receive it.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        Time Limit to Respond
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        We try to respond to all legitimate requests within one month or less, if required by law. Where it takes us more than one month to respond (for example, where your request is particularly complicated or where you have made a number of requests) we will notify you and keep you updated.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        10. ACCESSIBILITY 
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        If you have a disability and would like to access this policy in an alternative format, please contact us via our Contact Details listed below.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        11. CONTACT DETAILS
                    </SubheaderTextWrapper>
                    <TextWrapper marginBottom="50px">
                        If you have any questions or concerns, please contact us at:<br/>
                        Email: <a style={{ color: "#F1E7FA", textDecoration: "underline"}} href="mailto:general@blockgames.com">general@blockgames.com</a> <br/>
                        Postal address: 229, Carrara Tower, Floor 33, 250 City Road, London, United Kingdom.<br/><br/>
                        You have the right to make a complaint at any time to data protection authorities. We would, however, appreciate the chance to address your concerns before you approach a data protection authority, so please contact us in the first instance.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        12. CHANGES TO THE PRIVACY POLICY
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        We reserve the right to change this Privacy Policy at any time. If we do so, we will post an updated version of this Policy so that our users and customers are always aware of what information we collect, use, and disclose. Your continued use of our Platform and services after any change to this Privacy Policy will constitute your acceptance of such change.
                    </TextWrapper>
                </ContentWrapper>
                </div>
                
            </Container>
        </PageWrapper>
    )
}

const PageWrapper = styled.div`
  display: flex;
  background: ${base};
  width: 100%;
  height: 100%;
  margin-top: 150px;
  margin-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 30px;
  justify-content: center;
  background: ${baseSecondary};
  @media ${mobile} {
    max-width: 100%;
    padding: ${(props) => props.padding || '0 20px'};
  }
  @media ${big} {
    max-width: 1400px;
  }
  @media ${huge} {
    max-width: 1600px;
  }

  &.mobile-noP {
    @media ${mobile} {
      padding: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  max-width: 700px;
  padding-top: 20px;
  padding-bottom: 40px;
`;

const EffectiveDateWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  padding-right: 40px;
  justify-content: flex-end;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  color: #9F87B2;
`;

const HeaderTextWrapper = styled.div`
  width: 100%;
  color: #F1E7FA;
  text-edge: cap;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: 0.05625rem;
  text-transform: capitalize;
  margin-top: 40px;
  margin-bottom: 60px;
  @media ${mobile} {
    font-size: 35px;
  }
`

const SubheaderTextWrapper = styled.div`
  color: #F1E7FA;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 25px;
`;

const TextWrapper = styled.div`
  color: #F1E7FA;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: ${(props) => props.marginBottom || '25px'};
  white-space: pre-wrap;
  ul {
    margin-block-start: 0px;
  };
  ul li {
    display: flex;
    margin: 0;
    padding: 4px 0;

    &::before {
      content: "•";
      padding-right: 10px;
      margin-left: -2em;
    };
`;