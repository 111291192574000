import styled from 'styled-components';
import { base, baseSecondary } from '../styles/colors';
import { mobile, big, huge } from '../styles/breakpoints';

export const TermsOfUse = () => {
    const isSmallScreen = window.innerWidth < 500;
    return (
        <PageWrapper>
            <Container>
                <EffectiveDateWrapper>
                    Effective date: 21st of November, 2023
                </EffectiveDateWrapper>
                <div style={{ display: "flex", justifyContent: "center"}}>
                <ContentWrapper>
                    <HeaderTextWrapper>
                        {isSmallScreen ? <><span>BlockGames</span><br/><span>Terms of use</span></> : `BlockGames Terms of use` }
                    </HeaderTextWrapper>
                    <SubheaderTextWrapper>
                        GENERAL
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        These Terms of Use (the “Terms”) govern your access and use of products and services offered by BlockGames (“BlockGames”) and the User concerning the Platform (“Platform”), websites, mobile applications, digital events and/or products, content and services. Please carefully read the following Terms before using the Platform so that you are fully aware of your legal rights and obligations.<br/><br/>
                        The Terms govern your access to and use of the Platform, the Content, the Services (as such terms are defined below) and the features contained therein.<br/><br/>
                        The term “us”, “we,” or “our”, refers to BlockGames, the owner of the Platform. The term “you” refers to any User of the Platform.<br/><br/>
                        The BlockGames reserves the right to update and change the Terms at any time. You can always review the last updated version of the Terms at the Platform and/or website.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        DEFINITIONS:
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        <ul>
                            <li>
                                “Platform” means the mobile application or the browser-based platform, offered by BlockGames in its latest version, and all documentation, Content, and Services that will be made available by us to you;
                            </li>
                            <li>
                                “User” means any person or entity installing, accessing or using the Platform;
                            </li>
                            <li>
                                “Account” means an account opened by the User on the Platform;
                            </li>
                            <li>
                                “Content” means any input or data displayed or otherwise available on the Platform, whether published and/or uploaded and/or posted by us, by you or by any other person or entity;
                            </li>
                            <li>
                                “External sites” means any third-party websites, plug-ins or applications, including Games;
                            </li>
                            <li>
                                “Credits” means virtual currency that is earned while interacting with Games or by other means on the Platform;
                            </li>
                            <li>
                                "Game” means any game listed in the Platform, as updated from time to time;
                            </li>
                            <li>
                                “Tickets” means virtual currency that is earned while interacting with Games or exchanged on the Platform;
                            </li>
                            <li>
                                “Services” means all services provided through the Platform, including (among others) exchange Credits for Tickets, exchange Credits to gifts and/or rewards, and to send notifications relating to the Platform.
                            </li>
                        </ul>
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        ELIGIBILITY
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        The Terms are effective as of the moment you first install, access or use the Platform. Access or use of the Platform indicates your acceptance of the terms and conditions contained herein and you agree to be bound by these Terms with respect to your access and use of the Platform, unless a User is terminated as described below. <br/><br/>
                        To access some of the Services, you may have to sign up and create an account. You must have a valid email address or login details you make via a third party to create an account. Eligibility for an account and to use our Services is subject to (i) your information is truthful and will remain accurate and complete (ii) you have the legal capacity to agree to these Terms (iii) you are at least 18 years old (iv) the Platform and Services are available in your country and your use of them does not violate any applicable law.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        ACCESS
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        BlockGames hereby grants the User a limited, revocable, non-exclusive, non-transferable, non-sublicensable license to install, access the Platform and to use the Services by User’s private noncommercial use in the light of these Terms. 
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        IDENTITY VERIFICATION
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        BlockGames may require in order to provide accurate Services user identity information before accessing the Platform or using certain Services. Promptly following BlockGames’s request, user should provide BlockGames with required information, including the Users full name data to process KYC & KYB procedure. The User hereby represent, warrant, and covenant that such Content is accurate, current, and complete. BlockGames reserves the right to suspend user profile and account until KYC & KYB of user will be verified pursuant and to terminate Account if user fails to comply with KYC obligations. The User acknowledges and authorize BlockGames and its agents to make such investigative inquiries and request such third-party reports as it deems necessary to verify User’s identity and that of User’s principals and other agents.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        AVAILABILITY OF SERVICES
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        The BlockGames offers Credits that can be redeemed for rewards when users create a universal player profile, activate games, and share data while playing them with BlockGames. We are constantly changing and improving our Services and may at any time add or remove functionalities or features of the Service. New features or tools added to the Services will become subject to these Terms.<br/><br/>
                        We do not guarantee that all Services will be available at all times, in all locations, due to technical, regulatory, and other restrictions. You may not use any technology or technique, such as a VPN service, to obscure or disguise your location. We may use technologies to verify your geographic location and restrict access to the Services from other locations.<br/><br/>
                        The User may not use BlockGames’s Services if the User (i) are located in a country that is subject to any sanctions by the U.S. or other similar authority (“Sanctioned Country”) (ii) are subject to sanctions or otherwise designated on any list of prohibited or restricted parties, including but not limited to the lists maintained by the United Nations Security Council, the U.S. Government (e.g., the Specially Designated Nationals List and Foreign Sanctions Evaders List of the U.S. Department of Treasury or the Consolidated Canadian Autonomous Sanctions List), the European Union or its Member States or other applicable government authority. Any violation of this section may result in loss of features, up to and including termination of User’s account. <br/><br/>
                        The BlockGames is not liable for any delay or failure to perform under the Terms resulting from causes beyond BlockGames’s reasonable control, such as acts of God, war, government mandates, pandemics, failure of transportation, communication or suppliers of goods or services, cyberattacks. 
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        CONTENT AND DATA OF THE USER
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        The User solely responsible for all Content provided, uploaded, submitted, or posted to, or generated through access to or use of the Platform including by connecting or communicating with other Users (“Your Content”). The User’s responsibility for Content extends to resolving any disputes that may arise between the User or any person because of Content. <br/><br/>
                        By providing, uploading, submitting, posting, or generating Content, the User grants BlockGames and its authorized representatives and contractors a perpetual and non-exclusive right and license to use, process, store, and transmit, and disclose the User’s Content (a) to provide the Services and fulfill other obligations described under these Terms (b) to further develop and provide services for BlockGames customers. The User understands that in certain contexts its Content may be visible to, sent to, and viewed by other Users and you expressly waive any privacy rights you may otherwise have in User’s Content in such contexts. <br/><br/>
                        The User agrees to allow us, if we elect in our sole discretion, to provide its Content to other Users in connection with identity verification Services, described below. The User represent, warrant, and covenant that its Content: (a) does not violate the Terms or any applicable laws (b) is not libelous, defamatory, obscene, abusive, pornographic, threatening, or an invasion of privacy (c) does not constitute an infringement or misappropriation of the intellectual property rights or other rights of any third-party (d) is not an advertisement or solicitation of funds, goods, or services (e) is not false, misleading, or inaccurate (f) could not be considered junk mail, spam, a part of a pyramid scheme, a disruptive commercial message or disruptive advertisement.<br/><br/>
                        BlockGames is not responsible or liable for any deletion, correction, destruction, damage, loss or failure to store or back-up any of User’s Content. The User agree that all right, title, interest and consent in User’s Content to allow BlockGames to use Users Content to the BlockGames.<br/><br/>
                        The BlockGames may collect User’s information about device, connectivity and use of the Services to allow us to validate eligibility for an account, run Promotions, determine your level of Rewards, and identify bugs and crashes that may affect User’s use of the Services. <br/><br/>
                        To prevent fraud at redemption, we may require Users to share exact geolocation with us and/or provide access to a video-selfie. The User can continue to use all other features of the Services without performing the required human/identity verification. However, the User may be unable to redeem Rewards in the catalog.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        REWARDS
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        Users of BlockGames earn Credits and digital items in exchange for certain activities such as building out their universal player profile, activating games, sharing data from third-party games like reaching gameplay milestones, completing tasks, referral’s and participating in virtual events. The number of Credits and digital items earned for actions with the Services and in games discovered through BlockGames varies and is not the same for all BlockGames Users. Different Users might receive different amounts of Credits, even for identical actions or interactions.<br/><br/>
                        The User can redeem Credits for different rewards BlockGames makes available in the catalog for each User. The rewards may include gift cards, gift certificates, prepaid cash cards, third-party game content, digital items or contest tickets (collectively “Rewards”). The representative value of Credits will be applied against the pre-tax purchase amount (i.e., excluding taxes, fees, and shipping and handling charges) for each Reward. The User is responsible for all federal, state, and local taxes and any other costs of accepting and using the Reward.<br/><br/>
                        The specific requirements to earn Credits may be viewed within the Services or where we present an opportunity for the User to earn Credits. Restrictions apply to earning Credits, digital items and ultimately Rewards, including (i) User’s ability to participate in the Rewards Program itself (ii) the allowable frequency of activities (iii) the number of Credits the User can earn for a given activity (iv) the activities or Rewards available (v) the number of Credits required for particular Rewards (vi) the number of Credits or Rewards the User can earn during a given time frame, which is also subject to the limits Game partners place on providing information about certain gameplay data (e.g. in-app purchases). In-game purchase history in eligible games counts for Users to earn Credits or Rewards only where BlockGames receives gameplay data from game partners.<br/><br/>
                        Credits cannot be redeemed for cash or in any way resold, exchanged or returned for a cash refund, unless required by law. Credits are promotional and have no cash, monetary or other value. While we may use terms like "buy", "purchase", "earn" or "currency" to reference Credits, we do so only for convenience, and such terms in no way indicate that BlockGames is offering real-world fiat currency. If the User do not earn any Credits within a 90-day period, Credits will expire and will not be available to redeem for Rewards. We provide up to thirty days’ notice of Credits expiration in an account that is not terminated if notice is required under laws that apply to the User.<br/><br/>
                        To redeem Credits for Rewards, the User will have to visit the dashboard within the Services to see available Credits and select from the catalog, those Rewards available to the User.<br/><br/>
                        The BlockGames are not responsible for any improper fulfillment of an obligation by the Games or any Third Parties whom the User should contact directly for customer support for problems with games or any Third-Party Components (e.g., when Games does not send information to BlockGames to assure usage is tracked for earning Credits). <br/><br/>
                        The User understands that we are not liable for the activity of the companies or Third Parties that offer gift cards or other items. BlockGames is not responsible for any lost, stolen or malfunctioning Rewards except to the extent caused by gross negligence or willful misconduct of BlockGames. For instance, the User may be required to use the gift cards within a particular time period, and there may be limitations to their use or redemption. The User should carefully read any terms and policies that may apply, as they are separate from this Agreement, and also separate from User’s relationship with BlockGames. If the User terminates or close the account, the Credits may be burned and eliminated without right to recovery.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        CONTESTS
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        We may, in our sole discretion, run certain contests (“Contests”) for Users from time to time. Such contests may offer event participation using Tickets entry, or incentives for certain User actions, such as reaching certain milestones in playing games or completing tasks in connection with the Rewards Program.<br/><br/>
                        Certain contests  (e.g. sweepstakes) are available only in jurisdictions where applicable laws permit them. Rewards won through Contests will be applied to your account after meeting specific requirements set out in the terms and conditions that apply to each Contest (“Contest Terms”) and will apply in addition to these Terms. The specific number of digital items available from a Promotion will be provided in applicable Contest Terms on the Platform.<br/><br/>
                        Other requirements, conditions, and exclusions may apply. When you participate in a Contest, it is your responsibility to check the Contest Terms on the Platform.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        THE USER CONDUCT
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        The User required to comply with the applicable laws at all times when using the Services and will be not allowed to including but not limited to: create multiple accounts, transfer or sell account to third parties, use account on behalf of third party, grant access to not authorized person to the account, use account for business purpose, use a virtual private network, proxy, use of internet bots, use disposable email addresses, use vulgar username or bio. <br/><br/>
                        Any account, device or User that appears to operate contrary to indicated actions above or in the Terms will be valued as material breach of this Agreement and may be suspected of, and reported to the authorities for, fraudulent activity. In addition, the BlockGames may remove any Rewards, suspend access to an account or permanently block an account or device of the User which acts contrary to the appropriate user conduct guidelines.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        TITLE & OWNERSHIP
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        The BlockGames holds ownership to all right, title and interest, including any and all intellectual property rights, confidential information and all components of the Platform, products or Services, including without limitations Games, applications, website and/or software, and all modifications, enhancements, improvements, marketing content and/or derivatives to any of the foregoing which cannot be used without written approval of the BlockGames.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        PRIVACY & DATA SECURITY
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        The BlockGames and the User hereby undertakes to comply with all privacy laws applicable thereto. Without derogating from the generality of the foregoing, each Party hereby undertakes to comply with all applicable privacy laws in all material respects, in connection with any and all data and information which may be collected by it under the use of Platform and Services. Each Party further agrees to utilize administrative, physical, and technical safeguards designed to protect any and all sensitive or personal data from unauthorized access, disclosure, acquisition, destruction, use, or modification, to adhere to any applicable law relating data security, and to maintain an adequate cybersecurity framework based on and consistent with industry standards.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        BETA VERSION
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        For any Service that is identified by us as a "beta" version ("Beta Version"), the User acknowledges and agree that a Beta Version may contain more or fewer features than the final release of the Service. We reserve the right, in our sole discretion, not to release a final release of the Beta Version or to alter its features, specifications, capabilities, functions, licensing terms, release dates, general availability or other characteristics. Beta Version may not be suitable for production use and may contain errors affecting proper operation and functionality until release of Final Version. The User acknowledges and agrees that in no event shall BlockGames be liable for damages, including any losses as a result of a failure of any Beta Version use by the User.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        WARRANTIES AND LIMITATION OF LIABILITY
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        In no event (excluding such Party’s willful misconduct or gross negligence) shall either Party has any liability to the other party for any indirect, special, incidental, punitive, or consequential damages, however caused, or for any lost profits, loss of use, cost of data reconstruction, cost or procurement of substitute goods or services, whether in contract, tort or otherwise, arising out of, or in any way connected with the Terms.<br/><br/>
                        The User and the BlockGames represents and warrants to the other Party that: (i) it has the full right, power and authority to enter into and perform its obligations under the Terms (ii) its entry into and performance of the Terms, and the other Party’s exercise of its rights hereunder, will not (a) conflict with or result in a breach or violation of any of terms or provisions of (or constitute a default under) any agreement or privacy policy by which it is bound, or (b) otherwise breach any other obligation or duty that it owes to a third party; and (iii) it will comply with all applicable laws, regulations, consent decrees, privacy policies, terms of use as well as other agreements with its users in its performance of the Terms.<br/><br/>
                        The BlockGames further represents and warrants towards the User that (i) it is the owner or is licensed to use the entire content of the Platform and Content, and such do not infringe upon the intellectual property, property or privacy rights of any third party and (ii) the Platform and Content do not contain any content that by its nature may be reasonably deemed as objectionable, including without limitations content that is harmful, threatening, defamatory, obscene, harassing, promotes discrimination or illegal activities, contains profanity or software viruses, Trojan horses, worms, unlawful pop-up advertising, spyware, malware or other computer instructions, devices, or techniques that erase data or programming, infect, disrupt, damage, disable, or shut down a device system or any component of such device system.<br/><br/>
                        The User shall defend, indemnify, and hold harmless the BlockGames and each of their respective employees, officers, directors, and representatives from and against any claims, liabilities, settlements, disputes, damages, losses costs and expenses (including, without limitation, direct and indirect costs and expenses) arising out of or relating to: User’s breach of the Terms or violation of applicable law; any use of your Account, the Platform, and the Services by any user including yourself; User violation of any laws; the breach or non-fulfillment of any representation and warranty or covenant made by the User in these Terms; User’s provision of false, incomplete or misleading information (including, without limitation, citizenship, residency, nationality or email address); and/or Users negligence, fraud or misconduct. BlockGames reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and the User agrees to cooperate with our defense of these claims. The User agrees not to settle any matter without the prior written consent of BlockGames. The BlockGames will use reasonable efforts to notify the User of any such claim, action or proceeding upon becoming aware of it.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        TERMINATION
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        The User may stop using the Services at any time, for any reason, by deleting account at the BlockGames Platform or discontinue use of any or the Services.<br/><br/>
                        The BlockGames may use the right to temporarily suspend or permanently terminate account of the User if (i) the BlockGames are required to do so to comply with a legal requirement (ii) the BlockGames believe with reason that there has been conduct that creates harm to the BlockGames, user or third party (iii) the User materially or repeatedly breach the Terms (iv) the BlockGames may terminate without notice any account which has been inactive for a consecutive period of twelve (12) months (v) Upon termination of the Terms, the User shall cease all use of the App and/or the Services.<br/><br/>
                        In the event that the BlockGames suspend or terminate the User’s account and use of the App and/or the Services, the User acknowledges and agrees that will not receive any refund, including for any unused Credits and/or Rewards.
                    </TextWrapper>
                    <SubheaderTextWrapper>
                        GOVERNING LAW
                    </SubheaderTextWrapper>
                    <TextWrapper>
                        The Terms are governed and construed in accordance with the law of England and Wales and the parties hereby submit to the exclusive jurisdiction of the courts of England and Wales.
                    </TextWrapper>
                </ContentWrapper>
                </div>
                
            </Container>
        </PageWrapper>
    )
}

const PageWrapper = styled.div`
  display: flex;
  background: ${base};
  width: 100%;
  height: 100%;
  margin-top: 150px;
  margin-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 30px;
  justify-content: center;
  background: ${baseSecondary};
  @media ${mobile} {
    max-width: 100%;
    padding: ${(props) => props.padding || '0 20px'};
  }
  @media ${big} {
    max-width: 1400px;
  }
  @media ${huge} {
    max-width: 1600px;
  }

  &.mobile-noP {
    @media ${mobile} {
      padding: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  max-width: 700px;
  padding-top: 20px;
  padding-bottom: 40px;
`;

const EffectiveDateWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  padding-right: 40px;
  justify-content: flex-end;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  color: #9F87B2;
`;

const HeaderTextWrapper = styled.div`
  width: 100%;
  color: #F1E7FA;
  text-edge: cap;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: 0.05625rem;
  text-transform: capitalize;
  margin-top: 40px;
  margin-bottom: 60px;
  @media ${mobile} {
    font-size: 35px;
  }
`

const SubheaderTextWrapper = styled.div`
  color: #F1E7FA;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 25px;
`;

const TextWrapper = styled.div`
  color: #F1E7FA;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 25px;
  white-space: pre-wrap;
  ul {
    margin-block-start: 0px;
  };
  ul li {
    display: flex;
    margin: 0;
    padding: 4px 0;

    &::before {
      content: "•";
      padding-right: 10px;
      margin-left: -2em;
    };
`;