import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { HeroBlog, Posts, Community } from '../components';
import styled from 'styled-components';
import { base } from '../styles/colors';
import { useLocation } from 'react-router-dom';

export const Blog = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>
          BlockGames: Joyful gameplay with personalised rewards
        </title>
      </Helmet>

      <PageWrapper>
        <HeroBlog />
        <Posts />
        <Community />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  background: ${base};
  width: 100%;
  height: 100%;
`;
