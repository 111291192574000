import { useState } from "react";
import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { Button } from "../buttons/Button";
import { mobile } from "../../styles/breakpoints";
import copyIconWhite from "../../assets/icons/copy_white.svg";
import copyIconBlack from "../../assets/icons/copy_black.svg";
import {
  matePurple,
  lightGrey,
  shadowPurple,
  green,
  primary,
  textBlack,
  white,
} from "../../styles/colors";

export const CopyableReadOnlyInput = ({
  onClick,
  label,
  disabled,
  className,
  height,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    onClick();
    // Change styling once copied. Reset state after 3 seconds.
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Wrapper
      disabled={disabled}
      onClick={onClick}
      className={className}
      height={height}
    >
      <Text>{label}</Text>
      <StyledButton
        icon={copied ? copyIconBlack : copyIconWhite} // TOOD: Couldn't change color via css.
        label={copied ? "Copied" : "Copy"}
        onClick={() => handleCopy()}
        copied={copied}
      />
    </Wrapper>
  );
};

CopyableReadOnlyInput.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  height: PropTypes.string,
};

CopyableReadOnlyInput.defaultProps = {
  disabled: false,
  height: "3.5rem",
};

const StyledButton = styled(Button)`
  font-family: Inter, sans-serif;
  color: ${(props) => (props.copied ? textBlack : white)};
  background: ${(props) => (props.copied ? green : shadowPurple)};
  border-radius: 1rem;
  justify-self: flex-end;
  &:hover {
    background-color: ${(props) => (props.copied ? green : primary)};
    color: ${(props) => (props.copied ? textBlack : white)};
  }
`;

const Text = styled.div({
  fontFamily: "Inter Medium",
  fontSize: "20px",
  fontWeight: "normal",
  letterSpacing: "2px",
  display: "flex",
  justifySelf: "flext-start",
  alignItems: "center",
  color: matePurple,
  paddingLeft: "1rem",
  [`@media ${mobile}`]: {
    fontSize: "14px",
    paddingLeft: "0rem",
  },
});

const Wrapper = styled.div`
  color: ${matePurple};
  background: ${lightGrey};
  border: none;
  font-size: 1rem;
  padding: 0.3rem 0.3rem 0.3rem 1rem;
  border-radius: 1rem;
  height: ${(props) => props.height};
  min-width: 400px;
  text-align: center;
  width: max-content;
  display: flex;
  justify-content: space-between;
  @media ${mobile} {
    min-width: 270px;
    width: 100%;
  }
`;
