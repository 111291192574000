import React from 'react';

import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { icons } from '../../utils/icons';
import { H1, Paragraph, Container } from '..';
import { motion } from 'framer-motion';
import { base } from '../../styles/colors';
import { useMediaQuery } from 'react-responsive';

export const HeroTeam = () => {
  const isMobile = useMediaQuery({ query: mobile });

  return (
    <>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <BackgroundImage
              as={motion.img}
              initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
              animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
              transition={{ ease: 'easeOut', duration: 0.2, delay: 0.2 }}
              src={icons.teamBg}
              alt="Team"
            />

            <H1
              as={motion.h1}
              initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
              animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
              transition={{ ease: 'easeOut', duration: 0.2, delay: 0.3 }}
              maxWidth={isMobile ? '100%' : '90%'}
            >
              Meet <strong>the team</strong>
            </H1>
            <Paragraph
              as={motion.p}
              initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
              animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
              transition={{ ease: 'easeOut', duration: 0.2, delay: 0.4 }}
              margin="1.5rem 0 0"
              maxWidth={isMobile ? '100%' : '59%'}
              textAlign="center"
            >
              We’re an open community of passionate gamers, designers,
              developers, builders, blockchain experts, and partners dedicated
              to making gaming better for everybody through our unique,
              decentralized games Player Network. 
            </Paragraph>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background: ${base};
  padding: 12.5rem 0 8.5rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${mobile} {
    padding: 8.5rem 0rem 2.5rem;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  @media ${mobile} {
    width: 100%;
  }
`;

const BackgroundImage = styled.img`
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 5rem;
  left: 0;
`;
