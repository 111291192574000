import React from "react";
import styled from "styled-components";
import { mobile } from "../../styles/breakpoints";
import { icons } from "../../utils/icons";
import { H2, Paragraph, Container } from "..";

import { baseSecondary, white, textBlack, green } from "../../styles/colors";
import { useMediaQuery } from "react-responsive";

export const Powered = () => {
  const isMobile = useMediaQuery({ query: mobile });

  return (
    <>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <ImageWrapper>
              <Image src={isMobile ? icons.togetherMobile : icons.powered} />
            </ImageWrapper>
            <H2 maxWidth="100%" margin="0 auto">
              Powered by <strong>$BLOCK</strong>
            </H2>
            <Paragraph
              maxWidth={isMobile ? "100%" : "70%"}
              margin="1.5rem auto 3.5rem"
            >
              $BLOCK represents the total value of the BlockGames Player
              Network, and brings all participants together in an ongoing,
              cyclical contributions-based value exchange.
            </Paragraph>
            {/*<ButtonsWrapper>
              {/* <PrimaryButton
                url="/get-early-access"
                label="Read the litepaper"
              /> 
              <a
                href="https://block-games.gitbook.io/blockgames-whitepaper/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read the litepaper
              </a>
  </ButtonsWrapper> */}
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  @media ${mobile} {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

const Image = styled.img`
  height: 12rem;
  @media ${mobile} {
    height: 9.5rem;
  }
`;

const Wrapper = styled.div`
  background: ${baseSecondary};
  padding: 6.5rem 0;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${mobile} {
    padding: 5rem 0rem;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  a {
    border: 0px solid ${white};
    background: ${white};
    color: ${textBlack};
    font-weight: 700;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    transition: 0.2s all;
    cursor: pointer;
    display: flex;
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    line-height: 150%;
    padding: 0.5rem 1.5rem;
    height: 3.5rem;
    min-width: ${(props) => (props.minWidth ? props.minWidth : "auto")};
    &:hover {
      transform: scale(1.1);
      background: ${green};
    }
  }
`;
