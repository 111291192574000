import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobile, big } from '../../styles/breakpoints';
import { icons } from '../../utils/icons';
import {
  H1,
  Paragraph,
  Container,
  LinkButton,
  Decoration,
} from '../../components';
import { motion } from 'framer-motion';
import { base } from '../../styles/colors';
import ModalVideo from 'react-modal-video';
import { useMediaQuery } from 'react-responsive';
import diceBanerLogo from '../../assets/images/diceBanerLogo.png';

export const Hero = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <ModalVideo
        channel="youtube"
        youtube={{ autoplay: 1, mute: 0 }}
        isOpen={isOpen}
        videoId="7H4TeUB6upo"
        onClose={() => setOpen(false)}
      />

      <Wrapper>
        <Container className="mobile-noP">
          <InnerWrapper>
            <TextWrapper>
              <H1
                as={motion.h1}
                initial={{ opacity: 0, y: 40, x: 0 }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                transition={
                  isMobile
                    ? { duration: 0.3, delay: 0.2 }
                    : { duration: 0.3, delay: 0.2 }
                }
                maxWidth={isMobile ? '100%' : '100%'}
              >
                Joyful gameplay with <strong>personalised rewards</strong>
              </H1>
              <Paragraph
                as={motion.p}
                initial={{ opacity: 0, y: 40, x: 0 }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                transition={
                  isMobile
                    ? { duration: 0.3, delay: 0.3 }
                    : { duration: 0.3, delay: 0.3 }
                }
                margin={isMobile ? '1rem 0 0' : '1.5rem 0 0'}
                maxWidth={isMobile ? '100%' : '90%'}
              >
                A cross-chain, cross-game, decentralized Player Network that
                unlocks player growth for games.
              </Paragraph>
              <ButtonsWrapper
                as={motion.div}
                initial={{ opacity: 0, y: 40, x: 0 }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                transition={
                  isMobile
                    ? { duration: 0.3, delay: 0.4 }
                    : { duration: 0.3, delay: 0.4 }
                }
              >
                <LinkButton
                  url="/get-early-access"
                  label="Get early access now"
                ></LinkButton>
              </ButtonsWrapper>
            </TextWrapper>
            {isMobile ? (
              <>
                <MobileImage src={icons.heroDecoMobile} />
              </>
            ) : null}
            <VideoWrapper
              as={motion.div}
              initial={{ opacity: 0, y: 20, x: 0 }}
              animate={{ opacity: 1, y: 0, x: 0 }}
              transition={
                isMobile
                  ? { duration: 0.3, delay: 1 }
                  : { duration: 0.3, delay: 0.5 }
              }
            >
              {isMobile ? null : <VideoImage
                src={diceBanerLogo}
                alt="Video"
              />}              
            </VideoWrapper>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

Hero.propTypes = {
  label: PropTypes.string,
  imageSource: PropTypes.string,
  price: PropTypes.string,
};

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-top: 72px;
  @media ${mobile} {
    flex-direction: column;
    padding-top: 56px;
  }
`;

const MobileImage = styled.img`
  height: initial;
  object-fit: initial;
  width: 100%;
  border-radius: 0;
  margin: 3rem 0 0;
`;

const VideoImage = styled.img`
  width: 100%;

  object-fit: cover;
  position: relative;
  z-index: 1;
  border-radius: 2.5rem;
  overflow: hidden;
  @media ${mobile} {
    height: initial;
    object-fit: initial;
    border-radius: 0;
    margin: 3rem 0 0;
    border-radius: 2rem;
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  /* min-height: 100vh; */
  padding: 7rem 0 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${base};
  @media ${mobile} {
    height: initial;
    padding: 3.5rem 0 2.5rem;
    min-height: initial;
    text-align: center;
    justify-content: center;
  }
  @media ${big} {
    /* padding: em 0 0; */
    min-height: initial;
  }
`;

const TextWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${mobile} {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    border-right: 0px;
  }
  @media ${big} {
  }
`;

const VideoWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  @media ${mobile} {
    width: 100%;
    padding: 0 20px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 3rem 0 0;
  @media ${mobile} {
    width: 100%;
    margin: 2rem auto 0;
    a {
      width: 100%;
    }
  }
`;
