import axios from "axios";

const baseUrl = "https://api.blockgames.app/cf";

export const getNftsByWalletAddress = async (walletAddress) => {
  const { data } = await axios.post(`${baseUrl}/getdicenfts`, {
    walletAddress: walletAddress,
  });
  return data?.NFTs;
};

export const rollDice = async (
  walletAddress,
  tokenID,
  contractAddress,
  signedMessage
) => {
  const { data } = await axios.post(`${baseUrl}/rolldice`, {
    walletAddress: walletAddress,
    tokenID: tokenID,
    contractAddress: contractAddress,
    txSignature: signedMessage,
  });
  return data;
};

export const rollWhitelistedDice = async (
  walletAddress,
  signedMessage
) => {
  const { data } = await axios.post(`${baseUrl}/rolldice`, {
    walletAddress: walletAddress,
    txSignature: signedMessage,
  });
  return data;
};

export const getIsWhitelisted = async (walletAddress) => {
  const { data } = await axios.post(`${baseUrl}/iswhitelistedforrolling`, {
    walletAddress: walletAddress,
  });
  return data?.isWhitelisted;
};
