import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { H2, H6, Paragraph, Container } from '..';
import { base } from '../../styles/colors';
import gcvpart1 from '../../assets/lottie/GCV_part_1.json';
import gcvpart2 from '../../assets/lottie/GCV_part_2.json';
import gcvpart3 from '../../assets/lottie/GCV_part_3.json';
import gcvpart4 from '../../assets/lottie/GCV_part_4.json';
import Lottie from 'lottie-react';
import { useMediaQuery } from 'react-responsive';
import {
  Flex,
  Accordion,
  AccordionItem,
  Box,
  AccordionPanel,
  AccordionButton,
  Spinner,
} from '@chakra-ui/react';
import LazyLoad from 'react-lazy-load';

export const GcvMobile2 = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const defaultOptions = {
    loop: true,
    autoplay: true,

    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Flex direction="column" align="center" justify="center">
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
          animationData={gcvpart1}
        />
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
          animationData={gcvpart2}
        />{' '}
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
          animationData={gcvpart3}
        />
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
          animationData={gcvpart4}
        />
      </Flex>

      <Wrapper>
        <Container>
          {/* {loading ? (
            <InnerWrapper>
              <Spinner w="2rem" h="2rem" color="white" />
            </InnerWrapper>
          ) : ( */}
          <InnerWrapper>
            <H2
              fontSize={isMobile ? '2rem' : '3.5rem'}
              letterSpacing="0.07rem"
              margin="0 auto 2rem"
              maxWidth={isMobile ? '80%' : '982px'}
              textAlign="center"
            >
              Generating <strong>cyclical</strong> value
            </H2>
            {!isMobile && (
              <Paragraph
                maxWidth={isMobile ? '100%' : '55%'}
                margin="0 auto"
                textAlign="center"
              >
                BlockGames' unique contribution mechanisms, combined with
                instant referral and engagement rewards for players, help spark
                repeated chain reactions that generate viral growth for games.
              </Paragraph>
            )}

            <Accordion defaultIndex={[0]}>
              <AccordionItem pb="0.5rem">
                <CustomAccordionButton _expanded={{ opacity: 1 }} opacity="0.5">
                  <Box as="span" flex="1" textAlign="center">
                    <H6 fontSize="1.5rem" fontWeight="700">
                      Connect and scale
                    </H6>
                  </Box>
                </CustomAccordionButton>

                <AccordionPanel>
                  <Paragraph
                    fontSize="1.125rem"
                    fontWeight="400"
                    textAlign="center"
                    margin="0.5rem 0 0"
                  >
                    Games connected to the BlockGames network unlock player
                    growth and scale fast.
                  </Paragraph>

                  <LottieWrapper
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 2 }}
                  >
                    <Lottie animationData={gcvpart1} />
                  </LottieWrapper>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem pb="0.5rem">
                <CustomAccordionButton _expanded={{ opacity: 1 }} opacity="0.5">
                  <Box as="span" flex="1" textAlign="center">
                    <H6 fontSize="1.5rem" fontWeight="700">
                      Fees contribution
                    </H6>
                  </Box>
                </CustomAccordionButton>

                <AccordionPanel>
                  <Paragraph
                    fontSize="1.125rem"
                    fontWeight="400"
                    textAlign="center"
                    margin="0.5rem 0 0"
                  >
                    Games contribute $BLOCK fees to BlockGames in exchange for
                    unlocked player growth.
                  </Paragraph>

                  <LottieWrapper
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 2 }}
                  >
                    <Lottie animationData={gcvpart2} />
                  </LottieWrapper>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem pb="0.5rem">
                <CustomAccordionButton _expanded={{ opacity: 1 }} opacity="0.5">
                  <Box as="span" flex="1" textAlign="center">
                    <H6 fontSize="1.5rem" fontWeight="700">
                      Viral rewards
                    </H6>
                  </Box>
                </CustomAccordionButton>

                <AccordionPanel>
                  <Paragraph
                    fontSize="1.125rem"
                    fontWeight="400"
                    textAlign="center"
                    margin="0.5rem 0 0"
                  >
                    Players win instant rewards for engaging in their favorite
                    games, referring friends, plus when those friends play and
                    engage.
                  </Paragraph>

                  <LottieWrapper
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 2 }}
                  >
                    <Lottie animationData={gcvpart3} />
                  </LottieWrapper>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem pb="0.5rem">
                <CustomAccordionButton _expanded={{ opacity: 1 }} opacity="0.5">
                  <Box as="span" flex="1" textAlign="center">
                    <H6 fontSize="1.5rem" fontWeight="700">
                      Player contribution
                    </H6>
                  </Box>
                </CustomAccordionButton>

                <AccordionPanel>
                  <Paragraph
                    fontSize="1.125rem"
                    fontWeight="400"
                    textAlign="center"
                    margin="0.5rem 0 0"
                  >
                    Games receive instant $BLOCK rewards for bringing new
                    players into the Player Network.
                  </Paragraph>

                  <LottieWrapper
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 2 }}
                  >
                    <Lottie animationData={gcvpart4} />
                  </LottieWrapper>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </InnerWrapper>
          {/* )} */}
        </Container>
      </Wrapper>
    </>
  );
};

const LottieWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  height: 800px;
  @media ${mobile} {
    width: 100%;
    height: 400px;
  }
`;

const CustomAccordionButton = styled(AccordionButton)`
  background: none;
  border: none;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 9rem 0 0rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${base};
  @media ${mobile} {
    height: initial;
    padding: 2.5em 0 0;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
