import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, H1, H4, H5, H6, Paragraph, Community } from "../components";
import dayjs from "dayjs";
import styled from "styled-components";
import {
  base,
  baseSecondary,
  brightPink,
  white,
  green,
  textDark,
  yellow,
  border,
} from "../styles/colors";
import { useParams } from "react-router-dom";
import { mobile, big } from "../styles/breakpoints";
import { images } from "../utils/images";
import { icons } from "../utils/icons";
import useContentful from "../useContentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"; // Add this line
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { useMediaQuery } from "react-responsive";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

// Create a bespoke renderOptions object to target BLOCKS.EMBEDDED_ENTRY (linked block entries e.g. code blocks)
// INLINES.EMBEDDED_ENTRY (linked inline entries e.g. a reference to another blog post)
// and BLOCKS.EMBEDDED_ASSET (linked assets e.g. images)

const options = {
  renderNode: {
    // If the node is a link
    [INLINES.HYPERLINK]: (node) => {
      // Only process youtube links
      if (node.data.uri.includes("youtube.com")) {
        // Extract videoId from the URL
        const match =
          /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(
            node.data.uri
          );
        const videoId = match && match[7].length === 11 ? match[7] : null;
        return (
          videoId && (
            <Box
              className="video-container"
              borderRadius="1rem"
              overflow="hidden"
            >
              <iframe
                className="video"
                title={`https://youtube.com/embed/${videoId}`}
                src={`https://youtube.com/embed/${videoId}`}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
              />
            </Box>
          )
        );
      } else {
        const url = node?.data?.uri;
        const title = node?.content[0]?.value;
        if (url && title) {
          return (
            <a href={url} target="_blank" rel="noreferrer">
              {title}
            </a>
          );
        }
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      // target the contentType of the EMBEDDED_ENTRY to display as you need
      if (node.data.target.sys.contentType.sys.id === "post") {
        return (
          <a
            href={`/news/${node.data.target.fields.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            {node.data.target.fields.title}
          </a>
        );
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      // target the contentType of the EMBEDDED_ENTRY to display as you need
      if (node.data.target.sys.contentType.sys.id === "post") {
        return (
          <Link to={`/news/${node.data.target.fields.slug}`}>
            <FlexOne>
              <Image src={node.data.target.fields.image.fields.file.url} />
              <Stackas>
                <H5>{node.data.target.fields.title}</H5>

                {/* <Flex>
                  <Attribute>
                    <Icon2 src={icons.clock} />
                    <Paragraph
                      fontSize="0.875rem"
                      fontWeight="600"
                      lineHeight="160%"
                    >
                      {node.data.target.fields.minutesToRead} min
                    </Paragraph>
                  </Attribute>
                  <Attribute>
                    <Icon2 src={icons.calendar} />
                    <Paragraph
                      fontSize="0.875rem"
                      fontWeight="600"
                      lineHeight="160%"
                    >
                      {dayjs(node.data.target.fields.date).format("D MMM")}
                    </Paragraph>
                  </Attribute>
                </Flex> */}
              </Stackas>
            </FlexOne>
          </Link>
        );
      }

      if (node.data.target.sys.contentType.sys.id === "videoEmbed") {
        return (
          <Box
            className="video-container"
            borderRadius="1rem"
            overflow="hidden"
          >
            <iframe
              className="video"
              src={node.data.target.fields.embedUrl}
              height="100%"
              width="100%"
              frameBorder="0"
              scrolling="no"
              title={node.data.target.fields.title}
              allowFullScreen={true}
            />
          </Box>
        );
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      // render the EMBEDDED_ASSET as you need
      return (
        <img
          src={`https://${node.data.target.fields.file.url}`}
          height={node.data.target.fields.file.details.image.height}
          width={node.data.target.fields.file.details.image.width}
          alt={node.data.target.fields.description}
        />
      );
    },
  },
};

export const Article = () => {
  const { slug } = useParams();
  const isMobile = useMediaQuery({ query: mobile });

  const { getSinglePost } = useContentful();

  const [post, setPost] = useState({});

  useEffect(() => {
    // Hardcoded slug fix.
    // Some clients wrote posts which they cannot change and those posts include an exclamation mark the the end.
    // The exclamation mark gets trimmed on some browsers.
    let adjustedSlug = slug;
    if (adjustedSlug === "BlockGames Early Access has arrived") {
      adjustedSlug = "BlockGames Early Access has arrived!";
    }
    const fetchPost = async () => {
      const newPost = await getSinglePost(adjustedSlug);
      if (newPost.slug === adjustedSlug) {
        setPost(newPost);
      }
    };
    fetchPost();
    // Exclude getSinglePost
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    // Scroll to top after a 3-second delay
    const scrollTimeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500); // 3 seconds in milliseconds

    // Clear the timeout if the component unmounts or reloads
    return () => clearTimeout(scrollTimeout);
  }, []); // Empty dependency array, so it only runs once

  console.log("post", post);

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
      </Helmet>

      <PageWrapper>
        <Wrapper>
          <Container className="mobile-noP">
            <InnerWrapper>
              <InfoTopRight>
                <Attribute>
                  <Icon src={icons.clock} />
                  <Paragraph
                    fontSize="0.875rem"
                    fontWeight="600"
                    lineHeight="160%"
                  >
                    {post.minutesToRead} min
                  </Paragraph>
                </Attribute>
                <Attribute>
                  <Icon src={icons.calendar} />
                  <Paragraph
                    fontSize="0.875rem"
                    fontWeight="600"
                    lineHeight="160%"
                  >
                    {dayjs(post.date).format("DD MMM")}
                  </Paragraph>
                </Attribute>
              </InfoTopRight>

              <ContentWrapper>
                <Title>
                  <H1 fontSize={isMobile ? "1.75rem" : "2.875rem"}>
                    {post.title}
                  </H1>
                </Title>
                <ContentContainer>
                  <ImageWrapper>
                    {post.image && (
                      <Image src={post.image.file.url} alt="hero" />
                    )}
                    {/* <Paragraph
                      fontSize={isMobile ? "12px !important" : "1rem"}
                      margin="10px 0 0"
                      textAlign="center"
                    >
                      Will Web3 soon be ripe for mass adoption?
                    </Paragraph> */}
                  </ImageWrapper>
                  {post.content &&
                    documentToReactComponents(post.content, options)}
                  <Tags>
                    {post.categories &&
                      post.categories.map((category) => (
                        <Tag className="green">{category.fields.name}</Tag>
                      ))}
                  </Tags>
                  {post.author && (
                    <Author>
                      {post.author.fields.avatar &&
                      post.author.fields.avatar ? (
                        <>
                          <Paragraph>by</Paragraph>
                          <AuthorImage
                            src={post.author.fields.avatar.fields.file.url}
                            alt={post.author.fields.avatar.fields.file.title}
                          />
                        </>
                      ) : (
                        <Paragraph>
                          <strong>Author:</strong> {post.author.fields.name}
                        </Paragraph>
                      )}
                    </Author>
                  )}
                </ContentContainer>
              </ContentWrapper>
            </InnerWrapper>
          </Container>
        </Wrapper>
        <Community />
      </PageWrapper>
    </>
  );
};

const Stackas = styled.div`
  padding-left: 2rem;

  @media ${mobile} {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1.5rem;
  }
`;

const FlexOne = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;

  @media ${mobile} {
    flex-direction: column;
  }
`;

const AuthorImage = styled.img`
  height: 80px !important; // The ContentContainer img overrides this for some weird reason, hence the !important.
  border: none;
  width: initial !important;
  margin-left: 12px;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  margin: 3.75rem 0;

  @media ${mobile} {
    margin: 3.75rem 0;
  }
`;

const Tag = styled.div`
  display: flex;
  padding: 0.5rem 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${green};
  color: ${textDark};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  white-space: nowrap;
  margin-right: 0.5rem;

  &.green {
    background-color: ${green};
  }
  &.yellow {
    background-color: ${yellow};
  }

  @media ${mobile} {
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
  }
`;
const InfoTopRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 1.5rem;
  position: absolute;
  top: 30px;
  right: 34px;
`;

const Attribute = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 0 1.5rem;
  opacity: 0.5;
`;

const Icon = styled.img`
  height: 14px;
  margin-right: 8px;
`;

const Icon2 = styled.img`
  height: 8px;
  margin-right: 8px;
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 3.75rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${mobile} {
    margin: 1.875rem 0;
  }
`;

const Image = styled.img`
  width: 100%;
  border-radius: 2.5rem;
  overflow: hidden;
  border: 1px solid ${brightPink};
  @media ${mobile} {
    border-radius: 1.25rem;
  }
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  @media ${mobile} {
    margin: 4rem 0 0;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 688px;
  margin: 0 auto;
  color: ${white};
  font-size: 1.125rem;
  font-family: "Inter", sans-serif;
  padding: 0 0 8rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    line-height: 1.2;
  }
  p {
    font-weight: 400;
    font-size: 1.125rem;
    opacity: 0.8;
    line-height: 1.5;
  }

  h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 34px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 26px;
  }
  h5 {
    font-size: 22px;
  }
  h6 {
    font-size: 18px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    padding-inline-start: 40px;
  }
  li {
    background-color: initial;
    border-radius: 0px;
    padding: initial;
    margin-bottom: initial;
    overflow: initial;
    cursor: initial;
  }
  ul li {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px 0;
    flex: 1;

    &::before {
      content: "•";
      color: ${brightPink};
      font-weight: 700;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      width: 100%;
      margin: 0;
    }
    a {
      margin: 0;
    }
  }

  ol li {
    padding: 5px 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      margin: 0;
    }
  }

  a {
    color: ${brightPink};
    text-decoration: none;
    font-weight: 700;
  }

  a:hover {
    text-decoration: underline;
  }

  blockquote {
    border-left: 4px solid ${brightPink};
    padding-left: 1rem;
    margin-left: 0;
    font-style: italic;
    display: flex;
  }

  hr {
    border: 1px solid ${brightPink};
    margin: 4rem 0;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    tr,
    th,
    td {
      padding: 0 1rem;
      text-align: left;
    }
  }

  table,
  th,
  td {
    border: 1px solid #fff;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 2.5rem;
    overflow: hidden;
    border: 1px solid ${brightPink};
  }

  @media ${mobile} {
    font-size: 1rem;
    text-align: initial;

    padding: 0 24px 4rem;

    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 16px;
    }
    h6 {
      font-size: 14px;
    }
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`;

const PageWrapper = styled.div`
  background: ${base};
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  /* min-height: 100vh; */
  padding: 10.75rem 0 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${base};
  @media ${mobile} {
    height: initial;
    padding: 7.25rem 0 2.5rem;
    min-height: initial;
    text-align: center;
    justify-content: center;
  }
  @media ${big} {
    /* padding: em 0 0; */
    min-height: initial;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-top: 72px;
  background: ${baseSecondary};
  border-radius: 1.875rem;
  position: relative;
  width: 100%;

  @media ${mobile} {
    flex-direction: column;
    padding-top: 56px;
    gap: 0;
  }
`;
