import styled from 'styled-components';
import { black, white, brightPink } from '../../styles/colors';
import { mobile, big } from '../../styles/breakpoints';

export const Paragraph = styled.p`
  padding: ${(props) => props.padding || '0px'};
  font-family: 'Inter', sans-serif;
  margin: ${(props) => props.margin || '0 0 0'};
  max-width: ${(props) => props.maxWidth || '100%'};
  font-weight: ${(props) => props.fontWeight || 500};
  font-size: ${(props) => props.fontSize || '1.125rem'};
  line-height: ${(props) => props.lineHeight || '1.6'};
  display: flex;
  color: ${(props) => props.color || white};
  opacity: ${(props) => props.opacity || '1'};
  letter-spacing: 0.005em;
  white-space: pre-line;
  text-align: ${(props) => props.textAlign || 'none'};
  letter-spacing: ${(props) => props.letterSpacing || '0.011rem'};

  @media ${mobile} {
    font-size: ${(props) => props.fontSize || '16px'};
  }
  @media ${big} {
    font-size: ${(props) => props.fontSize || '18px'};
  }

  strong {
    color: ${brightPink};
  }
`;

export const ParagraphPlain = styled.p`
  padding: ${(props) => props.padding || '0px'};
  font-family: 'Inter', sans-serif;
  margin: ${(props) => props.margin || '0'};
  max-width: ${(props) => props.maxWidth || '100%'};
  font-weight: 400;
  font-size: ${(props) => props.fontSize || '1.25rem'};
  line-height: 1.6;
  color: ${(props) => props.color || black};
  opacity: 0.6;
  white-space: pre-line;
`;
