import ugne_gumenikovaite from '../assets/images/team/ugne_gumenikovaite.png';
import nedas_sapalas from '../assets/images/team/nedas_sapalas.png';
import karolis_prekeris from '../assets/images/team/karolis_prekeris.png';
import chris_akhavan from '../assets/images/team/chris_akhavan.png';
import mazvydas_skuodas from '../assets/images/team/mazvydas_skuodas.png';
import mateusz_staniszew from '../assets/images/team/mateusz_staniszew.png';
import vytis_bareika from '../assets/images/team/vytis_bareika.png';
import benas_baltramiejunas from '../assets/images/team/benas_baltramiejunas.png';
import kipras_gajauskas from '../assets/images/team/kipras_gajauskas.png';
import laurynas_slikas from '../assets/images/team/laurynas_slikas.png';
import jonas_pleckaitis from '../assets/images/team/jonas_pleckaitis.png';
import matthew_pigott from '../assets/images/team/matthew_pigott.png';
import dziugas_meskauskas from '../assets/images/team/dziugas_meskauskas.png';
import danas_vogulis from '../assets/images/team/danas_vogulis.png';
import unknown1 from '../assets/images/team/unknown1.png';
import unknown2 from '../assets/images/team/unknown2.png';
import unknown3 from '../assets/images/team/unknown3.png';
import unknown4 from '../assets/images/team/unknown4.png';

export const membersImages = {
  ugne_gumenikovaite,
  nedas_sapalas,
  karolis_prekeris,
  chris_akhavan,
  mazvydas_skuodas,
  mateusz_staniszew,
  vytis_bareika,
  benas_baltramiejunas,
  kipras_gajauskas,
  laurynas_slikas,
  jonas_pleckaitis,
  matthew_pigott,
  dziugas_meskauskas,
  danas_vogulis,
  unknown1,
  unknown2,
  unknown3,
  unknown4,
};
