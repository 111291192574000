import React from 'react';
import ReactGA from 'react-ga4';
import { Header, Footer } from './components';
import {
  Home,
  PlayerNetwork,
  Team,
  About,
  Blog,
  Mint,
  Roll,
  Article,
  Test,
  TermsOfUse,
  PrivacyPolicy
} from './routes';
import './index.scss';
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Switch,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

ReactGA.initialize([
  {
    trackingId: 'GTM-TR49DRD',
  },
]);

ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

const AnimatedRoutes = ({ children }) => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter={true}>
      <Switch location={location} key={location.pathname}>
        {children}
      </Switch>
    </AnimatePresence>
  );
};

const FooterSwitch = ({ children }) => {
  const location = useLocation();
  const isMintPage = location.pathname === '/get-early-access';
  const isRollPage = location.pathname === '/hiden-fdshjkfhsdjkhf';

  return (
    <Switch>
      {children}
      {!isMintPage && !isRollPage && <Route component={Footer} />}
    </Switch>
  );
};

export const App = () => {
  return (
    <Router>
      <>
        <Route component={Header} />

        <AnimatedRoutes>
          <Route exact path="/" component={Home} />
          <Route exact path="/test" component={Test} />
          <Route exact path="/player-network" component={PlayerNetwork} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/about" component={About} />
          <Route exact path="/news" component={Blog} />
          <Route exact path="/get-early-access" component={Roll} />
          <Route exact path="/terms-of-use" component={TermsOfUse} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />

          <Route exact path="/news/:slug" component={Article} />
        </AnimatedRoutes>

        <FooterSwitch>
          {/* Footer will be rendered here based on the route */}
        </FooterSwitch>
      </>
    </Router>
  );
};
