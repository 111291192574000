import styled from 'styled-components';
import React from 'react';

export const Decoration = ({ icon, top, bottom, left, right, iconHeight }) => {
  return (
    <Wrapper top={top} bottom={bottom} left={left} right={right}>
      {icon ? (
        <>
          <Icon src={icon} iconHeight={iconHeight}/>
        </>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: ${(props) => props.top || 'auto'};
  left: ${(props) => props.left || 'auto'};
  bottom: ${(props) => props.bottom || 'auto'};
  right: ${(props) => props.right || 'auto'};
  z-index: 9;
`;

const Icon = styled.img`
  height: ${(props) => props.iconHeight || 'initial'};
`;
