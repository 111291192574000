import React, { useEffect } from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { Paragraph, Container, Decoration } from '../../components';
import { icons } from '../../utils/icons';
import { images } from '../../utils/images';
import {
  base,
  white,
  textDark,
  secondary,
  yellow,
  cyan,
  green,
} from '../../styles/colors'; // Step 2: Import yellow, cyan, and green colors
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import test from '../../assets/gif/g_nft.gif';
import { useMediaQuery } from 'react-responsive';

const blocks = [
  {
    id: 1,
    number: '100+',
    text: 'Launched games and growing',
    bgColor: 'green',
  },
  {
    id: 2,
    number: '300k',
    text: 'Active web3 community across connected games',
    bgColor: 'yellow',
  },
  {
    id: 3,
    number: '50m',
    text: 'Monthly active users across our founding studio games',
    bgColor: 'cyan',
  },
];

export const Blocks = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const control = useAnimation();
  const [inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  return (
    <>
      <Wrapper
        as={motion.div}
        initial={{ opacity: 0, y: 40, x: 0 }}
        animate={{ opacity: 1, y: 0, x: 0 }}
        transition={{ duration: 0.3, delay: 0.6 }}
      >
        <Container>
          <InnerWrapper>
            {isMobile ? null : (
              <>
                <DecoWrapper
                  as={motion.div}
                  animate={{ translateY: [0, 10, 0] }}
                  transition={{
                    ease: 'linear',
                    duration: 2,
                    repeat: Infinity,
                  }}
                >
                  <Decoration iconHeight="7.25rem" icon={images.decoBlocks1} />
                </DecoWrapper>

                <Decoration
                  top="-6rem"
                  left="42%"
                  iconHeight="3rem"
                  icon={icons.decoBlocks2}
                />
              </>
            )}

            {isMobile ? (
              <>
                <MobileBlock className="green">
                  <Paragraph
                    fontSize="3.5rem"
                    fontWeight="800"
                    margin="0 0 0"
                    color="inherit"
                  >
                    100+
                  </Paragraph>
                  <TextBlock>
                    <Paragraph fontSize="1rem" margin="0 0 0" color="inherit">
                      Launched games and growing
                      <br />
                      <br />
                    </Paragraph>
                  </TextBlock>
                </MobileBlock>
                <MobileBlock className="yellow">
                  <Paragraph
                    fontSize="3.5rem"
                    fontWeight="800"
                    margin="0 0 0"
                    color="inherit"
                  >
                    300k
                  </Paragraph>
                  <TextBlock>
                    <Paragraph fontSize="1rem" margin="0 0 0" color="inherit">
                      Active web3 community across connected games
                      <br />
                      <br />
                    </Paragraph>
                  </TextBlock>
                </MobileBlock>
                <MobileBlock className="cyan">
                  <Paragraph
                    fontSize="3.5rem"
                    fontWeight="800"
                    margin="0 0 0"
                    color="inherit"
                  >
                    50M
                  </Paragraph>
                  <TextBlock>
                    <Paragraph fontSize="1rem" margin="0 0 0" color="inherit">
                      Monthly active users across our founding studio games
                      <br />
                      <br />
                    </Paragraph>
                  </TextBlock>
                </MobileBlock>
              </>
            ) : (
              <>
                <Gif src={test} alt="test" />
                {blocks.map((block) => (
                  <Block className={block.bgColor}>
                    <Paragraph
                      fontSize="4.5rem"
                      fontWeight="800"
                      margin="0 0 0"
                      color="inherit"
                    >
                      {block.number}
                    </Paragraph>
                    <TextBlock>
                      <Paragraph
                        fontSize="1.125rem"
                        margin="0 0 0"
                        color="inherit"
                      >
                        {block.text}
                        <br />
                        <br />
                      </Paragraph>
                    </TextBlock>
                  </Block>
                ))}
              </>
            )}
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const DecoWrapper = styled.div`
  position: absolute;
  top: -6.5rem;
  left: 15%;
`;

const Gif = styled.img`
  height: 80px;
  width: 80px;
  top: -3.5rem;
  right: 10%;
  position: absolute;
  @media ${mobile} {
    display: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;

  @media ${mobile} {
    flex-direction: column;
  }
`;
const TextBlock = styled.div`
  height: 100%;
  height: 60px;
`;

const Block = styled.div`
  width: calc(33.3333% - 8px);
  height: 216px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${secondary};
  border-radius: 2rem;
  color: ${white};

  text-align: center;
  flex-shrink: 0;
  transition: 0.2s all;

  &:hover {
    color: ${white};
    background: ${secondary};

    &.green {
      background: ${green};
      color: ${textDark};
    }
    &.yellow {
      background: ${yellow};
      color: ${textDark};
    }
    &.cyan {
      background: ${cyan};
      color: ${textDark};
    }
  }

  @media ${mobile} {
    width: 100%;
    margin: 0 0 1rem;
    &.cyan {
      background-color: ${cyan};
    }
  }
`;

const MobileBlock = styled.div`
  width: 100%;
  padding: 1.5rem 3rem;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${secondary};
  border-radius: 2rem;
  color: ${textDark};
  text-align: center;
  flex-shrink: 0;
  margin: 0 0 1rem;
  @media ${mobile} {
    display: flex;
  }

  &.green {
    background-color: ${green};
  }
  &.yellow {
    background-color: ${yellow};
  }
  &.cyan {
    background-color: ${cyan};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${base};
  padding: 6.5rem 0 3.5rem;
  @media ${mobile} {
    height: initial;

    padding: 5rem 0 5rem;
  }
`;
