import styled from 'styled-components';
import { mobile, big, huge } from '../../styles/breakpoints';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: ${(props) => props.padding || '0'};
  overflow: ${(props) => props.overflow || 'initial'};
  position: ${(props) => props.position || 'initial'};
  @media ${mobile} {
    max-width: 100%;
    padding: ${(props) => props.padding || '0 20px'};
  }
  @media ${big} {
    max-width: 1400px;
  }
  @media ${huge} {
    max-width: 1600px;
  }

  &.mobile-noP {
    @media ${mobile} {
      padding: 0;
    }
  }
`;
