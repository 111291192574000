export const base = '#2D0949';
export const baseSecondary = '#401065';
export const brightPink = '#E65EFA';
export const textBlack = '#161616';
export const textDark = '#161616';
export const textGray = '#DBC9E8';
export const secondary = '#521383';
export const secondaryAlt = '#7424B1';
export const yellow = '#FFD500';
export const green = '#AAEE51';
export const cyan = '#5ADEE7';
export const purple = '#400D67';
export const opaquePurple = '#F1E7FA';
export const matePurple = '#9473B5'
export const darkPurple = '#601799';
export const shadowPurple = "#3F0D66"
export const accentPurple = "#8B34CF"
export const red = '#E25252';

export const black = '#201E1F';
export const primary = '#7625B4';
export const lightGreen = '#E1FFF0';
export const lightBlue = '#DBE1FF';
export const orange = '#FFB43F';
export const grey = '#ebebeb';
export const lightGrey = '#E7F1FA';
export const white = '#FFFFFF';
export const border = '#EAEAEA';
export const darkGrey = '#d4d4d4';
