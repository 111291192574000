import { useEffect, useState } from 'react';
import styled from 'styled-components';

const SubscribeFormContainer = styled.div`
  .email-form {
    position: relative;
    z-index: 10;
    margin: auto auto 0;

    @media (max-width: 600px) {
      padding: 0 17px;
    }

    .form-terms {
      color: #dbc9e8;
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 50px;
      text-align: center;

      @media (max-width: 600px) {
        font-size: 12px;
        color: #ffffff;
        margin-bottom: 15px;
        letter-spacing: 0.01em;
      }
    }

    .success-text {
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 27px;
        margin-left: 10px;

        @media (max-width: 600px) {
          height: 21px;
        }
      }

      @media (max-width: 600px) {
        font-size: 16px;
        color: #ffffff;
        margin-bottom: 15px;
      }
    }
  }
`;

const FormContainer = styled.form`
  background: #fff;
  box-shadow: 3px 3px 0px #400d67;
  border-radius: 18px;
  width: 518px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  margin: 0 auto;
  margin-bottom: 10px;
  border: 1px solid transparent;

  &.error {
    border: 1px solid red;
    @media (max-width: 600px) {
      border: 1px solid transparent;
      input {
        border: 1px solid red;
      }
    }
  }

  @media (max-width: 600px) {
    background: none;
    box-shadow: none;
    border-radius: 0;
    width: auto;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 auto;
    margin-bottom: 10px;
    flex-direction: column;
  }

  input {
    background: transparent;
    border: none;
    outline: none;
    color: #521380;
    font-weight: 500;
    font-size: 18px;
    padding: 15px 25px;
    flex: 1;

    ::placeholder {
      color: #9473b5;
      font-size: 16px;
    }

    @media screen and (max-width: 600px) {
      background: #ffffff;
      box-shadow: 3px 3px 0px #400d67;
      border-radius: 14px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px 24px;
      margin: 0 auto;
      margin-bottom: 16px;
      border: 1px solid transparent;
    }
  }
`;

const Button = styled.button`
  background: #521380;
  border-radius: 14px;
  border: none;
  outline: none;
  height: 44px;
  padding: 0 30px;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 100%;
    border: 1px solid #9473b5;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    background: #e5e5e5;
    cursor: default;
  }
`;

export const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const submitStatus = urlParams.get('status');

    if (submitStatus === 'submitted') {
      window._show_thank_you();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const encodedEmail = encodeURIComponent(email);

    var head = document.querySelector('head'),
      script = document.createElement('script'),
      r = false;
    script.type = 'text/javascript';
    script.charset = 'utf-8';

    script.src = `https://block-games.activehosted.com/proc.php?u=15&f=15&s=&c=0&m=0&act=sub&v=2&or=715272d305b6350736f7e717c4829701&email=${encodedEmail}&jsonp=true`;
    // if (callback) {
    script.onload = script.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        // Callback
      }
    };
    // }
    script.onerror = function () {
      console.error('error');
    };

    head.appendChild(script);
  };
  return (
    <SubscribeFormContainer>
      <div className="email-form">
        <FormContainer className="input-container" onSubmit={handleSubmit}>
          <input
            onChange={(e) => {
              setEmail(e.target.value);
              document
                .querySelector('.input-container')
                .classList.remove('error');
            }}
            className="email-input"
            type="email"
            placeholder="Enter your email"
          />
          <Button className="submit">Register</Button>
        </FormContainer>
        <div className="form-terms">
          By subscribing you agree to receive emails from BlockGames.
        </div>
      </div>
    </SubscribeFormContainer>
  );
};
