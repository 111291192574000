import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import useScrollToTop from "../hooks/useScrollToTop";
import { mobile } from "../styles/breakpoints";
// Horizontal videos.
import congrats_background_hor from "../assets/video/mint_horizontal/congrats_background.webm";
import congrats_background_hor_mov from "../assets/video/mint_horizontal/congrats_background.mov";
import hands_background_hor from "../assets/video/mint_horizontal/hands_background.webm";
import hands_background_hor_mov from "../assets/video/mint_horizontal/hands_background.mov";
import hands_dices_hor from "../assets/video/mint_horizontal/hands_dices_loop.webm";
import hands_dices_hor_mov from "../assets/video/mint_horizontal/hands_dices_loop.mov";
import hands_start_hor from "../assets/video/mint_horizontal/hands_start.webm";
import hands_start_hor_mov from "../assets/video/mint_horizontal/hands_start.mov";
import hands_dices_grab_hor from "../assets/video/mint_horizontal/hands_dices_grab.webm";
import hands_dices_grab_hor_mov from "../assets/video/mint_horizontal/hands_dices_grab.mov";
import fireworks_hor from "../assets/video/mint_horizontal/congrats_fireworks.webm";
import fireworks_hor_mov from "../assets/video/mint_horizontal/congrats_fireworks.mov";
// Vertical videos.
import congrats_background_ver from "../assets/video/mint_vertical/v_congrats_background.webm";
import congrats_background_ver_mov from "../assets/video/mint_vertical/v_congrats_background.mov";
import hands_background_ver from "../assets/video/mint_vertical/v_hands_background.webm";
import hands_background_ver_mov from "../assets/video/mint_vertical/v_hands_background.mov";
import hands_dices_ver from "../assets/video/mint_vertical/v_hands_dices_loop.webm";
import hands_dices_ver_mov from "../assets/video/mint_vertical/v_hands_dices_loop.mov";
import hands_start_ver from "../assets/video/mint_vertical/v_hands_start.webm";
import hands_start_ver_mov from "../assets/video/mint_vertical/v_hands_start.mov";
import hands_dices_grab_ver from "../assets/video/mint_vertical/v_hands_dices_grab.webm";
import hands_dices_grab_ver_mov from "../assets/video/mint_vertical/v_hands_dices_grab.mov";
import fireworks_ver from "../assets/video/mint_vertical/v_congrats_fireworks.webm";
import fireworks_ver_mov from "../assets/video/mint_vertical/v_congrats_fireworks.mov";
import openseaIcon from "../assets/icons/opensea.svg";
import blurIcon from "../assets/icons/blur.svg";
import qrCode from "../assets/qr_codes/app_download.svg";
import {
  PrimaryButton,
  ExternalLinkButton,
  CopyableReadOnlyInput,
  WalletButton,
} from "../components";
import useWeb3 from "../utils/web3";
import { getNftsByWalletAddress, rollDice, rollWhitelistedDice, getIsWhitelisted } from "../utils/api";
import { copyTextToClipboard } from "../utils/copy";
import GooglePlayIcon from "../assets/icons/google_play_icon.svg";
import AppleIcon from "../assets/icons/apple_icon.svg";
import { motion } from "framer-motion";

import {
  red,
  brightPink,
  black,
  white,
  green,
  secondaryAlt,
  textGray,
  opaquePurple,
} from "../styles/colors";

const mobileBreakpoint = 1024;
// Videos on small laptop screens overlap with text.
// Need to handle explicitly.
const laptopScreenQuery = `(max-height: 1000px) and (min-width: ${mobileBreakpoint}px)`;

const Container = styled.div({
  position: "relative",
  width: "100%",
  height: "100vh",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const VideoContainer = styled.video({
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ControlsContainer = styled.div({
  marginTop: "auto",
  marginBottom: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "1rem",
  zIndex: 1,
  [`@media ${laptopScreenQuery}`]: {
    marginBottom: "10px",
  },
  [`@media ${mobile}`]: {
    marginBottom: "5px",
    padding: "1.5rem",
  },
});

const PixelatedText = styled.div({
  fontFamily: "Pixeled",
  textAlign: "center",
  color: `${white}`,
  fontSize: "26px",
  padding: "1rem",
  display: "block",
  lineHeight: "1.5",
  [`@media ${laptopScreenQuery}`]: {
    fontSize: "24px",
    lineHeight: "1.4",
  },
  [`@media ${mobile}`]: {
    padding: "0.5rem",
    fontSize: "24px",
    lineHeight: "1.4",
  },
});

const ActionSubtext = styled.div({
  fontFamily: "Inter Medium",
  padding: "0 1rem 1rem 1rem",
  maxWidth: "600px",
  textAlign: "center",
  lineHeight: "1.1",
  color: `${textGray}`,
  fontSize: "20px",
  display: "block",
  [`@media ${mobile}`]: {
    fontSize: "16px",
  },
});

const AcessCodeModal = styled(motion.div)({
  borderRadius: "20px",
  boxShadow: `4px 4px 0 -1px ${black}`,
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1",
  padding: "10px 20px 10px 20px",
  color: `${white}`,
  textColor: `${white}`,
  fontWeight: "700",
  fontSize: "1.125rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: `${secondaryAlt}`,
  minHeight: "610px",
  maxHeight: "610px",
  minWidth: "680px",
  maxWidth: "680px",
  width: "100%",
  [`@media ${mobile}`]: {
    minWidth: "280px",
    width: "90%",
    minHeight: "610px",
    maxHeight: "700px",
  },
});

const AccessCodeModalHeader = styled(PixelatedText)({
  fontSize: (props) => props.fontSize,
  marginTop: (props) => props.marginTop,
  textShadow: `2px 2px 2px ${black}`,
  [`@media ${mobile}`]: {
    fontSize: "30px",
  },
});

const MarketplaceFooter = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "1rem",
  padding: "1rem",
  [`@media ${mobile}`]: {
    gap: "0.5rem",
  },
});

const WalletButtonContainer = styled.div({
  marginTop: "15px",
  marginBottom: "-20px",
});

const Icon = styled.img({
  display: "flex",
  height: "18px",
  width: "18px",
});

const isSafari = () => {
  const navigator = window.navigator;
  const ua = navigator.userAgent.toLowerCase();
  return (
    ua.indexOf("safari") !== -1 &&
    !(ua.indexOf("chrome") !== -1) &&
    ua.indexOf("version/") !== -1
  );
};

export const Roll = () => {
  const {
    walletAddress,
    signMessage,
    connectWallet,
    disconnectWallet,
    shortenWalletAddress,
    walletInjected,
  } = useWeb3();
  // Calculated on page load and rerender. Not on resize.
  const useHorizontalVideos = window.innerWidth > mobileBreakpoint;
  const handsDiceGrabRef = useRef(null);
  /**
    We rely on videos being transparent and stacking them on top of each other.
    We show and play appropriate videos during state changes.
    NOTES on <video> tag:
      -- poster tag pauses other videos during loading. We need to use a simple background image until videos are loaded.
      -- autplay attribute doesn't work without muting the video (muted attribute). Sounds can be played automatically if we use .play() via a ref, but that has it's issues.
      -- Refs can be used to control videos (play/pause/etc.) We use them so we can track play time of "hands_dices_grab" video. TODO: Adjust video file so we don't have to do this.
      -- Weird issue with using .play() with refs: Videos tend to lag a bit when they're running in a loop.
      -- I tried rendering all videos on load (for better performance when claiming) and calling .play() on the video refs, but google throws an error "play() failed because the user didn't interact with the document first.".

    States and videos shown:
      - initial: hands_background + hands_dices videos playing in a loop. Connect wallet button visible.
      - diceAlreadyRolled: hands_background + hands_dices videos playing in a loop. Skips rolling video and shows access code modal.
      - hasDice: hands_background + hands_dices videos playing in a loop, hands_start_hor played till end. Roll the dice button visible.
      - noDice: hands_background + hands_dices videos playing in a loop. Marketplace buttons visible.
      - rolling: hands_background playing in a loop, hands_dices_grab played till 7th second. No action button visible.
      - rolled: congrats_background playing in a loop. Access code modal is shown.
  */
  const initVideos = [
    {
      id: 1,
      src: useHorizontalVideos ? hands_background_hor : hands_background_ver,
      hevc_src: useHorizontalVideos
        ? hands_background_hor_mov
        : hands_background_ver_mov,
      show: true,
      loop: true,
    },
    {
      id: 2,
      src: useHorizontalVideos ? hands_dices_hor : hands_dices_ver,
      hevc_src: useHorizontalVideos ? hands_dices_hor_mov : hands_dices_ver_mov,
      show: true,
      loop: true,
    },
    {
      id: 3,
      src: useHorizontalVideos ? hands_start_hor : hands_start_ver,
      hevc_src: useHorizontalVideos ? hands_start_hor_mov : hands_start_ver_mov,
      show: false,
      loop: false,
    },
    {
      id: 4,
      src: useHorizontalVideos ? hands_dices_grab_hor : hands_dices_grab_ver,
      hevc_src: useHorizontalVideos
        ? hands_dices_grab_hor_mov
        : hands_dices_grab_ver_mov,
      show: false,
      loop: false,
      ref: handsDiceGrabRef,
    },
    {
      id: 5,
      src: useHorizontalVideos
        ? congrats_background_hor
        : congrats_background_ver,
      hevc_src: useHorizontalVideos
        ? congrats_background_hor_mov
        : congrats_background_ver_mov,
      show: false,
      loop: true,
    },
    {
      id: 6,
      src: useHorizontalVideos ? fireworks_hor : fireworks_ver,
      hevc_src: useHorizontalVideos ? fireworks_hor_mov : fireworks_ver_mov,
      show: false,
      loop: false,
    },
  ];

  const [state, setState] = useState("initial");
  const [videos, setVideos] = useState(initVideos);
  const [ownedNfts, setOwnedNfts] = useState([]);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [diceToRoll, setDiceToRoll] = useState(null); // Stores whole NFT object.
  const [accessCode, setAccessCode] = useState(null);
  const [handsStartVideoPlayed, setHandsStartVideoPlayed] = useState(false);
  const [rollVideoPlayed, setRollVideoPlayed] = useState(false);
  const [isConnectingWallet, setIsConnectingWallet] = useState(false);
  const [isRollingDice, setIsRollingDice] = useState(false);
  const [isRevealingAccessCode, setIsRevealingAccessCode] = useState(false);

  const showVideo = (id) => {
    setVideos((prevVideos) =>
      prevVideos.map((video) =>
        video.id === id ? { ...video, show: !video.show } : video
      )
    );
  };

  const handleVideoEnded = (id) => {
    console.log("Video ended!", id);
    if (id === 3) {
      setHandsStartVideoPlayed(true);
    } else if (id === 4) {
      // TODO: End video in the middle of the animation. (After flash)
      setRollVideoPlayed(true);
    }
  };

  // Checks NFT metadata attributes to see if given NFT is rolled.
  const isDiceRolled = (nft) => {
    if (nft && nft.lastRoll) {
      // If last roll date is less than 24 hours ago, say it's not rolled.
      const currentTimestamp = Math.floor(Date.now() / 1000); // Exchange milliseconds to seconds.
      // Calculate the UTC timestamp of 24 hours ago
      const twentyFourHoursAgo = currentTimestamp - (24 * 60 * 60);
      // If NFT rolled less than 24 hours ago, it's rolled.
      if (nft.lastRoll < twentyFourHoursAgo) {
        return false;
      } else {
        // If NFT rolled more than 24 hours ago, it's not rolled.
        return true;
      }
    }

    return false;
  };

  // Finds the first rolled dice NFT and returns it's id..
  const getRolledNft = useCallback(() => {
    if (ownedNfts) {
      const rolledNft = ownedNfts.find((nft) => isDiceRolled(nft));
      return rolledNft;
    }
  }, [ownedNfts]);

  const getAccessCode = async () => {
    if (walletAddress && (isWhitelisted || diceToRoll)) {
      try {
        setIsRevealingAccessCode(true);
        let accessCodeResponse = null;
        if (isWhitelisted) {
          const message = `Rolling Dice with ${walletAddress}`
          const signedMessage = await signMessage(message);
          accessCodeResponse = await rollWhitelistedDice(
            walletAddress,
            signedMessage
          );
        } else {
          const message = `Rolling NFT dice #${diceToRoll.id} with ${walletAddress}`;
          const signedMessage = await signMessage(message);
          accessCodeResponse = await rollDice(
            walletAddress,
            diceToRoll.id,
            diceToRoll.contractAddress,
            signedMessage
          );
        }
        const code =
          accessCodeResponse?.activationCode || accessCodeResponse?.code;
        if (code) {
          console.log("Access code received.");
          setAccessCode(code);
          return code;
        }
      } catch (error) {
        console.error("Failed to roll when getting access code: ", error);
      } finally {
        setIsRevealingAccessCode(false);
      }
    }
  };

  const handleConnectWallet = async () => {
    try {
      setIsConnectingWallet(true);
      const walletAddress = await connectWallet();
      if (walletAddress) {
        try {
          const isWalletWhitelisted = await getIsWhitelisted(walletAddress);
          if (isWalletWhitelisted) {
            setIsWhitelisted(true);
          } else {
            const nfts = await getNftsByWalletAddress(walletAddress);
            if (!nfts || nfts.length === 0) {
              setState("noDice");
            } else {
              setOwnedNfts(nfts);
            }
          }
        } catch (error) {
          console.error("Failed to fetch wallet whitelisted status or dice NFT: ", error);
        }
      }
    } catch (error) {
      console.error("Failed to connect wallet: ", error);
    } finally {
      setIsConnectingWallet(false);
    }
  };

  // Resets the page state to initial.
  // To really disconnect, need to do that in the wallet itself.
  const handleDiscconectWallet = () => {
    disconnectWallet();
    setVideos(initVideos);
    setOwnedNfts([]);
    setDiceToRoll(null);
    setAccessCode(null);
    setHandsStartVideoPlayed(false);
    setRollVideoPlayed(false);
    setIsConnectingWallet(false);
    setIsRollingDice(false);
    setIsRevealingAccessCode(false);
    setState("initial");
  };

  const handleRoll = async () => {
    try {
      setIsRollingDice(true);
      // Ensure we have access code before starting the animation.
      const accessCode = await getAccessCode();
      console.log("After calling getAccessCode");
      if (accessCode) {
        console.log("Start rolling.");
        setState("rolling");
        setVideos((prevVideos) =>
          prevVideos.map((video) => {
            if (video.id === 2) {
              // Hide dice loop video.
              return { ...video, show: false };
            } else if (video.id === 3) {
              // Hide hands ready video.
              return { ...video, show: false };
            } else if (video.id === 4) {
              // Show dice grabbing video.
              return { ...video, show: true };
            }
            return video;
          })
        );
      }
    } catch (error) {
      console.error("Failed to roll dice: ", error);
    } finally {
      setIsRollingDice(false);
    }
  };

  const handleShowAccessCode = async () => {
    // Skip roll videos.
    setHandsStartVideoPlayed(true);
    setRollVideoPlayed(true);
    getAccessCode();
  };

  useEffect(() => {
    if (ownedNfts && ownedNfts.length > 0) {
      // Check if any nft is alrad rolled.
      const rolledNft = getRolledNft();

      if (rolledNft) {
        // If any of the dice was already rolled. Pick that and ignore the rest.
        console.log("Already rolled!");
        setDiceToRoll(rolledNft);
        // Sign message and roll automatically.
      } else {
        console.log("Finding first nft to roll...");
        const nftToRoll = ownedNfts[0];
        console.log(nftToRoll);
        setDiceToRoll(nftToRoll);
      }
    }
  }, [ownedNfts, getRolledNft]);

  useEffect(() => {
    // If wallet whitelisted, skip dice checks.
    if (isWhitelisted) {
      setState("hasDice");
      showVideo(3);
    } else if (diceToRoll) {
      if (isDiceRolled(diceToRoll)) {
        // If dice is already rolled, prompt to show access code.
        setState("diceAlreadyRolled");
      } else {
        // Show "Roll the dice" button.
        setState("hasDice");
        showVideo(3);
      }
    }
  }, [isWhitelisted, diceToRoll]);

  useEffect(() => {
    if (rollVideoPlayed && accessCode) {
      setState("rolled");
      setVideos((prevVideos) =>
        prevVideos.map((video) => {
          // Show congratulations background and firework videos.
          if (video.id === 5 || video.id === 6) {
            return { ...video, show: true };
          }
          // Hide other videos.
          return { ...video, show: false };
        })
      );
    }
  }, [rollVideoPlayed, accessCode]);

  useEffect(() => {
    // Reset state if wallet address changes.
    if (state !== "initial") {
      handleDiscconectWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  const walletComponent = walletAddress && (
    <WalletButtonContainer>
      <WalletButton
        walletAddress={shortenWalletAddress(walletAddress)}
        onClick={() => handleDiscconectWallet()}
      />
    </WalletButtonContainer>
  );

  const mobileDownloadComponent = (
    <>
      <AccessCodeModalHeader fontSize="40px" marginTop="40px">
        CONGRATS!
      </AccessCodeModalHeader>
      <div
        style={{
          color: opaquePurple,
          fontSize: "1.125rem",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "160%",
          letterSpacing: "0.01125rem",
          textAlign: "center",
        }}
      >
        Nicely done! Scan this QR code to download the BlockGames app.
        <br />
        After downloading, sign up and enter your code.
      </div>
      <img src={qrCode} alt="" style={{ marginTop: "0.5rem" }} />
      <div style={{ marginTop: "auto", marginBottom: "15px" }}>
        <div style={{ marginBottom: "2rem" }}>
          <ActionSubtext
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              lineHeight: "1.5",
              paddingBottom: "0.5rem",
            }}
          >
            Available on
          </ActionSubtext>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <Icon src={GooglePlayIcon} />
            <Icon src={AppleIcon} />
          </div>
        </div>
        <CopyableReadOnlyInput
          label={accessCode}
          icon={openseaIcon}
          onClick={() => copyTextToClipboard(accessCode)}
        ></CopyableReadOnlyInput>
      </div>
      <ActionSubtext
        style={{
          fontSize: "14px",
          fontWeight: "normal",
          lineHeight: "1",
        }}
      >
        The code remains valid for 24 hours after it's revealed
      </ActionSubtext>
    </>
  );

  const stateComponentMap = {
    initial: (
      <ControlsContainer>
        <PixelatedText>
          <span style={{ color: brightPink }}>JOIN</span> THE PLAYER NETWORK
        </PixelatedText>
        {walletInjected ? (
          <>
            <ActionSubtext>
              Connect your wallet and become an early adopter.
            </ActionSubtext>
            <PrimaryButton
              state={isConnectingWallet ? "loading" : "normal"}
              minWidth="200px"
              disabled={isConnectingWallet}
              onClick={() => handleConnectWallet()}
            >
              Connect Wallet
            </PrimaryButton>
          </>
        ) : (
          <>
            <PixelatedText style={{ fontSize: "16px" }}>
              <span style={{ color: red }}>NO WALLET DETECTED.</span>
            </PixelatedText>
            <ActionSubtext>
              Please use a PC browser that has an Ethereum wallet installed.
            </ActionSubtext>
          </>
        )}
      </ControlsContainer>
    ),
    hasDice: (
      <ControlsContainer>
        <PixelatedText>
          YOU <span style={{ color: green }}>CONNECTED</span>
          <br />
          YOUR WALLET!
        </PixelatedText>
        <ActionSubtext>Now, it's time to roll the dice</ActionSubtext>
        <PrimaryButton
          state={isRollingDice ? "loading" : "normal"}
          minWidth="200px"
          disabled={isRollingDice}
          onClick={() => handleRoll()}
        >
          Roll the dice
        </PrimaryButton>
        {walletComponent}
      </ControlsContainer>
    ),
    noDice: (
      <ControlsContainer>
        <PixelatedText>
          YOU <span style={{ color: red }}>DO NOT</span> HAVE BG DICE NFT OR
          <br /> YOUR WALLET IS NOT WHITELISTED
        </PixelatedText>
        <ActionSubtext>
          Go to the marketplace and explore the collection
        </ActionSubtext>
        <MarketplaceFooter>
          <ExternalLinkButton
            url="https://opensea.io/collection/blockgames-dice-5555"
            icon={openseaIcon}
            label="OpenSea"
            changeBgColor={false}
          />
          <div style={{ color: white, display: "flex", alignItems: "center" }}>
            OR
          </div>
          <ExternalLinkButton
            icon={blurIcon}
            url="https://blur.io/collection/blockgames-dice-5555"
            changeBgColor={false}
          />
        </MarketplaceFooter>
        {walletComponent}
      </ControlsContainer>
    ),
    diceAlreadyRolled: (
      <ControlsContainer>
        <PixelatedText>
          YOU <span style={{ color: green }}>ROLLED</span>
          <br />
          YOUR DICE!
        </PixelatedText>
        <ActionSubtext>Don't forget to get your access code!</ActionSubtext>
        <PrimaryButton
          state={isRevealingAccessCode ? "loading" : "normal"}
          minWidth="200px"
          disabled={isRevealingAccessCode}
          onClick={() => handleShowAccessCode()}
        >
          Reveal activation code
        </PrimaryButton>
        {walletComponent}
      </ControlsContainer>
    ),
    rolling: <></>,
    rolled: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AcessCodeModal
          initial={{ opacity: 0, scale: 0, x: "-50%", y: "-50%" }}
          animate={{ opacity: 1, scale: 1, x: "-50%", y: "-50%" }}
          transition={{
            duration: 0.3,
            delay: 0.1,
          }}
        >
          {mobileDownloadComponent}
        </AcessCodeModal>
      </div>
    ),
  };

  useScrollToTop();

  return (
    <Container>
      {videos
        .filter((x) => x.show)
        .map((video) => {
          return (
            <VideoContainer
              key={video.id}
              ref={video.ref}
              autoPlay
              muted
              playsinline
              loop={video.loop}
              onEnded={() => handleVideoEnded(video.id)}
            >
              {/**
               * Safari doesn't support alpgha channel for webm videos (videos are not transparent).
               * Have to explicitly use hvac encoded videos.
               * Webm files are smaller in size, so we use them for other browsers.
               */}
              {isSafari() ? (
                <source src={video.hevc_src} type="video/mp4" />
              ) : (
                <source src={video.src} type="video/webm" />
              )}
            </VideoContainer>
          );
        })}
      {stateComponentMap[state]}
    </Container>
  );
};
