import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "./PrimaryButton";

/**
 * Use when you want to redirect to external page.
 */
export const ExternalLinkButton = ({
  onClick,
  label,
  disabled,
  className,
  icon,
  url,
  children,
  minWidth,
  changeBgColor,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <a rel="noreferrer" href={url} target="_blank">
      <PrimaryButton
        disabled={disabled}
        onClick={handleClick}
        className={className}
        label={label}
        icon={icon}
        minWidth={minWidth}
        changeBgColor={changeBgColor}
      >
        {children}
      </PrimaryButton>
    </a>
  );
};

ExternalLinkButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  changeBgColor: PropTypes.bool,
};

ExternalLinkButton.defaultProps = {
  type: "button",
  disabled: false,
  changeBgColor: true,
};
