import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { big, mobile } from "../../styles/breakpoints";
import { motion } from "framer-motion";
// import { Link } from "react-router-dom";
import {
  // green,
  black,
  white,
  grey,
  border,
  // textDark,
} from "../../styles/colors";
import { ReactComponent as ComingSoon } from "../../assets/icons/comingSoon.svg";

export const ShadowButton = ({
  onClick,
  label,
  disabled,
  type,
  className,
  isSecondary,
  icon,
  url,
  comingSoon,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    // <Link to={url}>
    <Wrapper
      isSecondary={isSecondary}
      as={motion.button}
      disabled={disabled}
      type={type}
      onClick={handleClick}
      className={className}
      whileHover={{
        cursor: "pointer",
      }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 300, damping: 17 }}
    >
      {label}
      {icon ? <ButtonIcon marginLeft={!!label} src={icon} /> : null}
      {comingSoon && <TooltipSVG />}
    </Wrapper>
    // </Link>
  );
};

ShadowButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ShadowButton.defaultProps = {
  type: "button",
  disabled: false,
};

const TooltipSVG = styled(ComingSoon)`
  position: absolute;
  bottom: -50px;
  display: none;
  z-index: 1;
`;

export const Wrapper = styled.button`
  font-family: "Inter", sans-serif;
  display: flex;
  position: relative;
  border: none;
  height: 40px;
  padding: 12px 15px;
  justify-content: center;
  align-items: center;
  color: ${white};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%; /* 21px */
  letter-spacing: 0.7px;
  background-color: ${({ isSecondary }) =>
    isSecondary ? "#993EDF" : "#3F0D66"};
  border-radius: 8px;
  flex-shrink: 0;

  &:hover {
    background-color: ${({ isSecondary }) =>
      isSecondary ? "#AA3AFF" : "#7625B4"};

    ${TooltipSVG} {
      .svg-container {
        fill: ${({ isSecondary }) => (isSecondary ? "#AA3AFF" : "#7625B4")};
      }
      display: block;
      @media ${mobile} {
        display: none;
      }
    }
  }

  a {
    cursor: pointer;
    display: flex;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: ${white};
  }

  &.small {
    padding: 11px 24px;
    font-size: 14px;
  }
  &.white {
    background: ${white};
    color: ${black};
  }
  &.backButton {
    background: ${grey};
    border-radius: 48px;
  }
  &.filterButton {
    background: none;
    font-size: 14px;
    line-height: 24px;
    padding: 4px 32px;
    border-radius: 100px;
    border: 1px solid ${border};
    margin-right: 1rem;
  }
  &.w100 {
    width: 100%;
  }
  @media ${big} {
  }
`;

const ButtonIcon = styled.img`
  display: flex;
  height: 20px;
  margin-left: ${({ marginLeft }) => (marginLeft ? "12px" : "0")};
`;
