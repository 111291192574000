import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  HeroPlayerNetwork,
  Gcv,
  GcvMobile2,
  Poc,
  Powered,
  Community,
} from '../components';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { mobile } from '../styles/breakpoints';
import { base } from '../styles/colors';
import { useLocation } from 'react-router-dom';

export const Test = () => {
  const isMobile = useMediaQuery({ query: mobile });
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>BlockGames: Test</title>
      </Helmet>

      <PageWrapper>
        <HeroPlayerNetwork />

        {!isMobile && <Gcv />}
        {isMobile && <GcvMobile2 />}

        <Poc />
        <Powered />
        <Community />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  background: ${base};
  width: 100%;
  height: 100%;
`;
