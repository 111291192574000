import React from 'react';
import styled from 'styled-components';
import { members } from '../../data/teammembers';
import { membersImages } from '../../utils/membersImages';
import { mobile } from '../../styles/breakpoints';
import { icons } from '../../utils/icons';
import { H6, Paragraph } from '..';
import { motion } from 'framer-motion';
import {
  base,
  secondary,
  green,
  cyan,
  white,
  brightPink,
} from '../../styles/colors';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export const Members = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const firstRowMembers = [members[0], members[1], members[2]];
  const secondRowMembers = [members[3], members[4]];
  const thirdRowMembers = [members[5], members[6], members[7]];
  const fourthRowMembers = [members[8], members[9]];
  const fifthRowMembers = [members[10], members[11], members[12]];
  const sixthRowMembers = [members[13], members[14]];
  const seventhRowMembers = [members[15], members[16], members[17]];

  const [activeMember, setActiveMember] = React.useState(null);

  const handleMemberClick = (memberId) => {
    if (isMobile) {
      setActiveMember(activeMember === memberId ? null : memberId);
    }
  };

  const blocks = [
    {
      id: 1,
      title: 'We create value',
      description:
        'Games, players, developers, investors, followers, and creators deliver value through player growth and retention, governance participation, and rewards.',
      icon: icons.value1,
    },
    {
      id: 2,
      title: 'We work for fun',
      description:
        'We aim to partner with games that share this ethos and publish the most enjoyable, engaging, and scalable mobile games our community will love and share.',
      icon: icons.value2,
    },
    {
      id: 3,
      title: 'Community comes first',
      description:
        'Community is our life force and grows through play, meaningful engagement, governance rights, and shared ownership of the BlockGames Player Network.',
      icon: icons.value3,
    },
    {
      id: 4,
      title: 'We thrive on innovation',
      description:
        'BlockGames is a new gameplay paradigm powered by interoperability, fair distribution, digital asset ownership, Web3, and $BLOCK.',
      icon: icons.value4,
    },
  ];

  const params = {
    loop: true,
    autoplay: true,
    modules: [Autoplay, Navigation, Pagination, Scrollbar],
    navigation: {
      nextEl: '.arrow-right',
      prevEl: '.arrow-left',
    },
    pagination: true,
    scrollbar: false,
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 16,
  };

  return (
    <Wrapper
      as={motion.div}
      initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
      animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
      transition={{ duration: 0.3, delay: 0.5 }}
    >
      <Row>
        {firstRowMembers.map((member) => (
          <Member
            key={member.id}
            onMouseEnter={() => !isMobile && setActiveMember(member.id)}
            onMouseLeave={() => !isMobile && setActiveMember(null)}
            onClick={() => handleMemberClick(member.id)}
          >
            <MemberImage src={membersImages[member.image]} alt={member.name} />
            {member.unknown !== true && (
              <MemberPopup isActive={activeMember === member.id}>
                <Paragraph fontSize="14px" fontWeight="500" margin="0 0 4px">
                  {member.role}
                </Paragraph>
                <H6
                  fontSize={isMobile ? '1.25rem' : '1.5rem'}
                  fontWeight="800"
                  margin="0 0 1rem"
                >
                  {member.name}
                </H6>
                <ButtonsWrapper>
                  {member.linkedin.length > 0 && (
                    <Button className="green" href={member.linkedin}>
                      LinkedIn
                    </Button>
                  )}
                  {member.twitter.length > 0 && (
                    <Button href={member.twitter}>Twitter</Button>
                  )}
                </ButtonsWrapper>
              </MemberPopup>
            )}
          </Member>
        ))}
      </Row>
      <Row className="narrow">
        {secondRowMembers.map((member) => (
          <Member
            key={member.id}
            onMouseEnter={() => !isMobile && setActiveMember(member.id)}
            onMouseLeave={() => !isMobile && setActiveMember(null)}
            onClick={() => handleMemberClick(member.id)}
          >
            <MemberImage src={membersImages[member.image]} alt={member.name} />
            {member.unknown !== true && (
              <MemberPopup isActive={activeMember === member.id}>
                <Paragraph fontSize="14px" fontWeight="500" margin="0 0 4px">
                  {member.role}
                </Paragraph>
                <H6
                  fontSize={isMobile ? '1.25rem' : '1.5rem'}
                  fontWeight="800"
                  margin="0 0 1rem"
                >
                  {member.name}
                </H6>
                <ButtonsWrapper>
                  {member.linkedin.length > 0 && (
                    <Button className="green" href={member.linkedin}>
                      LinkedIn
                    </Button>
                  )}
                  {member.twitter.length > 0 && (
                    <Button href={member.twitter}>Twitter</Button>
                  )}
                </ButtonsWrapper>
              </MemberPopup>
            )}
          </Member>
        ))}
      </Row>
      <Row>
        {thirdRowMembers.map((member) => (
          <Member
            key={member.id}
            onMouseEnter={() => !isMobile && setActiveMember(member.id)}
            onMouseLeave={() => !isMobile && setActiveMember(null)}
            onClick={() => handleMemberClick(member.id)}
          >
            <MemberImage src={membersImages[member.image]} alt={member.name} />
            {member.unknown !== true && (
              <MemberPopup isActive={activeMember === member.id}>
                <Paragraph fontSize="14px" fontWeight="500" margin="0 0 4px">
                  {member.role}
                </Paragraph>
                <H6
                  fontSize={isMobile ? '1.25rem' : '1.5rem'}
                  fontWeight="800"
                  margin="0 0 1rem"
                >
                  {member.name}
                </H6>
                <ButtonsWrapper>
                  {member.linkedin.length > 0 && (
                    <Button className="green" href={member.linkedin}>
                      LinkedIn
                    </Button>
                  )}
                  {member.twitter.length > 0 && (
                    <Button href={member.twitter}>Twitter</Button>
                  )}
                </ButtonsWrapper>
              </MemberPopup>
            )}
          </Member>
        ))}
      </Row>
      {isMobile ? null : (
        <Row className="blocksRowOne">
          <Blocks>
            <Value>
              <Icon src={icons.value1} />
              <H6 margin="1rem 0 0.5rem" fontSize="1.75rem" fontWeight="800">
                We create value
              </H6>
              <Paragraph fontSize="0.875rem" fontWeight="400">
                Games, players, developers, investors, followers, and creators
                deliver value through player growth and retention, governance
                participation, and rewards.
              </Paragraph>
            </Value>
            <Value>
              <Icon src={icons.value2} />
              <H6 margin="1rem 0 0.5rem" fontSize="1.75rem" fontWeight="800">
                We work for ‘fun’
              </H6>
              <Paragraph fontSize="0.875rem" fontWeight="400">
                We aim to partner with games that share this ethos and publish
                the most enjoyable, engaging, and scalable mobile games our
                community will love and share.
              </Paragraph>
            </Value>
          </Blocks>
        </Row>
      )}
      <Row className="narrow">
        {fourthRowMembers.map((member) => (
          <Member
            key={member.id}
            onMouseEnter={() => !isMobile && setActiveMember(member.id)}
            onMouseLeave={() => !isMobile && setActiveMember(null)}
            onClick={() => handleMemberClick(member.id)}
          >
            <MemberImage src={membersImages[member.image]} alt={member.name} />
            {member.unknown !== true && (
              <MemberPopup isActive={activeMember === member.id}>
                <Paragraph fontSize="14px" fontWeight="500" margin="0 0 4px">
                  {member.role}
                </Paragraph>
                <H6
                  fontSize={isMobile ? '1.25rem' : '1.5rem'}
                  fontWeight="800"
                  margin="0 0 1rem"
                >
                  {member.name}
                </H6>
                <ButtonsWrapper>
                  {member.linkedin.length > 0 && (
                    <Button className="green" href={member.linkedin}>
                      LinkedIn
                    </Button>
                  )}
                  {member.twitter.length > 0 && (
                    <Button href={member.twitter}>Twitter</Button>
                  )}
                </ButtonsWrapper>
              </MemberPopup>
            )}
          </Member>
        ))}
      </Row>
      {isMobile ? null : (
        <Row className="blocksRowTwo">
          <Blocks>
            <Value className="wide">
              <Icon src={icons.value3} />
              <H6 margin="1rem 0 0.5rem" fontSize="1.75rem" fontWeight="800">
                Community comes first
              </H6>
              <Paragraph fontSize="0.875rem" fontWeight="400">
                Community is our life force and grows through play, meaningful
                engagement, governance rights, and shared ownership of the
                BlockGames Player Network.
              </Paragraph>
            </Value>
            <Value className="wide">
              <Icon src={icons.value4} />
              <H6 margin="1rem 0 0.5rem" fontSize="1.75rem" fontWeight="800">
                We thrive on innovation
              </H6>
              <Paragraph fontSize="0.875rem" fontWeight="400">
                BlockGames is a new gameplay paradigm powered by
                interoperability, fair distribution, digital asset ownership,
                Web3, and $BLOCK.
              </Paragraph>
            </Value>
          </Blocks>
        </Row>
      )}
      <Row>
        {fifthRowMembers.map((member) => (
          <Member
            key={member.id}
            onMouseEnter={() => !isMobile && setActiveMember(member.id)}
            onMouseLeave={() => !isMobile && setActiveMember(null)}
            onClick={() => handleMemberClick(member.id)}
          >
            <MemberImage src={membersImages[member.image]} alt={member.name} />
            {member.unknown !== true && (
              <MemberPopup isActive={activeMember === member.id}>
                <Paragraph fontSize="14px" fontWeight="500" margin="0 0 4px">
                  {member.role}
                </Paragraph>
                <H6
                  fontSize={isMobile ? '1.25rem' : '1.5rem'}
                  fontWeight="800"
                  margin="0 0 1rem"
                >
                  {member.name}
                </H6>
                <ButtonsWrapper>
                  {member.linkedin.length > 0 && (
                    <Button className="green" href={member.linkedin}>
                      LinkedIn
                    </Button>
                  )}
                  {member.twitter.length > 0 && (
                    <Button href={member.twitter}>Twitter</Button>
                  )}
                </ButtonsWrapper>
              </MemberPopup>
            )}
          </Member>
        ))}
      </Row>
      <Row className="narrow">
        {sixthRowMembers.map((member) => (
          <Member
            key={member.id}
            onMouseEnter={() => !isMobile && setActiveMember(member.id)}
            onMouseLeave={() => !isMobile && setActiveMember(null)}
            onClick={() => handleMemberClick(member.id)}
          >
            <MemberImage src={membersImages[member.image]} alt={member.name} />
            {member.unknown !== true && (
              <MemberPopup isActive={activeMember === member.id}>
                <Paragraph fontSize="14px" fontWeight="500" margin="0 0 4px">
                  {member.role}
                </Paragraph>
                <H6
                  fontSize={isMobile ? '1.25rem' : '1.5rem'}
                  fontWeight="800"
                  margin="0 0 1rem"
                >
                  {member.name}
                </H6>
                <ButtonsWrapper>
                  {member.linkedin.length > 0 && (
                    <Button className="green" href={member.linkedin}>
                      LinkedIn
                    </Button>
                  )}
                  {member.twitter.length > 0 && (
                    <Button href={member.twitter}>Twitter</Button>
                  )}
                </ButtonsWrapper>
              </MemberPopup>
            )}
          </Member>
        ))}
      </Row>
      <Row>
        {seventhRowMembers.map((member) => (
          <Member
            key={member.id}
            onMouseEnter={() => !isMobile && setActiveMember(member.id)}
            onMouseLeave={() => !isMobile && setActiveMember(null)}
            onClick={() => handleMemberClick(member.id)}
          >
            <MemberImage src={membersImages[member.image]} alt={member.name} />
            {member.unknown !== true && (
              <MemberPopup isActive={activeMember === member.id}>
                <Paragraph fontSize="14px" fontWeight="500" margin="0 0 4px">
                  {member.role}
                </Paragraph>
                <H6
                  fontSize={isMobile ? '1.25rem' : '1.5rem'}
                  fontWeight="800"
                  margin="0 0 1rem"
                >
                  {member.name}
                </H6>
                <ButtonsWrapper>
                  {member.linkedin.length > 0 && (
                    <Button className="green" href={member.linkedin}>
                      LinkedIn
                    </Button>
                  )}
                  {member.twitter.length > 0 && (
                    <Button href={member.twitter}>Twitter</Button>
                  )}
                </ButtonsWrapper>
              </MemberPopup>
            )}
          </Member>
        ))}
      </Row>

      {isMobile ? (
        <BlocksSliderWrapper>
          <H6
            fontSize="2rem"
            fontWeight="800"
            margin="0 0 2.5rem"
            textAlign="center"
          >
            Our <strong>Values</strong>
          </H6>
          <Swiper {...params}>
            {blocks.map((block) => (
              <SwiperSlide key={block.id}>
                <Value>
                  <Icon src={block.icon} />
                  <H6
                    margin="1rem 0 0.5rem"
                    fontSize="1.75rem"
                    fontWeight="800"
                    maxWidth="100%"
                  >
                    {block.title}
                  </H6>
                  <Paragraph
                    fontSize="0.875rem"
                    fontWeight="400"
                    margin="0 0 2rem"
                  >
                    {block.description}
                  </Paragraph>
                </Value>
              </SwiperSlide>
            ))}
          </Swiper>
        </BlocksSliderWrapper>
      ) : null}
    </Wrapper>
  );
};

const BlocksSliderWrapper = styled.div`
  width: 100%;
  margin: 4.5rem 0 0;

  .swiper-pagination-bullet {
    background: #f1def0;
    border-radius: 2px;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: ${brightPink};
    }
  }
`;

const MemberPopup = styled.div`
  position: absolute;
  top: -130px;
  left: 50%;
  padding: 1rem;
  background-color: ${secondary};
  flex-shrink: 0;
  box-shadow: 6px 8px 0px #7236c0;
  border-radius: 1rem;
  opacity: 0;
  white-space: nowrap;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  transform: translateX(-50%);
  visibility: hidden;
  z-index: 2;
  /* Show the popup when active */
  ${({ isActive }) =>
    isActive &&
    `
    opacity: 1;
    visibility: visible;
  `}
  @media ${mobile} {
    /* top: 50%;
    transform: translate(-50%, -50%); */
    top: initial;
    left: initial;
    transform: initial;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Button = styled.a`
  display: inline-block;
  padding: 10px 20px;
  margin: 0 4px;
  border-radius: 10px;
  background-color: ${cyan};
  color: ${base};
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  transition: 0.3s all;

  &.green {
    background-color: ${green};
  }

  &:hover {
    cursor: pointer;
    background-color: ${base};
    color: ${white};
    transition: 0.3s all;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;

const Member = styled.div`
  text-align: center;
  position: relative;
  transition: 0.3s all;
  &:hover {
    cursor: pointer;
    transition: 0.3s all;
    /* ${MemberPopup} {
      opacity: 1;
      transition: 0.3s all;
    } */
  }
  @media ${mobile} {
    padding: 0 0.25rem;
    &:hover {
    }
  }

  &:nth-child(2) {
    @media ${mobile} {
      ${MemberPopup} {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &:last-child {
    @media ${mobile} {
      ${MemberPopup} {
        left: 0%;
        transform: translateX(-50%);
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 830px;
  margin: 0 auto 20px;
  @media ${mobile} {
    max-width: 100%;
    justify-content: center;
    margin: 0 auto 1rem;
  }
  &.narrow {
    justify-content: center;
    max-width: 490px;
    margin: 0 auto 20px;
    ${Member} {
      margin: 0 5.75rem;
    }
    @media ${mobile} {
      max-width: 100%;
      margin: 0 auto 1rem;
      ${Member} {
        margin: 0 0rem;
      }
    }
  }
  &.blocksRowOne {
    max-width: 812px;
    justify-content: space-between;
    @media ${mobile} {
      max-width: 100%;
      justify-content: center;
    }
  }
  &.blocksRowTwo {
    max-width: 960px;
    @media ${mobile} {
      max-width: 100%;
    }
  }
`;

const MemberImage = styled.img`
  height: 145px;
  @media ${mobile} {
    height: 100px;
  }
`;

const Blocks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding: 5rem 0;
  margin: 0 auto;
  text-align: center;

  @media ${mobile} {
    max-width: 100%;
    padding: 2.5rem 20px;
    flex-direction: column;
    justify-content: center;
  }
`;

const Value = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 288px;
  &.wide {
    max-width: 365px;
  }
  @media ${mobile} {
    width: 100%;
    margin: 0 auto 0rem;
    max-width: 80%;
    text-align: center;
    justify-content: center;
  }
`;

const Icon = styled.img`
  height: 2.5rem;
`;
