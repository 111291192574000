import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Hero,
  Blocks,
  Trusted,
  Rewards,
  Dashboard,
  Explore,
  PlayerNetwork,
  Community,
} from '../components';
import styled from 'styled-components';
import { base } from '../styles/colors';
import { useLocation } from 'react-router-dom';

export const Home = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>
          BlockGames: Joyful gameplay with personalised rewards
        </title>
      </Helmet>

      <PageWrapper>
        <Hero />
        <Blocks />
        <Trusted />
        <Rewards />
        <Dashboard />
        <Explore />
        <PlayerNetwork />
        <Community />
        {/* <News /> */}
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  background: ${base};
  width: 100%;
  height: 100%;
`;
