import { useState } from "react";
import { ethers } from "ethers";

// Only balnce of function is needed for this app.
const erc721ABI = [
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];
const diceNftContractAddress = "0x0581dDf7A136c6837429a46C6Cb7b388A3E52971"; // ETH
// const diceNftContractAddress = "0x9e926dFff61dfb975A245ef2f9001F0Ccb25795a"; // Goerli

const goerliChainId = "0x5";
const mainnetChainId = "0x1";
const chainId = mainnetChainId;

const useWeb3 = () => {
  const [walletAddress, setWalletAddress] = useState(null);

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", function (accounts) {
      if (accounts && accounts.length > 0) {
        setWalletAddress(accounts[0]);
      }
    });
  }

  const ensureCorrectNetwork = async () => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const network = await provider.getNetwork();
    if (network.chainId !== chainId) {
      console.log("Sending change network request.");
      await provider._send({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
    }
  };

  const shortenWalletAddress = (address) =>
    address.slice(0, 6) + "..." + address.slice(-4);

  const connectWallet = async () => {
    await ensureCorrectNetwork();
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const wallet = await signer.getAddress();
    setWalletAddress(wallet);
    return wallet;
  };

  // To properly disconnect, need to do it by hand on the wallet.
  const disconnectWallet = () => {
    setWalletAddress(null);
  };

  const signMessage = async (message) => {
    await ensureCorrectNetwork();
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const signedMessage = await signer.signMessage(message);
    return signedMessage;
  };

  const hasDiceNft = async (walletAddress) => {
    await ensureCorrectNetwork();
    const provider = new ethers.BrowserProvider(window.ethereum);
    const erc721Contract = new ethers.Contract(
      diceNftContractAddress,
      erc721ABI,
      provider
    );
    const nftBalance = await erc721Contract.balanceOf(walletAddress);
    console.log("Nft balance:", nftBalance);
    return nftBalance > 0;
  };

  return {
    walletAddress,
    shortenWalletAddress,
    connectWallet,
    disconnectWallet,
    signMessage,
    walletInjected: !!window.ethereum
  };
};

export default useWeb3;
